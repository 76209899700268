import React, { useEffect, useState } from "react";
import Content from "../../../../../../layout/content/Content";
import Head from "../../../../../../layout/head/Head";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Loading,
  PreviewAltCard,
} from "../../../../../../components/Component";
import UserAvatar from "../../../../../../components/user/UserAvatar";
import { toast, ToastContainer } from "react-toastify";
import { apiRequest } from "../../../../../../utils/Api";
import { Button, Col, Container, Row, Spinner } from "reactstrap";
import { getInitials } from "../../../../../../utils/Utils";
import { useParams } from "react-router";

const ClientAccess = (props) => {
  const [loading, setLoading] = useState()
  const [data, setData] = useState()
  const [buttonLoading, setButtonLoading] = useState(false)
  const { request_id } = useParams()


  useEffect(() => {
    getMembersList()

  }, [])

  const showToast = (type, message) => {
    toast[type](message || "An error occurred", {
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 5000,
    });
  }

  const getMembersList = async () => {
    try {
      setLoading(true);
      await apiRequest(`/customer/a/client/member-list/${request_id}`, "GET").then(async (resp) => {
        console.log(resp);
        if (resp.code === 200) {
          console.log(resp);
          setData(resp.data);
          setLoading(false);

        } else {
          toast.error(resp.message || "An error occurred", {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 5000,
          });
          setLoading(false);
        }
      });
    } catch (error) {
      // setLoading(false)
      console.log(error);
      toast.error(error, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  const onCheck = (e) => {
    console.log(typeof e.target.id)

    setData((prevData) =>
      prevData.map((item) =>
        item.id === Number(e.target.id) ? { ...item, checked: e.target.checked } : item
      )
    );
    data.forEach(item => {
      console.log('===================')
      console.log(typeof item.id)
      console.log(item.checked)
    })
  }

  const onSave = async () => {
    console.log('save')
    let selected = []
    for (let agent of data) {
      console.log(agent.checked)
      if (agent.checked) {
        selected.push(agent.id)
      }
    }
    setButtonLoading(true)
    console.log(selected)
    // return
    await apiRequest(`/customer/a/client/member-list/${request_id}`, "POST", { selected }).then(async (resp) => {
      console.log(resp);
      if (resp.code === 200) {
        console.log(resp);
        setData(resp.data);
        setLoading(false);
        showToast('success', "Saved")
      } else {
        console.log('error')
        setLoading(false);
        showToast('error', resp.message)

      }
      setButtonLoading(false)
    });
  }

  return (
    <React.Fragment>
      <BlockHead size="lg">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle tag="h4">Access</BlockTitle>
            <BlockDes >
              <p className="me-3">
                {/* Select the organization members that you want to have access to this user's verifications. */}
                Select the organization agents you want to grant access to this client and their activities, including Identity Verification (IDV) and Know Your Customer (KYC) requests.
              </p>
            </BlockDes>
          </BlockHeadContent>
          <BlockHeadContent>
            <div className="toggle-wrap nk-block-tools-toggle">
              <div className="t" >
                <Button color="primary" style={{ minWidth: '100px', width: '100px' }} className="btn-block" disabled={buttonLoading} onClick={() => onSave()}>
                  {buttonLoading ?
                    <Spinner size="sm" />
                    :
                    <>
                      <Icon className="" name="save"></Icon>
                      <span className="pe-2">Save</span>
                    </>}
                </Button>
              </div>
            </div>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>
      {loading ?
        <Loading />
        :

        data && <Content>
          <Head title="Client Details"></Head>


          <Block>
            {/* <OverlineTitle data={data} className="preview-title">Agent Selection</OverlineTitle> */}
            {/* <MainTable columns={tableCols} route="/customer/a/client/member-list" checkbox /> */}
            {!data ?
              <Loading />
              :

              <Content>
                <BlockHead size="sm">
                  <BlockBetween>
                    <BlockHeadContent>
                      {/* <BlockTitle tag="h3" page>
                                Mmbers Lists
                            </BlockTitle>
                            <BlockDes className="text-soft">
                                <p>Select the organization members that you want to have access to this user's verifications.</p>
                            </BlockDes> */}
                    </BlockHeadContent>

                  </BlockBetween>
                </BlockHead>
                <Container
                  className="d-block"
                  style={{
                    height: '520px',
                    overflowY: 'auto',
                  }}
                >
                  <style>
                    {`
          @media (max-width: 767.98px) {
            .no-scroll-mobile {
              overflow-y: visible !important;
            }
          }
        `}
                  </style>

                  <div className="no-scroll-mobile">
                    <Row className="g-gs">
                      {data.map((item) => (
                        <Col sm="6" xl="4" key={item.id}>
                          <PreviewAltCard>
                            <div className="team">
                              <div>
                                <input
                                  id={item.id}
                                  name={item.id}
                                  onChange={(e) => onCheck(e)}
                                  type="checkbox"
                                  checked={item.checked}
                                  style={{ transform: 'scale(1.4)' }}
                                />
                              </div>
                              <div className="user-card user-card-s2">
                                <UserAvatar
                                  imageLink={item.avatar}
                                  className="md"
                                  text={getInitials(item.name)}
                                  image={item.image}
                                />
                                <div className="user-info">
                                  <h6>{item.name}</h6>
                                  <span className="sub-text">{item.email}</span>
                                  <span className="sub-text">{item.phone}</span>
                                </div>
                              </div>
                            </div>
                          </PreviewAltCard>
                        </Col>
                      ))}
                    </Row>
                  </div>
                </Container>


              </Content>
            }
          </Block>
        </Content>}
      <ToastContainer />

    </React.Fragment>
  );
};

export default ClientAccess;
