import React, { useState, useRef, useEffect } from "react";
import { useForm } from "react-hook-form";

import { BlockAthenty, Block, Icon, Input, Loading } from "../../../../components/Component";
import RSelect2 from "../../../../components/select/ReactSelect2";
import classnames from "classnames";
import { Col, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { toast, ToastContainer } from "react-toastify";
import { Card } from "reactstrap";
import { apiRequest } from "../../../../utils/Api";
import { useNavigate } from "react-router";

const AddThirdParty = ({ mode }) => {
  const [activeIconTab, setActiveIconTab] = useState("1");
  const [formData, setFormData] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)
  const [buttonLoading, setButtonLoading] = useState(false)

  const options = [
    { value: 'contact', label: 'Contact' },
    { value: 'other', label: 'Other' }
  ]
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    watch
  } = useForm();

  const submitRef = useRef();

  const userData = {
    first_name: "Joe ",
    last_name: "Smith",
    email: "joe@email.com",
    phoneNumber: "416-123-4567",
    title: "Associate",
    role: "Corporate Lawyer",
    licence: "Free",
    permission: "Default",
    lastLogin: "12-12-1212",
  };



  const onselect = (name, value) => {
    console.log(getValues())
    if (name === "type") {
      setValue("type", value);
      if (value === 'other') {
        delete errors.email
        delete errors.name_first
        delete errors.name_last
        delete errors.phone
        delete errors.mobile
        delete errors.title

      }
    }
    if (getValues(name)) {
      delete errors[name];
      console.log(errors)
    }
  };

  const asignRef = () => {
    // setButtonLoading(true)
    console.log(errors)
    return submitRef.current.click();
  };

  const onFormSubmit = async (formData) => {
    setButtonLoading(true)
    await apiRequest("/customer/a/third-party/add", "POST", getValues()).then((resp) => {
      console.log(resp);

      if (resp.code === 200) {
        console.log("success");
        navigate("/customer/menu/third-party-list", { state: "userAdded" });
        toast.success("New Agent Added Successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } else if (resp.code === 401) {
        toast.error("Access Denied", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } else if (resp.code === 403) {
        toast.error("Email Already Exist", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } else if (resp.code === '500') {
        toast.error("Something went wrong", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } else {
        toast.error(resp.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    });
    setButtonLoading(false)
  };

  const toggleIconTab = (icontab) => {
    if (activeIconTab !== icontab) setActiveIconTab(icontab);
  };

  return (

    <React.Fragment>
      {loading && <Loading />}
      {!loading && options && (<>
        <BlockAthenty
          head={'Add new Third-party'}
          nav="Admin / Access / Users"
          title={mode === "edit" ? "Edit Third-party" : "New Third-party"}
          description={mode === "edit" ? "Edit Third-party" : "Add Third-party"}
          loading={buttonLoading}
          buttons={[
            {
              text: "Save",
              icon: "save",
              onclick: asignRef,
              tooltip: "Save Changes",
              id: "save-button",
              type: "submit",
              form: "my-form",
              disabled: buttonLoading
            },
          ]}
          back
        />
        <Block size="lg">
          <Card className="card-bordered">
            <Nav tabs>
              <NavItem>
                <NavLink
                  tag="a"
                  href="#tab"
                  className={classnames({ active: activeIconTab === "1" })}
                  style={{ marginLeft: "24px" }}
                  onClick={(ev) => {
                    ev.preventDefault();
                    toggleIconTab("1");
                  }}
                >
                  <Icon name="share-alt" /> <span>Third-party</span>
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeIconTab}>
              <TabPane tabId="1">
                <form
                  className="is-alter"
                  style={{ paddingLeft: "24px", paddingRight: "24px", paddingBottom: "24px" }}
                  onSubmit={handleSubmit(onFormSubmit)}
                >
                  <div className="form-group">
                    <div className="form-control-wrap">
                      {mode !== 'edit' && <div className="row">
                        <Col>
                          <label htmlFor="type" className="form-label">
                            Type <span className="text-danger">*</span>
                          </label>
                        </Col>
                        <Col>
                          <RSelect2
                            id="type"
                            name="type"
                            register={register}
                            options={options}
                            requiredMessage="This field is required"
                            errors={errors}
                            className="p-0 border-0 form-control"
                            onselect={onselect}
                            setValue={setValue}
                            placeholder="Select type"
                          />
                        </Col>
                      </div>}
                      <div className="row">
                        <div className="col">
                          <label htmlFor="company" className="form-label">
                            Company <span className="text-danger">*</span>
                          </label>
                        </div>
                        <div className="col">
                          <Input
                            id="company"
                            name="company"
                            type="text"
                            register={register}
                            required
                            requiredMessage={{
                              required: "This field is required",
                              pattern: {
                                value: /^.{1,40}$/,
                                message: "Max 40 characters",
                              },
                            }}
                            errors={errors}
                            className=" form-control"
                            defaultValue={mode === "edit" ? userData.name_first : ""}
                          />
                        </div>
                      </div>
                      {watch('type') !== 'other' &&

                        <>
                          <div className="row mt-2">
                            <div className="col">
                              <label htmlFor="title" className="form-label">
                                Title {watch('type') !== 'other' && <span className="text-danger">*</span>}
                              </label>
                            </div>
                            <div className="col">
                              <Input
                                id="title"
                                name="title"
                                type="text"
                                register={register}
                                required
                                requiredMessage={{
                                  required: watch('type') !== 'other' ? "This field is required" : false,
                                  pattern: {
                                    value: /^.{1,40}$/,
                                    message: "Max 40 characters",
                                  },
                                }}
                                errors={errors}
                                className=" form-control"
                                defaultValue={mode === "edit" ? userData.name_last : ""}
                              />
                            </div>
                          </div>


                          <div className="row mt-2">
                            <div className="col">
                              <label htmlFor="name_first" className="form-label">
                                First Name {watch('type') !== 'other' && <span className="text-danger">*</span>}
                              </label>
                            </div>
                            <div className="col">
                              <Input
                                id="name_first"
                                name="name_first"
                                type="text"
                                register={register}
                                required
                                requiredMessage={{
                                  required: watch('type') !== 'other' ? "This field is required" : false,
                                  pattern: {
                                    value: /^.{1,40}$/,
                                    message: "Max 40 characters",
                                  },
                                }}
                                errors={errors}
                                className=" form-control"
                                defaultValue={mode === "edit" ? userData.name_last : ""}
                              />
                            </div>
                          </div>

                          <div className="row mt-2">
                            <div className="col">
                              <label htmlFor="name_last" className="form-label">
                                Last Name {watch('type') !== 'other' && <span className="text-danger">*</span>}
                              </label>
                            </div>
                            <div className="col">
                              <Input
                                id="name_last"
                                name="name_last"
                                type="text"
                                register={register}
                                required
                                requiredMessage={{
                                  required: watch('type') !== 'other' ? "This field is required" : false,
                                  pattern: {
                                    value: /^.{1,40}$/,
                                    message: "Max 80 characters",
                                  },
                                }}
                                errors={errors}
                                className=" form-control"
                                defaultValue={mode === "edit" ? userData.title : ""}
                              />
                            </div>
                          </div>

                          <div className="row mt-2">
                            <div className="col">
                              <label htmlFor="email" className="form-label">
                                Email {watch('type') !== 'other' && <span className="text-danger">*</span>}
                              </label>
                            </div>
                            <div className="col">
                              <Input
                                id="email"
                                name="email"
                                type="text"
                                register={register}
                                // required={watch('type') !== 'other' ? true : false}
                                requiredMessage={{
                                  required: watch('type') !== 'other' ? "This field is required" : false,
                                  pattern: {
                                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                    message: "Please enter valid email address",
                                  },
                                }}
                                errors={errors}
                                className=" form-control"
                                defaultValue={mode === "edit" ? userData.email : ""}
                              />
                            </div>
                          </div>



                          <div className="row mt-2">
                            <div className="col">
                              <label htmlFor="mobile" className="form-label">
                                Mobile Number {watch('type') !== 'other' && <span className="text-danger">*</span>}
                              </label>
                            </div>
                            <div className="col">
                              <Input
                                id="mobile"
                                name="mobile"
                                type="text"
                                register={register}

                                requiredMessage={{
                                  required: watch('type') !== 'other' ? "This field is required" : false,
                                  pattern: {
                                    value: /^(?:\+?1[-.\s]?)?\(?[2-9][0-8][0-9]\)?[-.\s]?\d{3}[-.\s]?\d{4}$/,
                                    message: "Please input valid phone number",
                                  },
                                }}
                                errors={errors}
                                className=" form-control"
                                defaultValue={mode === "edit" ? userData.phone_mobile : ""}
                              />
                            </div>
                          </div>


                        </>
                      }
                      <button ref={submitRef} type="submit" id="add-new-verify" style={{ display: "none" }} />
                    </div>
                  </div>
                </form>
              </TabPane>
            </TabContent>
          </Card>
          <ToastContainer />
        </Block>
      </>)}
    </React.Fragment>
  );
};

export default AddThirdParty;
