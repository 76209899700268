import Content from "../../../../../layout/content/Content";
import React, { useEffect, useRef } from "react";
// import ShareAddExisting from "./shareAddExisting/ShareAddExisting";

import { Block, BlockAthenty, Input } from "../../../../../components/Component";
import { Card, Col, Container, Row } from "reactstrap";
import { useForm } from "react-hook-form";
import RSelect2 from "../../../../../components/select/ReactSelect2";
import { apiRequest } from "../../../../../utils/Api";
import { Navigate, useNavigate } from "react-router";
import { toast, ToastContainer } from "react-toastify";
// import VerifyWizard from "./verifyWizard/VerifyWizard";

const ClientAdd = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
        setError,
        clearErrors,
        getValues,
        watch,
        setValue,
        unregister
    } = useForm();

    const submitRef = useRef();
    const navigate = useNavigate();

    const asignRef = () => {

        return submitRef.current.click();
    };


    useEffect(() => {
        const firstName = document.getElementById('client_name_first');
        if (firstName) {
            firstName.addEventListener('blur', setClientId);
        }
        const lastName = document.getElementById('client_name_last');
        if (lastName) {
            lastName.addEventListener('blur', setClientId);
        }

        return () => {
            if (firstName) {
                firstName.removeEventListener('blur', setClientId);
            }
            if (lastName) {
                lastName.removeEventListener('blur', setClientId);
            }
        };
    }, [])

    const onFormSubmit = async () => {
        console.log('submit')
        console.log(getValues())
        console.log('test')
        // return
        await apiRequest("/customer/a/client/add", "POST", getValues()).then((resp) => {
            // setLoading(true)
            console.log(resp)
            console.log('test')
            if (resp.code === 200) {
                navigate("/customer/menu/client-list", { state: "clientAdded" });
                console.log('done')
            } else {
                toast.error(resp.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            }
        });
    }

    const setClientId = () => {
        console.log('set')
        setValue('client_id', `${getValues('client_name_first')}${getValues('client_name_last') ? '-' : ''}${getValues('client_name_last')}`)
    }

    return (
        <React.Fragment>
            <Content page="component">
                <BlockAthenty
                    head={"New Client"}
                    nav="Client List"
                    title={"New Client"}
                    back
                    description="Add New Client"
                    buttons={[
                        {
                            // type:"submit",
                            text: "Save",
                            icon: "save",
                            link: "",
                            onclick: asignRef,
                            tooltip: "Submit",
                            id: "submit",
                            form: "verify-form",
                            color: "primary",
                        },
                    ]}
                />
            </Content>
            <Block className="nk-content-wrap">
                <Card className="card-bordered ">
                    <div className="card-inner card-inner-lg">
                        <Container>
                            <form
                                className="is-alter"
                                style={{ paddingLeft: "24px", paddingRight: "24px", paddingBottom: "24px" }}
                                onSubmit={handleSubmit(onFormSubmit)}
                            >
                                <Row>
                                    <Row>
                                        <Col xl="4" sm="6" xs="12" className="mb-2">

                                            <div className="form-control-wrap">
                                                <Input
                                                    errors={errors}
                                                    required
                                                    requiredMessage={{
                                                        required: "This field is required",
                                                        pattern: {
                                                            value: /^.{1,40}$/,
                                                            message: "Max 40 characters",
                                                        },
                                                    }}
                                                    // onchange={setClientId}
                                                    register={register}
                                                    type="text"
                                                    label="Client First Name"
                                                    id="client_name_first"
                                                    name="client_name_first"
                                                    className="form-control-l form-control"
                                                />
                                            </div>

                                        </Col>
                                        <Col xl="4" sm="6" xs="12" className="mb-2">
                                            <div className="form-control-wrap">
                                                <Input
                                                    errors={errors}
                                                    required
                                                    requiredMessage={{
                                                        required: "This field is required",
                                                        pattern: {
                                                            value: /^.{1,40}$/,
                                                            message: "Max 40 characters",
                                                        },
                                                    }}
                                                    // onchange={(e) => console.log(e.target.value)}
                                                    register={register}
                                                    type="text"
                                                    label="Client Last Name"
                                                    id="client_name_last"
                                                    name="client_name_last"
                                                    className="form-control-l form-control"

                                                />
                                            </div>
                                        </Col>
                                        <Col xl="4" sm="6" xs="12" className="mb-2">
                                            <div className="form-control-wrap">
                                                <Input
                                                    errors={errors}
                                                    register={register}
                                                    type="text"
                                                    label="Client ID"
                                                    id="client_id"
                                                    name="client_id"
                                                    className="form-control-l form-control"
                                                    requiredMessage={{
                                                        pattern: {
                                                            value: /^.{1,80}$/,
                                                            message: "Max 80 characters",
                                                        },
                                                    }}


                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl="4" sm="6" xs="12" className="mb-2">
                                            <div className="form-control-wrap">
                                                <Input
                                                    errors={errors}
                                                    required
                                                    requiredMessage={{
                                                        required: "This field is required",
                                                        pattern: {
                                                            value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                                            message: "Please enter valid email address",
                                                        },
                                                    }}
                                                    register={register}
                                                    type="text"
                                                    label="Client Email"
                                                    id="client_email"
                                                    name="client_email"
                                                    className="form-control-l form-control"
                                                />
                                            </div>
                                        </Col>
                                        <Col xl="4" sm="6" xs="12" className="mb-2">
                                            <div className="form-control-wrap">
                                                <Input
                                                    errors={errors}
                                                    required
                                                    requiredMessage={{
                                                        required: "This field is required",
                                                        pattern: {
                                                            value: /^\+?1?[2-9][0-8][0-9]\d{3}\d{4}$/,
                                                            message: "Please input valid phone number",
                                                        },
                                                    }}
                                                    register={register}
                                                    type="text"
                                                    label="Client Phone Number"
                                                    id="client_phone"
                                                    name="client_phone"
                                                    className="form-control-l form-control"
                                                />
                                            </div>
                                        </Col>
                                        {/* <Col xl="4" sm="6" xs="12" className="mb-2">
                                        <Row>
                                            <RSelect2
                                                onselect={onselect}
                                                setValue={setValue}
                                                // selectedCountry={watch("region")}
                                                label="Matter"
                                                register={register}
                                                requiredMessage="This field is required"
                                                id="matter"
                                                name="matter"
                                                errors={errors}
                                                clearErrors={clearErrors}
                                                createable={true}
                                                // options={props.matterList}
                                                placeholder="Select or type new matter"
                                                className="form-control-lg p-0 border-0 form-control"
                                            />
                                        </Row>
                                    </Col> */}
                                    </Row>
                                    {/* {isNewMatter && <Row>
                                    <Col xl="12" sm="6" xs="12" className="mb-2">
                                        <div className="form-label-group">
                                            <label className="form-label" htmlFor="default-01">
                                                Matter Description or Reason<span className="text-danger">*</span>
                                            </label>
                                        </div>
                                        <div className="form-control-wrap">
                                            <textarea
                                                id="request_reason"
                                                name="request_reason"
                                                type="text"
                                                className="  form-control form-control-lg "
                                                {...register("request_reason", {
                                                    required: "This field is required",
                                                    pattern: {
                                                        value: /^.{1,128}$/,
                                                        message: "Max 128 characters",
                                                    },
                                                })}
                                            ></textarea>
                                            {errors.request_reason && <p className="invalid">{errors.request_reason.message}</p>}
                                        </div>
                                    </Col>
                                </Row>} */}


                                    <div className="card-inner-group">
                                        <div className="mt-2 text-muted small">
                                            Please make sure the request details are correct and accurate, you cannot change them once sent, and
                                            there are no refunds.
                                        </div>
                                    </div>
                                </Row >
                                <button ref={submitRef} type="submit" id="add-new-verify" style={{ display: "none" }} />
                            </form>
                        </Container >

                    </div>
                </Card>
            </Block>
            <ToastContainer />
        </React.Fragment>
    );
};
export default ClientAdd;
