export const menu = [
  { heading: "Menu", access: ['Owner', 'Agent'] },
  {
    key: "index",
    icon: "dashboard",
    text: "Dashboard",
    link: "/customer/index",
    access: ['Owner', 'Agent']
  },

  {
    key: "client-list",
    icon: "user-list",
    text: "Client List",
    link: "/customer/menu/client-list",
    access: ['Owner', 'Agent']
  },
  {
    key: "matter-list",
    // icon: "list-thumb",
    icon: "folder-list",
    text: "Matter List",
    link: "/customer/menu/matter-list",
    access: ['Owner', 'Agent']
  },
  {
    key: "third-party-list",
    // icon: "share-alt",
    icon: "user-group",
    text: "Third-party List",
    link: "/customer/menu/third-party-list",
    access: ['Owner', 'Agent']
  },
  { heading: "Solutions", access: ['Owner', 'Agent'] },

  // {
  //   key: "share",
  //   icon: "share",
  //   text: "Smart Share",
  //   badge: "Preview",
  //   link: "/customer/solutions/share",
  //   access: ['Owner', 'Agent']
  // },
  {
    key: "verify",
    // icon: "share",
    icon: "cc-alt2",
    text: "Smart IDV",
    // badge: "Preview",
    link: "/customer/solutions/verify",
    access: ['Owner', 'Agent']
  },
  {
    key: "kyc",
    // icon: "user-check",
    icon: "form-validation",
    text: "Smart KYC",
    link: "/customer/solutions/kyc",
    access: ['Owner', 'Agent']
  },

  {
    disable: true,
    icon: "alert",
    text: "Athenty Alert",
    badge: "Coming soon",
    access: ['Owner', 'Agent']
  },
  {
    disable: true,
    icon: "zoom-in",
    text: " SwiftSpect",
    badge: "Coming soon",
    access: ['Owner', 'Agent']
  },
  { heading: "Admin", access: ['Owner', 'Agent'] },
  {
    key: "Organization",
    icon: "building",
    text: "Organization",
    link: "/customer/admin/organization",
    access: ['Owner']
  },
  {
    key: "Access",
    icon: "account-setting",
    text: "Access",
    active: true,
    subMenu: [
      {
        key: "Users",
        text: "Users",
        link: "/customer/admin/access/users",
        access: ['Owner']
      },
      // {
      //   key: "API Token",
      //   text: "API Token",
      //   link: "/customer/admin/access/api-token",
      //   count: "2",
      // },
      {
        key: "My Profile",
        text: "My Profile",
        link: "/customer/admin/access/profile",
        count: "3",
        access: ['Owner', 'Agent']
      },
    ],
    access: ['Owner', 'Agent']
  },
  {
    key: "Billing",
    // icon: "files",
    icon: "app-development",
    text: "Billing",
    active: true,
    access: ['Owner'],
    subMenu: [
      {
        key: "Subscription",
        text: "Subscription",
        link: "/customer/admin/billing/subscription",
        access: ['Owner']
      },
      {
        key: "Invoice History",
        text: "Invoice History",
        link: "/customer/admin/billing/invoices",
        access: ['Owner']
      },
      {
        key: "Payment History",
        text: "Payment History",
        link: "/customer/admin/billing/payment-history",
        access: ['Owner']
      },
      {
        key: "Transaction History",
        text: "Transaction History",
        link: "/customer/admin/billing/transactions",
        access: ['Owner']
      },
      {
        key: "Payment Methods",
        text: "Payment Methods",
        link: "/customer/admin/billing/payment-methods",
        access: ['Owner']
      },
    ],
  },
  { heading: "support", access: ['Owner', 'Agent'] },
  {
    key: "tickets",
    icon: "ticket",
    text: "Tickets",
    link: "/customer/tickets",
    access: ['Owner', 'Agent']
  },
];

export const smallMenu = [
  {
    heading: "Resources",
  },
  {
    text: "Knowledge Base",
    link: "https://www.athenty.com/resources/knowledge-base/",
    external: true,

  },
  // {
  //   text: "API Docs",
  //   link: "https://www.athenty.com",
  //   external: true,
  // },
  {
    text: "Pricing",
    link: "https://www.athenty.com/solution/smart-verify/#pricing",
    external: true,

  },
  // {
  //   text: "Feature Request",
  //   link: "https://www.athenty.com",
  //   external: true,
  // },
  // {
  //   text: "Support",
  //   link: "https://www.athenty.com/solution/smart-verify/#pricing",
  //   external: true,
  // },
];
export default menu;
