import React from "react";
import Content from "../../../../layout/content/Content";
import { useEffect, useState } from "react";
import { apiRequest } from "../../../../utils/Api";
import { Block, PreviewCard, ClientListTable, BlockAthenty, Loading } from "../../../../components/Component";
import { toast, ToastContainer } from "react-toastify";
import { Link, useLocation } from "react-router-dom";
// table imports
import { UserAvatar, Icon } from "../../../../components/Component";
import { findUpper } from "../../../../utils/Utils";
import { Badge, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import MainTable from "../../../components/athenty/table/Main.Table";
import moment from "moment";
import { parsePhoneNumber } from "libphonenumber-js";

const ClientList = () => {
  const [tableData, setTableData] = useState();
  const [loadin, setLoading] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (location.state === "clientAdded") {
      toast.success("The client has been added", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }, []);



  // table ------------------------------------------------------
  const tableCols = [
    {
      name: "Client",
      selector: (row) => row.name,
      compact: true,
      grow: 6,

      cell: (row) => (
        <Link to={`/customer/menu/client-list/${row.uuid}`}>
          <div className="user-card position-relative">
            <div className="user-info">
              <span className="tb-lead">{row.name} </span>
              <span>{row.external_id}</span>
            </div>
          </div>
        </Link>
      ),
      sortable: true,
    },
    {
      name: "Contact",
      selector: (row) => row.location,
      grow: 5,
      cell: (row) => (
        <div className="user-card">
          <div className="user-info">
            <span className="tb-lead"></span>
            <span>
              <Icon name="call-fill" className={"pe-1"} />
              <a href={`tel:${row.phone}`}>{parsePhoneNumber("+1" + row.phone).formatNational()}</a>
              <br />
              <Icon name="mail" className={"pe-1"} />
              <a href={`mailto://${row.email}`}>{row.email}</a>
            </span>
          </div>
        </div>
      ),
    },
    {
      name: "IDVs Requests",
      center: true,
      selector: (row) => row.request_count,
      grow: 2,
      hide: "lg",
      cell: (row) => (
        <div
          className="nk-menu-icon"
          data-toggle="tooltip"
        // title={row.status.charAt(0).toUpperCase() + row.status.slice(1)}
        >
          <Badge pill color="gray">
            {row.verify_count}
          </Badge>
        </div>
      ),
    },
    {
      name: "KYCs Requests",
      center: true,
      selector: (row) => row.request_count,
      grow: 2,
      hide: "lg",
      cell: (row) => (
        <div
          className="nk-menu-icon"
          data-toggle="tooltip"
        // title={row.status.charAt(0).toUpperCase() + row.status.slice(1)}
        >
          <Badge pill color="gray">
            {row.kyc_count}
          </Badge>
        </div>
      ),
    },
    {
      name: "Last Verified",
      selector: (row) => row.last_verified,
      grow: 4.5,
      sortable: true,
      cell: (row) => (
        <div className="">
          {row.last_verified && (
            <span>
              {" "}
              {new Date(row.last_verified).toLocaleDateString("default", { weekday: "long" }) +
                " " +
                new Date(row.last_verified).toLocaleDateString("default", {
                  month: "long",
                  day: "numeric",
                  year: "numeric",
                })}
            </span>
          )}
          {row.last_verified && (
            <span>
              <br />
              {Math.trunc((new Date().getTime() - new Date(row.last_verified)) / (1000 * 3600 * 24)) === 0
                ? "Today"
                : Math.trunc((new Date().getTime() - new Date(row.last_verified)) / (1000 * 3600 * 24)) === 1
                  ? "Yesterday"
                  : Math.trunc((new Date().getTime() - new Date(row.last_verified)) / (1000 * 3600 * 24)) + " Days ago"}
            </span>
          )}
          {!row.last_verified && <span>Never Verified</span>}
        </div>
      ),
    },

    // {
    //   name: "",
    //   selector: (row) => row.name,

    //   cell: (row) => (
    //     <UncontrolledDropdown>
    //       <DropdownToggle tag="a" className="dropdown-toggle btn btn-icon btn-trigger">
    //         <Icon name="more-v"></Icon>
    //       </DropdownToggle>
    //       <DropdownMenu end>
    //         <li onClick={() => console.log("click")}>
    //           <DropdownItem
    //             tag="a"
    //             href="#edit"
    //             onClick={(ev) => {
    //               ev.preventDefault();
    //             }}
    //           >
    //             <Icon name="edit"></Icon>
    //             <span>Edit</span>
    //           </DropdownItem>
    //         </li>
    //         <React.Fragment>
    //           <li className="divider"></li>
    //           <li onClick={() => console.log("click")}>
    //             <DropdownItem
    //               tag="a"
    //               href="#suspend"
    //               onClick={(ev) => {
    //                 ev.preventDefault();
    //               }}
    //             >
    //               <Icon name="na"></Icon>
    //               <span>Suspend User</span>
    //             </DropdownItem>
    //           </li>
    //         </React.Fragment>
    //       </DropdownMenu>
    //     </UncontrolledDropdown>
    //   ),
    //   sortable: true,
    // },
  ];

  const tools = [
    {
      text: "Send Email",
      onclick: (row) => (window.location = `tel:${row.to_phone}`),
      icon: "mail",
    },
    {
      text: "Call",
      onclick: (row) => (window.location = `tel:${row.to_phone}`),
      icon: "call",
    },
    {
      text: "Re-Verify",
      icon: "done",
    },
  ];

  // display ---------------------------------------------------------------------
  return (
    <React.Fragment>
      {loadin && <Loading />}
      {!loadin && (
        <Content page="component">
          <BlockAthenty
            head={"Client List"}
            nav="Menu"
            title={"Client List"}
            description={
              <>
                Add new clients using <Link to="/customer/solutions/verify/add">Smart IDV</Link> or <Link to="/customer/solutions/kyc/add">Smart KYC</Link>.
              </>
            }
            buttons={[
              {
                text: "New Client",
                icon: "plus",
                link: "",
                onclick: "",
                tooltip: "Add New Client",
                id: "add-client",
                form: "my-form",
                linkTo: "/customer/menu/client-list/add",
                className: "btn-icon ",
                color: "primary",
              }
            ]}
          />

          <Block>
            {/* {tableData && (
              <PreviewCard>
                <ClientListTable
                  data={tableData}
                  columns={tableCols}
                  pagination
                  className="nk-tb-list"
                  selectableRows={true}
                />
              </PreviewCard>
            )} */}
            {/* ----------------------new table--------------------------------- */}
            <MainTable columns={tableCols} route="/customer/a/client" search_placeholder='matter ID' compact />
          </Block>
        </Content>
      )}
      <ToastContainer />
    </React.Fragment>
  );
};
export default ClientList;
