import React from "react";
import Content from "../../../../layout/content/Content";
import { useEffect, useState } from "react";
import { apiRequest } from "../../../../utils/Api";
import { Block, PreviewCard, ClientListTable, BlockAthenty, Loading } from "../../../../components/Component";
import { toast, ToastContainer } from "react-toastify";
import { Link } from "react-router-dom";
// table imports
import { UserAvatar, Icon } from "../../../../components/Component";
import { capitalizeWords, dateConverter, findUpper } from "../../../../utils/Utils";
import { Badge, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import MainTable from "../../../components/athenty/table/Main.Table";
import moment from "moment";
import { parsePhoneNumber } from "libphonenumber-js";

const MattertList = () => {
  const [tableData, setTableData] = useState();
  const [loadin, setLoading] = useState(false);

  useEffect(() => {
    // getClientList();
  }, []);


  // table ------------------------------------------------------
  const tableCols = [
    {
      // name: "Matter ID / Due date",
      // name: ["Matter ID /", <br key={new Date()} />, "Due date"],
      name: ["Matter ID (Reason)"],
      selector: (row) => row.id,
      sortable: true,
      cell: (row) => (
        <Link to={`/customer/menu/matter-list/${row.uuid}/client-list`} state={{ matter_id: row.matter_id, matter_reason: row.matter_reason }}>
          <div className="user-card " data-toggle="tooltip">
            <div className="user-info">
              <span className="tb-lead">{row.matter_id}</span>
              <span>({row.matter_reason})</span>
              <br />
            </div>
          </div>
        </Link>
      ),
    },
    {
      // name: "Matter ID / Due date",
      // name: ["Matter ID /", <br key={new Date()} />, "Due date"],
      name: ["Clients"],
      selector: (row) => row.id,
      sortable: true,
      hide: "sm",
      grow: 3,
      cell: (row) => (
        <div className="nk-menu-icon" data-toggle="tooltip" title={row.clients_count}>
          <Badge pill color="gray">
            {row.clients_count}
          </Badge>
        </div>
      ),
    },
    {
      // name: "Matter ID / Due date",
      // name: ["Matter ID /", <br key={new Date()} />, "Due date"],
      name: ["Primary Client"],
      selector: (row) => row.id,
      sortable: true,
      hide: "sm",
      grow: 3,
      cell: (row) => (
        <div className="user-card " data-toggle="tooltip">
          <div className="user-info">
            {row.client ?
              <>
                <span className="tb-lead">{capitalizeWords(row.client.name_first + " " + row.client.name_last)}</span>
                <span>
                  <Icon name="call-fill" className={"pe-1"} />
                  <a href={`tel:${row.client.phone}`}>{parsePhoneNumber("+1" + row.client.phone).formatNational()}</a>
                  <br />
                  <Icon name="mail" className={"pe-1"} />
                  <a href={`mailto://${row.client.email}`}>{row.client.email}</a>
                </span>
              </>
              :
              '-'
            }
          </div>
        </div>
      ),
    },
    {
      name: "Created",
      selector: (row) => row.created_at,
      sortable: true,
      grow: 2.5,
      hide: "md",
      cell: (row) => (
        <div className="user-card">
          <div className="user-info">
            <span className="tb-lead">
              {dateConverter(row.created_at, true)[0]} <span className={"dot"}></span>
            </span>
            <span>{dateConverter(row.created_at, true)[1]}</span>
          </div>
        </div>
      ),
    },
  ];
  const tableColsOld = [
    // {
    //   name: "Client",
    //   selector: (row) => row.name,
    //   compact: true,
    //   grow: 6,

    //   cell: (row) => (
    //     <Link to={`/customer/menu/client-list/${row.id}`}>
    //       <div className="user-card position-relative">
    //         <UserAvatar theme={row.avatarBg} text={findUpper(row.name.replace(" (R)"))} image={row.facial} className="">
    //           {/* <span className="position-absolute top-100 start-100 translate-middle badge rounded-pill bg-danger"></span> */}
    //           <div className={`status dot dot-lg ${row.status === "attestation" ? "dot-success" : "dot-warning"} `}>
    //             {/* <Icon name="shield-check" /> */}
    //           </div>
    //         </UserAvatar>
    //         <div className="user-info">
    //           <span className="tb-lead">{row.name} </span>
    //           <span>{row.external_id}</span>
    //         </div>
    //       </div>
    //     </Link>
    //   ),
    //   sortable: true,
    // },
    {
      name: "Matter ID / Reason",
      selector: (row) => row.location,
      grow: 5,
      cell: (row) => (
        <div className="user-card">
          <div className="user-info">
            <span className="tb-lead"></span>
            <span>
              <Icon name="call-fill" className={"pe-1"} />
              <a href={`tel:${row.phone}`}>{parsePhoneNumber("+1" + row.phone).formatNational()}</a>
              <br />
              <Icon name="mail" className={"pe-1"} />
              <a href={`mailto://${row.email}`}>{row.email}</a>
            </span>
          </div>
        </div>
      ),
    },
    {
      name: "Requests",
      center: true,
      selector: (row) => row.status,
      grow: 2,
      hide: "lg",
      cell: (row) => (
        <div
          className="nk-menu-icon"
          data-toggle="tooltip"
          title={row.status.charAt(0).toUpperCase() + row.status.slice(1)}
        >
          <Badge pill color="gray">
            {row.request_count}
          </Badge>
        </div>
      ),
    },
    {
      name: "Last Verified",
      selector: (row) => row.created_at,
      grow: 4.5,
      sortable: true,
      cell: (row) => (
        <div className="">
          {row.created_at && (
            <span>
              {" "}
              {new Date(row.created_at).toLocaleDateString("default", { weekday: "long" }) +
                " " +
                new Date(row.created_at).toLocaleDateString("default", {
                  month: "long",
                  day: "numeric",
                  year: "numeric",
                })}
            </span>
          )}
          {row.created_at && (
            <span>
              <br />
              {Math.trunc((new Date().getTime() - new Date(row.created_at)) / (1000 * 3600 * 24)) === 0
                ? "Today"
                : Math.trunc((new Date().getTime() - new Date(row.created_at)) / (1000 * 3600 * 24)) === 1
                  ? "Yesterday"
                  : Math.trunc((new Date().getTime() - new Date(row.created_at)) / (1000 * 3600 * 24)) + " Days ago"}
            </span>
          )}
          {!row.created_at && <span>Never Verified</span>}
        </div>
      ),
    },

    // {
    //   name: "",
    //   selector: (row) => row.name,

    //   cell: (row) => (
    //     <UncontrolledDropdown>
    //       <DropdownToggle tag="a" className="dropdown-toggle btn btn-icon btn-trigger">
    //         <Icon name="more-v"></Icon>
    //       </DropdownToggle>
    //       <DropdownMenu end>
    //         <li onClick={() => console.log("click")}>
    //           <DropdownItem
    //             tag="a"
    //             href="#edit"
    //             onClick={(ev) => {
    //               ev.preventDefault();
    //             }}
    //           >
    //             <Icon name="edit"></Icon>
    //             <span>Edit</span>
    //           </DropdownItem>
    //         </li>
    //         <React.Fragment>
    //           <li className="divider"></li>
    //           <li onClick={() => console.log("click")}>
    //             <DropdownItem
    //               tag="a"
    //               href="#suspend"
    //               onClick={(ev) => {
    //                 ev.preventDefault();
    //               }}
    //             >
    //               <Icon name="na"></Icon>
    //               <span>Suspend User</span>
    //             </DropdownItem>
    //           </li>
    //         </React.Fragment>
    //       </DropdownMenu>
    //     </UncontrolledDropdown>
    //   ),
    //   sortable: true,
    // },
  ];

  const tools = [
    {
      text: "Send Email",
      onclick: (row) => (window.location = `tel:${row.to_phone}`),
      icon: "mail",
    },
    {
      text: "Call",
      onclick: (row) => (window.location = `tel:${row.to_phone}`),
      icon: "call",
    },
    {
      text: "Re-Verify",
      icon: "done",
    },
  ];

  // display ---------------------------------------------------------------------
  return (
    <React.Fragment>
      {loadin && <Loading />}
      {!loadin && (
        <Content page="component">
          <BlockAthenty
            head={"Matter List"}
            nav="Menu"
            title={"Matter List"}
            description={<>View and manage Matters.</>}
          />

          <Block>
            {/* {tableData && (
              <PreviewCard>
                <ClientListTable
                  data={tableData}
                  columns={tableCols}
                  pagination
                  className="nk-tb-list"
                  selectableRows={true}
                />
              </PreviewCard>
            )} */}
            {/* ----------------------new table--------------------------------- */}
            <MainTable compact columns={tableCols} route="/customer/a/matter" />
          </Block>
        </Content>
      )}
      <ToastContainer />
    </React.Fragment>
  );
};
export default MattertList;
