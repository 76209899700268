import Content from "../../../../layout/content/Content";
import React, { useEffect, useRef, useState } from "react";

import { Block, BlockAthenty, Icon } from "../../../../components/Component";
import { Card } from "reactstrap";
import SolutionAddWizard from "./solotionAddWizard/SolutionAddWizard";
import { useParams } from "react-router";
import { verificationTitle } from "../../../../utils/Utils";


const SolutionAdd = () => {
  const { type } = useParams()
  const submitRef = useRef();
  const [selectedTab, setSelectedTab] = useState("new");

  const asignRef = () => {
    return submitRef.current.click();
  };

  return (
    <React.Fragment>
      <Content page="component">
        <BlockAthenty
          head={`New Smart ${verificationTitle(type)} Request`}
          nav={`Solutions / Smart ${verificationTitle(type)}`}
          title={`New Smart ${verificationTitle(type)} Request`}
          back
          description={`Send new ${verificationTitle(type)} request`}
        />
      </Content>
      <Block className="nk-content-wrap">
        <Card className="card-bordered ">
          <div className="card-inner card-inner-lg">
            <SolutionAddWizard />
          </div>
        </Card>
      </Block>
    </React.Fragment>

  );
};
export default SolutionAdd;
