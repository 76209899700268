import { UserAvatar } from "../../../../../../components/Component";
import { Button, Card } from "reactstrap";
import { Col, Row } from "react-bootstrap";
import { useEffect } from "react";
import { findUpper, getInitials } from "../../../../../../utils/Utils";




const SolutionWizardFinal = (props) => {

    const { getValues, watch } = props


    useEffect(() => {
        // filterData(getValues('params'))
        console.log(getValues())
    }, [])


    return (
        <>
            <div className="accordion-item pb-2">
                Are you certain you want to submit a verification request based on the information provided below?
            </div>
            <Row>
                <Col xl="6" sm="6" xs="12" className="mb-2">

                    <Row>


                        <>
                            <div className="form-label-group">
                                <label className="form-label" htmlFor="default-01">
                                    Client Information

                                </label>
                            </div>
                            <Card style={{ width: '93%' }} className="card-preview mx-auto" >
                                <div className="team pt-2 pb-2">
                                    <div className="user-card user-card-s2">
                                        <UserAvatar className="md" text={getInitials(getValues('client').name)} >
                                            <div className="status"></div>
                                        </UserAvatar>
                                        <div className="user-info">
                                            <h6>{getValues('client') && getValues('client').name}</h6>
                                            <span className="sub-text">{getValues('client_email')}</span>
                                        </div>
                                    </div>
                                    <div className="team-details">
                                        <p>Phone Number: {getValues('client').phone}</p>
                                    </div>
                                    <div className="team-details">
                                        <p>Client ID: {getValues('client').external_id}</p>
                                    </div>
                                </div >
                            </Card>

                        </>


                    </Row>


                </Col>
                <Col xl="6" sm="6" xs="12" className="mb-2">

                    <Row>


                        <>
                            <div className="form-label-group">
                                <label className="form-label" htmlFor="default-01">
                                    Matter Information

                                </label>
                            </div>
                            {/* <Card style={{ height: '200px', width: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} className="card-preview mx-auto" > */}
                            <Card style={{ width: '93%', height: '211px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} className="card-preview mx-auto" >
                                <div className="team">

                                    <div className="team-details">
                                        <p>Matter ID: {getValues('matter').label}</p>
                                    </div>
                                    <div className="team-details">
                                        <p>Matter Reason: {getValues('matter') && getValues('matter').value ? getValues('matter').reason : getValues('matter_Reason').reason}</p>
                                    </div>



                                </div >
                            </Card>

                        </>


                    </Row>

                </Col>

            </Row >
            <Row>
                <div className="form-label-group">
                    <label className="form-label" htmlFor="default-01">
                        Recipients
                    </label>
                </div>
            </Row>
            <Card className="ps-2 pe-2 pb-2 card-preview mx-auto">
                <Row className="pt-2">
                    <>
                        <div className="form-label-group">
                            <label className="form-label" htmlFor="default-01">
                                Primary Recipient

                            </label>
                        </div>
                        {/* <Card style={{ height: '200px', width: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} className="card-preview mx-auto" > */}
                        <Card style={{ width: '97%', display: 'flex', justifyContent: 'center', }} className="card-preview mx-auto" >
                            <div className="team">


                                <div className={"table-responsive"}>
                                    <table className="table">
                                        <thead >
                                            <tr>

                                                {/* <th>Role</th> */}
                                                <th>Name</th>
                                                <th>Title</th>
                                                <th>Email</th>
                                                <th>Phone</th>

                                            </tr>
                                        </thead>
                                        <tbody>


                                            <tr key={getValues('agent').id}>
                                                {/* <th>{item.id}</th> */}
                                                {/* <td>{getValues('agent').role}</td> */}
                                                <td>{getValues('agent').label || 'No Title'}</td>
                                                <td>{getValues('agent').title}</td>
                                                <td>{getValues('agent').email || 'No Email'}</td>
                                                <td>{getValues('agent').phone || 'No Mobile'}</td>
                                            </tr>


                                        </tbody>
                                    </table>
                                </div>



                            </div >
                        </Card>

                    </>


                </Row>
                {getValues('third-party') && getValues('third-party').length > 0 && <Row className="pt-2">
                    <>
                        <div className="form-label-group">
                            <label className="form-label" htmlFor="default-01">
                                Third-party recipient(s)

                            </label>
                        </div>
                        {/* <Card style={{ height: '200px', width: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} className="card-preview mx-auto" > */}
                        <Card style={{ width: '97%', display: 'flex', justifyContent: 'center', }} className="card-preview mx-auto" >
                            <div className="team">


                                <div className={"table-responsive"}>
                                    <table className="table">
                                        <thead >
                                            <tr>

                                                <th>Type</th>
                                                <th>Company</th>
                                                <th>Title</th>
                                                <th>Name</th>
                                                <th>Email</th>
                                                <th>Mobile</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {getValues('third-party').map((item) => {
                                                return (
                                                    <tr key={item.id}>
                                                        {/* <th>{item.id}</th> */}
                                                        <td>{item.type}</td>
                                                        <td>{item.company}</td>
                                                        <td>{item.title || 'No Title'}</td>
                                                        <td>{item.name_first || 'No Name'}</td>
                                                        <td>{item.email || 'No Email'}</td>
                                                        <td>{item.mobile || 'No Mobile'}</td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>



                            </div >
                        </Card>

                    </>


                </Row>}
            </Card>
            <div className="actions clearfix">
                <ul>
                    <li>
                        <Button color="primary" onClick={props.formSubmit}>Send</Button>
                    </li>
                    <li>
                        <Button color="primary" onClick={props.prev}>Back</Button>
                    </li>
                </ul>
            </div>
        </>


    );
}

export default SolutionWizardFinal