const introCard = [

  {
    icon: "support-general",
    title: "Matter List",
    description: "View and organize matters across clients.",
    linkTo: "/customer/menu/matter-list",
    linkText: "Manage Matter List",
  },
  // {
  //   icon: "profile",
  //   title: "Your Profile",
  //   description: "See your profile data and manage your password, notifications and more.",
  //   linkTo: "/customer/admin/access/profile",
  //   linkText: "Manage Your Profile",
  // },
  {
    icon: "client-list",
    title: "Client List",
    description: "View clients and associated Smart IDV requests with documents.",
    linkTo: "/customer/menu/client-list",
    linkText: "View Clients",
  },
  {
    icon: "connect-secure",
    title: "Smart IDV",
    description: "Send a Smart IDV request to a new or existing client.",
    linkTo: "/customer/solutions/verify",
    linkText: "Start Now",
  },
  // {
  //   icon: "bills",
  //   title: "Invoice History",
  //   description: "Check out your Invoice history. You can also download or print your invoices.",
  //   linkTo: "/customer/admin/billing/invoices",
  //   linkText: "Invoice History",
  // },
  {
    icon: "support-account",
    title: "Smart KYC",
    description: "Send a Smart IDV request to a new or existing client.",
    linkTo: "/customer/solutions/kyc",
    linkText: "Start Now",
  },
];

export default introCard;
