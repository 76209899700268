import React from "react";
import Content from "../../../../../../layout/content/Content";
import Head from "../../../../../../layout/head/Head";
import {
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
} from "../../../../../../components/Component";

const ClientProfile = (props) => {
  // console.log(props);
  return (
    <React.Fragment>
      <Content>
        <Head title="Client Details"></Head>
        <BlockHead size="lg">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h4">File Details</BlockTitle>
              <BlockDes>
                <p>
                  This information was provided at {props.data && props.data.latest && props.data.latest.created_at} by
                  Smart IDV
                </p>
              </BlockDes>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <div className="card card-bordered card-stretch">
          <div className="card-inner-group">
            <div className="card-inner p-0">
              <div className="nk-tb-list nk-tb-ulist">
                <div className="profile-ud wider">
                  <span
                    style={{
                      fontSize: "14px",
                      margin: "10px 0px 10px 20px",
                      width: "50%",
                    }}
                    className="profile-ud-label"
                  >
                    Title
                  </span>

                  <span
                    style={{
                      textAlign: "left",
                      fontSize: "14px",
                      margin: "10px 20px 10px 0px",
                      width: "50%",
                    }}
                    id="title"
                    className="profile-ud-value"
                  >
                    {props.data && props.data.latest && props.data.latest.person.title}
                  </span>
                </div>
                <hr style={{ margin: "0" }} />
                <div className="profile-ud wider">
                  <span
                    style={{
                      fontSize: "14px",
                      margin: "10px 0px 10px 20px",
                      width: "50%",
                    }}
                    className="profile-ud-label"
                  >
                    First Name
                  </span>

                  <span
                    style={{
                      textAlign: "left",
                      fontSize: "14px",
                      margin: "10px 20px 10px 0px",
                      width: "50%",
                    }}
                    id="name_first"
                    className="profile-ud-value"
                  >
                    {props.data && props.data.latest && props.data.latest.person.name_first}
                  </span>
                </div>
                <hr style={{ margin: "0" }} />
                <div className="profile-ud wider">
                  <span
                    style={{
                      fontSize: "14px",
                      margin: "10px 0px 10px 20px",
                      width: "50%",
                    }}
                    className="profile-ud-label"
                  >
                    Middle Name(s)
                  </span>

                  <span
                    style={{
                      textAlign: "left",
                      fontSize: "14px",
                      margin: "10px 20px 10px 0px",
                      width: "50%",
                    }}
                    id="name_middle"
                    className="profile-ud-value"
                  >
                    {props.data && props.data.latest && props.data.latest.person.name_middle}
                  </span>
                </div>
                <hr style={{ margin: "0" }} />
                <div className="profile-ud wider">
                  <span
                    style={{
                      fontSize: "14px",
                      margin: "10px 0px 10px 20px",
                      width: "50%",
                    }}
                    className="profile-ud-label"
                  >
                    Last Name
                  </span>

                  <span
                    style={{
                      textAlign: "left",
                      fontSize: "14px",
                      margin: "10px 20px 10px 0px",
                      width: "50%",
                    }}
                    id="name-last"
                    className="profile-ud-value"
                  >
                    {props.data && props.data.latest && props.data.latest.person.name_last}
                  </span>
                </div>
                <hr style={{ margin: "0" }} />
                <div className="profile-ud wider">
                  <span
                    style={{
                      fontSize: "14px",
                      margin: "10px 0px 10px 20px",
                      width: "50%",
                    }}
                    className="profile-ud-label"
                  >
                    Suffix
                  </span>

                  <span
                    style={{
                      textAlign: "left",
                      fontSize: "14px",
                      margin: "10px 20px 10px 0px",
                      width: "50%",
                    }}
                    id="suffix"
                    className="profile-ud-value"
                  >
                    {props.data && props.data.latest && props.data.latest.person.suffix}
                  </span>
                </div>
                <hr style={{ margin: "0" }} />
                <div className="profile-ud wider">
                  <span
                    style={{
                      fontSize: "14px",
                      margin: "10px 0px 10px 20px",
                      width: "50%",
                    }}
                    className="profile-ud-label"
                  >
                    Also Known As
                  </span>

                  <span
                    style={{
                      textAlign: "left",
                      fontSize: "14px",
                      margin: "10px 20px 10px 0px",
                      width: "50%",
                    }}
                    id="aka"
                    className="profile-ud-value"
                  >
                    {props.data && props.data.latest && props.data.latest.person.name_alias}
                  </span>
                </div>
                <hr style={{ margin: "0" }} />
                <div className="profile-ud wider">
                  <span
                    style={{
                      fontSize: "14px",
                      margin: "10px 0px 10px 20px",
                      width: "50%",
                    }}
                    className="profile-ud-label"
                  >
                    Legally Changed Name?
                  </span>

                  <span
                    style={{
                      textAlign: "left",
                      fontSize: "14px",
                      margin: "10px 20px 10px 0px",
                      width: "50%",
                    }}
                    id="name_changed"
                    className="profile-ud-value"
                  >
                    {props.data && props.data.latest && props.data.latest.person.name_change}
                  </span>
                </div>
                <hr style={{ margin: "0" }} />
                <div className="profile-ud wider">
                  <span
                    style={{
                      fontSize: "14px",
                      margin: "10px 0px 10px 20px",
                      width: "50%",
                    }}
                    className="profile-ud-label"
                  >
                    Previous Name
                  </span>

                  <span
                    style={{
                      textAlign: "left",
                      fontSize: "14px",
                      margin: "10px 20px 10px 0px",
                      width: "50%",
                    }}
                    id="name_prev"
                    className="profile-ud-value"
                  >
                    {props.data && props.data.latest && props.data.latest.person.name_previous}
                  </span>
                </div>
                <hr style={{ margin: "0" }} />
                <div className="profile-ud wider">
                  <span
                    style={{
                      fontSize: "14px",
                      margin: "10px 0px 10px 20px",
                      width: "50%",
                    }}
                    className="profile-ud-label"
                  >
                    Pronoun
                  </span>

                  <span
                    style={{
                      textAlign: "left",
                      fontSize: "14px",
                      margin: "10px 20px 10px 0px",
                      width: "50%",
                    }}
                    id="pronoun"
                    className="profile-ud-value"
                  >
                    {props.data && props.data.latest && props.data.latest.person.pronoun}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="nk-block-head">
          <h5 className="title">Personal Information</h5>
        </div>
        <div className="card card-bordered card-stretch">
          <div className="card-inner-group">
            <div className="card-inner p-0">
              <div className="nk-tb-list nk-tb-ulist">
                <div className="profile-ud wider">
                  <span
                    style={{
                      fontSize: "14px",
                      margin: "10px 0px 10px 20px",
                      width: "50%",
                    }}
                    className="profile-ud-label"
                  >
                    Date of Birth
                  </span>

                  <span
                    style={{
                      textAlign: "left",
                      fontSize: "14px",
                      margin: "10px 20px 10px 0px",
                      width: "50%",
                    }}
                    id="title"
                    className="profile-ud-value"
                  >
                    {props.data && props.data.latest && props.data.latest.person.birthdate}
                  </span>
                </div>
                <hr style={{ margin: "0" }} />
                <div className="profile-ud wider">
                  <span
                    style={{
                      fontSize: "14px",
                      margin: "10px 0px 10px 20px",
                      width: "50%",
                    }}
                    className="profile-ud-label"
                  >
                    Sex
                  </span>

                  <span
                    style={{
                      textAlign: "left",
                      fontSize: "14px",
                      margin: "10px 20px 10px 0px",
                      width: "50%",
                    }}
                    id="name_first"
                    className="profile-ud-value"
                  >
                    {props.data && props.data.latest && props.data.latest.person.sex}
                  </span>
                </div>
                <hr style={{ margin: "0" }} />
                <div className="profile-ud wider">
                  <span
                    style={{
                      fontSize: "14px",
                      margin: "10px 0px 10px 20px",
                      width: "50%",
                    }}
                    className="profile-ud-label"
                  >
                    Place of Birth
                  </span>

                  <span
                    style={{
                      textAlign: "left",
                      fontSize: "14px",
                      margin: "10px 20px 10px 0px",
                      width: "50%",
                    }}
                    id="name_middle"
                    className="profile-ud-value"
                  >
                    {props.data && props.data.latest && props.data.latest.person.birthplace}
                  </span>
                </div>
                <hr style={{ margin: "0" }} />
                <div className="profile-ud wider">
                  <span
                    style={{
                      fontSize: "14px",
                      margin: "10px 0px 10px 20px",
                      width: "50%",
                    }}
                    className="profile-ud-label"
                  >
                    Citizenship
                  </span>

                  <span
                    style={{
                      textAlign: "left",
                      fontSize: "14px",
                      margin: "10px 20px 10px 0px",
                      width: "50%",
                    }}
                    id="name-last"
                    className="profile-ud-value"
                  >
                    {props.data && props.data.latest && props.data.latest.person.citizenship}
                  </span>
                </div>
                <hr style={{ margin: "0" }} />
                <div className="profile-ud wider">
                  <span
                    style={{
                      fontSize: "14px",
                      margin: "10px 0px 10px 20px",
                      width: "50%",
                    }}
                    className="profile-ud-label"
                  >
                    SIN/SSN/Tax ID
                  </span>

                  <span
                    style={{
                      textAlign: "left",
                      fontSize: "14px",
                      margin: "10px 20px 10px 0px",
                      width: "50%",
                    }}
                    id="suffix"
                    className="profile-ud-value"
                  >
                    {props.data && props.data.latest && props.data.latest.person.sn}
                  </span>
                </div>
                <hr style={{ margin: "0" }} />
                <div className="profile-ud wider">
                  <span
                    style={{
                      fontSize: "14px",
                      margin: "10px 0px 10px 20px",
                      width: "50%",
                    }}
                    className="profile-ud-label"
                  >
                    Maritial Status
                  </span>

                  <span
                    style={{
                      textAlign: "left",
                      fontSize: "14px",
                      margin: "10px 20px 10px 0px",
                      width: "50%",
                    }}
                    id="aka"
                    className="profile-ud-value"
                  >
                    {props.data && props.data.latest && props.data.latest.person.maritial_status}
                  </span>
                </div>
                <hr style={{ margin: "0" }} />
                <div className="profile-ud wider">
                  <span
                    style={{
                      fontSize: "14px",
                      margin: "10px 0px 10px 20px",
                      width: "50%",
                    }}
                    className="profile-ud-label"
                  >
                    Occupation
                  </span>

                  <span
                    style={{
                      textAlign: "left",
                      fontSize: "14px",
                      margin: "10px 20px 10px 0px",
                      width: "50%",
                    }}
                    id="name_changed"
                    className="profile-ud-value"
                  >
                    {props.data && props.data.latest && props.data.latest.person.occupation}
                  </span>
                </div>
                <hr style={{ margin: "0" }} />
                <div className="profile-ud wider">
                  <span
                    style={{
                      fontSize: "14px",
                      margin: "10px 0px 10px 20px",
                      width: "50%",
                    }}
                    className="profile-ud-label"
                  >
                    Annual Income
                  </span>

                  <span
                    style={{
                      textAlign: "left",
                      fontSize: "14px",
                      margin: "10px 20px 10px 0px",
                      width: "50%",
                    }}
                    id="name_prev"
                    className="profile-ud-value"
                  >
                    {props.data && props.data.latest && props.data.latest.person.income}
                  </span>
                </div>
                <hr style={{ margin: "0" }} />
                <div className="profile-ud wider">
                  <span
                    style={{
                      fontSize: "14px",
                      margin: "10px 0px 10px 20px",
                      width: "50%",
                    }}
                    className="profile-ud-label"
                  >
                    Company
                  </span>

                  <span
                    style={{
                      textAlign: "left",
                      fontSize: "14px",
                      margin: "10px 20px 10px 0px",
                      width: "50%",
                    }}
                    id="pronoun"
                    className="profile-ud-value"
                  >
                    {props.data && props.data.latest && props.data.latest.person.company}
                  </span>
                </div>
                <hr style={{ margin: "0" }} />
                <div className="profile-ud wider">
                  <span
                    style={{
                      fontSize: "14px",
                      margin: "10px 0px 10px 20px",
                      width: "50%",
                    }}
                    className="profile-ud-label"
                  >
                    Company Website
                  </span>

                  <span
                    style={{
                      textAlign: "left",
                      fontSize: "14px",
                      margin: "10px 20px 10px 0px",
                      width: "50%",
                    }}
                    id="pronoun"
                    className="profile-ud-value"
                  >
                    <a href={props.data && props.data.latest && props.data.latest.person.company_website}>
                      {props.data && props.data.latest && props.data.latest.person.company_website}
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="nk-block-head">
          <h5 className="title">Address and Contact Information</h5>
        </div>
        <div className="card card-bordered card-stretch">
          <div className="card-inner-group">
            <div className="card-inner p-0">
              <div className="nk-tb-list nk-tb-ulist">
                <div className="profile-ud wider">
                  <span
                    style={{
                      fontSize: "14px",
                      margin: "10px 0px 10px 20px",
                      width: "50%",
                    }}
                    className="profile-ud-label"
                  >
                    Country of Tax Residency
                  </span>

                  <span
                    style={{
                      textAlign: "left",
                      fontSize: "14px",
                      margin: "10px 20px 10px 0px",
                      width: "50%",
                    }}
                    id="title"
                    className="profile-ud-value"
                  >
                    {props.data && props.data.latest && props.data.latest.location.tax_country}
                  </span>
                </div>
                <hr style={{ margin: "0" }} />
                <div className="profile-ud wider">
                  <span
                    style={{
                      fontSize: "14px",
                      margin: "10px 0px 10px 20px",
                      width: "50%",
                    }}
                    className="profile-ud-label"
                  >
                    Full Legal Address
                  </span>

                  <span
                    style={{
                      textAlign: "left",
                      fontSize: "14px",
                      margin: "10px 20px 10px 0px",
                      width: "50%",
                    }}
                    id="name_first"
                    className="profile-ud-value"
                  >
                    {props.data &&
                      props.data.latest &&
                      props.data.latest.location &&
                      props.data.latest.location.legal.address1
                      ? `${props.data.latest.location.legal.address1}${props.data.latest.location.legal.address2}, 
                      ${props.data.latest.location.legal.city}, 
                      ${props.data.latest.location.legal.region}, 
                      ${props.data.latest.location.legal.country}
                      ${props.data.latest.location.legal.postal}`
                      : ""}
                  </span>
                </div>
                <hr style={{ margin: "0" }} />
                <div className="profile-ud wider">
                  <span
                    style={{
                      fontSize: "14px",
                      margin: "10px 0px 10px 20px",
                      width: "50%",
                    }}
                    className="profile-ud-label"
                  >
                    Primary Residence
                  </span>

                  <span
                    style={{
                      textAlign: "left",
                      fontSize: "14px",
                      margin: "10px 20px 10px 0px",
                      width: "50%",
                    }}
                    id="name_middle"
                    className="profile-ud-value"
                  >
                    {props.data &&
                      props.data.latest &&
                      props.data.latest.location &&
                      props.data.latest.location.primary &&
                      props.data.latest.location.primary.address1
                      ? `${props.data.latest.location.primary.address1}${props.data.latest.location.primary.address2}, 
                          ${props.data.latest.location.primary.city}, 
                          ${props.data.latest.location.primary.region}, 
                          ${props.data.latest.location.primary.country}
                          ${props.data.latest.location.primary.postal}`
                      : ""}
                  </span>
                </div>
                <hr style={{ margin: "0" }} />
                <div className="profile-ud wider">
                  <span
                    style={{
                      fontSize: "14px",
                      margin: "10px 0px 10px 20px",
                      width: "50%",
                    }}
                    className="profile-ud-label"
                  >
                    Email
                  </span>

                  <span
                    style={{
                      textAlign: "left",
                      fontSize: "14px",
                      margin: "10px 20px 10px 0px",
                      width: "50%",
                    }}
                    id="name-last"
                    className="profile-ud-value"
                  >
                    {props.data && props.data.email}
                  </span>
                </div>
                <hr style={{ margin: "0" }} />
                <div className="profile-ud wider">
                  <span
                    style={{
                      fontSize: "14px",
                      margin: "10px 0px 10px 20px",
                      width: "50%",
                    }}
                    className="profile-ud-label"
                  >
                    Phone Number
                  </span>

                  <span
                    style={{
                      textAlign: "left",
                      fontSize: "14px",
                      margin: "10px 20px 10px 0px",
                      width: "50%",
                    }}
                    id="suffix"
                    className="profile-ud-value"
                  >
                    {props.data && props.data.phone}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="nk-block-head">
          <h5 className="title">Verification Info</h5>
        </div>
        <div className="card card-bordered card-stretch">
          <div className="card-inner-group">
            <div className="card-inner p-0">
              <div className="nk-tb-list nk-tb-ulist">
                <div className="profile-ud wider">
                  <span
                    style={{
                      fontSize: "14px",
                      margin: "10px 0px 10px 20px",
                      width: "50%",
                    }}
                    className="profile-ud-label"
                  >
                    Submitted By
                  </span>

                  <span
                    style={{
                      textAlign: "left",
                      fontSize: "14px",
                      margin: "10px 20px 10px 0px",
                      width: "50%",
                    }}
                    id="title"
                    className="profile-ud-value"
                  >
                    {/* {props.data &&
                      props.data.latest &&
                      props.data.latest.verificationInfo &&
                      props.data.latest.verificationInfo.submitted_by} */}
                  </span>
                </div>
                <hr style={{ margin: "0" }} />
                <div className="profile-ud wider">
                  <span
                    style={{
                      fontSize: "14px",
                      margin: "10px 0px 10px 20px",
                      width: "50%",
                    }}
                    className="profile-ud-label"
                  >
                    Submitted On
                  </span>

                  <span
                    style={{
                      textAlign: "left",
                      fontSize: "14px",
                      margin: "10px 20px 10px 0px",
                      width: "50%",
                    }}
                    id="name_first"
                    className="profile-ud-value"
                  >
                    {/* {props.data &&
                      props.data.latest &&
                      props.data.latest.verificationInfo &&
                      props.data.latest.verificationInfo.submitted_on} */}
                  </span>
                </div>
                <hr style={{ margin: "0" }} />
                <div className="profile-ud wider">
                  <span
                    style={{
                      fontSize: "14px",
                      margin: "10px 0px 10px 20px",
                      width: "50%",
                    }}
                    className="profile-ud-label"
                  >
                    Submitted At
                  </span>

                  <span
                    style={{
                      textAlign: "left",
                      fontSize: "14px",
                      margin: "10px 20px 10px 0px",
                      width: "50%",
                    }}
                    id="name_middle"
                    className="profile-ud-value"
                  >
                    {/* {props.data &&
                      props.data.latest &&
                      props.data.latest.verificationInfo &&
                      props.more.verificationInfo.submitted_at} */}
                  </span>
                </div>
                <hr style={{ margin: "0" }} />
                <div className="profile-ud wider">
                  <span
                    style={{
                      fontSize: "14px",
                      margin: "10px 0px 10px 20px",
                      width: "50%",
                    }}
                    className="profile-ud-label"
                  >
                    Status
                  </span>

                  <span
                    style={{
                      textAlign: "left",
                      fontSize: "14px",
                      margin: "10px 20px 10px 0px",
                      width: "50%",
                    }}
                    id="name-last"
                    className="profile-ud-value"
                  >
                    {props.data && props.data.status}
                  </span>
                </div>
                <hr style={{ margin: "0" }} />
                <div className="profile-ud wider">
                  <span
                    style={{
                      fontSize: "14px",
                      margin: "10px 0px 10px 20px",
                      width: "50%",
                    }}
                    className="profile-ud-label"
                  >
                    Last Checked By
                  </span>

                  <span
                    style={{
                      textAlign: "left",
                      fontSize: "14px",
                      margin: "10px 20px 10px 0px",
                      width: "50%",
                    }}
                    id="suffix"
                    className="profile-ud-value"
                  >
                    {/* {props.data &&
                      props.data.latest &&
                      props.data.latest.verificationInfo &&
                      props.more.verificationInfo.last_check_by} */}
                  </span>
                </div>
                <hr style={{ margin: "0" }} />
                <div className="profile-ud wider">
                  <span
                    style={{
                      fontSize: "14px",
                      margin: "10px 0px 10px 20px",
                      width: "50%",
                    }}
                    className="profile-ud-label"
                  >
                    Last Checked On
                  </span>

                  <span
                    style={{
                      textAlign: "left",
                      fontSize: "14px",
                      margin: "10px 20px 10px 0px",
                      width: "50%",
                    }}
                    id="aka"
                    className="profile-ud-value"
                  >
                    {/* {props.data &&
                      props.data.latest &&
                      props.data.latest.verificationInfo &&
                      props.more.verificationInfo.last_check_on} */}
                  </span>
                </div>
                <hr style={{ margin: "0" }} />
                <div className="profile-ud wider">
                  <span
                    style={{
                      fontSize: "14px",
                      margin: "10px 0px 10px 20px",
                      width: "50%",
                    }}
                    className="profile-ud-label"
                  >
                    Digital Signature
                  </span>

                  <span
                    style={{
                      textAlign: "left",
                      fontSize: "14px",
                      margin: "10px 20px 10px 0px",
                      width: "50%",
                    }}
                    id="name_changed"
                    className="profile-ud-value"
                  >
                    {/* {props.data &&
                      props.data.latest &&
                      props.data.latest.verificationInfo &&
                      props.more.verificationInfo.digital_signature} */}
                  </span>
                </div>
                <hr style={{ margin: "0" }} />
                <div className="profile-ud wider">
                  <span
                    style={{
                      fontSize: "14px",
                      margin: "10px 0px 10px 20px",
                      width: "50%",
                    }}
                    className="profile-ud-label"
                  >
                    IPv4
                  </span>

                  <span
                    style={{
                      textAlign: "left",
                      fontSize: "14px",
                      margin: "10px 20px 10px 0px",
                      width: "50%",
                    }}
                    id="name_prev"
                    className="profile-ud-value"
                  >
                    {/* {props.data &&
                      props.data.latest &&
                      props.data.latest.verificationInfo &&
                      props.more.verificationInfo.IPv4} */}
                  </span>
                </div>
                <hr style={{ margin: "0" }} />
                <div className="profile-ud wider">
                  <span
                    style={{
                      fontSize: "14px",
                      margin: "10px 0px 10px 20px",
                      width: "50%",
                    }}
                    className="profile-ud-label"
                  >
                    Country
                  </span>

                  <span
                    style={{
                      textAlign: "left",
                      fontSize: "14px",
                      margin: "10px 20px 10px 0px",
                      width: "50%",
                    }}
                    id="pronoun"
                    className="profile-ud-value"
                  >
                    {/* {props.data &&
                      props.data.latest &&
                      props.data.latest.verificationInfo &&
                      props.more.verificationInfo.country} */}
                  </span>
                </div>
                <hr style={{ margin: "0" }} />
                <div className="profile-ud wider">
                  <span
                    style={{
                      fontSize: "14px",
                      margin: "10px 0px 10px 20px",
                      width: "50%",
                    }}
                    className="profile-ud-label"
                  >
                    Region
                  </span>

                  <span
                    style={{
                      textAlign: "left",
                      fontSize: "14px",
                      margin: "10px 20px 10px 0px",
                      width: "50%",
                    }}
                    id="pronoun"
                    className="profile-ud-value"
                  >
                    {/* {props.data &&
                      props.data.latest &&
                      props.data.latest.verificationInfo &&
                      props.more.verificationInfo.region} */}
                  </span>
                </div>
                {/* <hr style={{ margin: "0" }} />
                <div className="profile-ud wider">
                  <span
                    style={{
                      fontSize: "14px",
                      margin: "10px 0px 10px 20px",
                      width: "50%",
                    }}
                    className="profile-ud-label"
                  >
                    Country
                  </span>

                  <span
                    style={{
                      textAlign: "left",
                      fontSize: "14px",
                      margin: "10px 20px 10px 0px",
                      width: "50%",
                    }}
                    id="pronoun"
                    className="profile-ud-value"
                  >
                    {props.more.verificationInfo.region}
                  </span>
                </div> */}
                <hr style={{ margin: "0" }} />
                <div className="profile-ud wider">
                  <span
                    style={{
                      fontSize: "14px",
                      margin: "10px 0px 10px 20px",
                      width: "50%",
                    }}
                    className="profile-ud-label"
                  >
                    City
                  </span>

                  <span
                    style={{
                      textAlign: "left",
                      fontSize: "14px",
                      margin: "10px 20px 10px 0px",
                      width: "50%",
                    }}
                    id="pronoun"
                    className="profile-ud-value"
                  >
                    {/* {props.data &&
                      props.data.latest &&
                      props.data.latest.verificationInfo &&
                      props.more.verificationInfo.city} */}
                  </span>
                </div>
                <hr style={{ margin: "0" }} />
                <div className="profile-ud wider">
                  <span
                    style={{
                      fontSize: "14px",
                      margin: "10px 0px 10px 20px",
                      width: "50%",
                    }}
                    className="profile-ud-label"
                  >
                    Postal
                  </span>

                  <span
                    style={{
                      textAlign: "left",
                      fontSize: "14px",
                      margin: "10px 20px 10px 0px",
                      width: "50%",
                    }}
                    id="pronoun"
                    className="profile-ud-value"
                  >
                    {/* {props.data &&
                      props.data.latest &&
                      props.data.latest.verificationInfo &&
                      props.more.verificationInfo.postal} */}
                  </span>
                </div>
                <hr style={{ margin: "0" }} />
                <div className="profile-ud wider">
                  <span
                    style={{
                      fontSize: "14px",
                      margin: "10px 0px 10px 20px",
                      width: "50%",
                    }}
                    className="profile-ud-label"
                  >
                    User Agent
                  </span>

                  <span
                    style={{
                      textAlign: "left",
                      fontSize: "14px",
                      margin: "10px 20px 10px 0px",
                      width: "50%",
                    }}
                    id="pronoun"
                    className="profile-ud-value"
                  >
                    {/* {props.data &&
                      props.data.latest &&
                      props.data.latest.verificationInfo &&
                      props.more.verificationInfo.user_agent} */}
                  </span>
                </div>
                <hr style={{ margin: "0" }} />
                <div className="profile-ud wider">
                  <span
                    style={{
                      fontSize: "14px",
                      margin: "10px 0px 10px 20px",
                      width: "50%",
                    }}
                    className="profile-ud-label"
                  >
                    Geocoordinates
                  </span>

                  <span
                    style={{
                      textAlign: "left",
                      fontSize: "14px",
                      margin: "10px 20px 10px 0px",
                      width: "50%",
                    }}
                    id="pronoun"
                    className="profile-ud-value"
                  >
                    {/* Long :{" "}
                    {props.data &&
                      props.data.latest &&
                      props.data.latest.verificationInfo &&
                      props.more.verificationInfo.geocoordinates.Longitude}{" "}
                    Lat:{" "}
                    {props.data &&
                      props.data.latest &&
                      props.data.latest.verificationInfo &&
                      props.more.verificationInfo.geocoordinates.Latitude} */}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Content>
    </React.Fragment>
  );
};

export default ClientProfile;
