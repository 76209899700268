import React from "react";
import Content from "../../../../../../layout/content/Content";
import { ClientListTable, Icon } from "../../../../../../components/Component";
import moment from "moment";

import { capitalizeFirstLetter } from "../../../../../../utils/Utils";

const HistoryTab = ({ data }) => {
  console.log(data);
  const dataTableColumns = [
    {
      name: "Event",
      selector: (row) => row.event,
      style: { padding: "5px 10px" },
      cell: (row) => (
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly", alignItems: "left" }}>
          <span style={{ paddingLeft: "5px" }}>
            {capitalizeFirstLetter(row.event).replace('_', ' ')}
          </span>
        </div>
      ),
      sortable: false,
      grow: 0.7
    },
    {
      name: "Coordinates",
      selector: (row) => row.location,
      style: { padding: "5px 10px" },
      cell: (row) => (
        // <div className="d-flex justify-content-center align-items-center">


        // </div>
        <div >
          <a href={`https://www.google.com/maps/search/?api=1&query=${row.lat},${row.long}`} target="_blank" rel="noreferrer">
            <Icon style={{ fontSize: '25px', paddingLeft: '30px' }} name='map-pin-fill' />
          </a>
        </div>
        // <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-evenly", alignItems: "left" }}>
        //   <span style={{ paddingLeft: "5px" }}>{row.location.device.lat}</span>
        //   <span style={{ paddingLeft: "5px" }}>{row.location.device.long}</span>
        // </div>
      ),
      sortable: false,
      grow: 0.1,
    },
    {
      name: "IP Address (ASN Name)",
      selector: (row) => row.location,
      style: { padding: "5px 10px" },
      cell: (row) => (
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-evenly", alignItems: "left" }}>
          <span style={{ paddingLeft: "5px" }}>{row.ipv4}</span>
          <span style={{ paddingLeft: "5px", paddingTop: "5px", fontSize: "12px" }}>({row.asn})</span>
        </div>
      ),
      sortable: false,
    },
    {
      name: "Device",
      selector: (row) => row.location,
      style: { padding: "5px 10px" },
      cell: (row) => (
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-evenly", alignItems: "left" }}>
          <span style={{ paddingLeft: "5px" }}>{(row.device.device.vendor || row.device.os.name) +
            ", " +
            (row.device.device.model || row.device.engine.name) +
            ", " +
            (row.device.device.type || 'Desktop')}</span>
          {/* <span style={{ paddingLeft: "5px" }}>({row.asn_name})</span> */}
        </div>
      ),
      sortable: false,
    },
    // {
    //   name: "IP / useragent",
    //   selector: (row) => row.user,
    //   style: { padding: "5px 10px" },
    //   cell: (row) => (
    //     <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-evenly", alignItems: "left" }}>
    //       <span style={{ paddingLeft: "5px" }}>{row.ipv4}</span>
    //     </div>
    //   ),
    //   sortable: false,
    // },
    {
      name: "Date",
      selector: (row) => row.created_at,
      style: { padding: "5px 10px" },
      cell: (row) => (
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-evenly", alignItems: "left" }}>
          <span style={{ paddingLeft: "5px" }}>{moment(row.created_at).format("MM/DD/YYYY")}</span>
          <span style={{ paddingLeft: "5px", paddingTop: "5px", fontSize: "12px" }}>
            {moment(row.created_at).format("LT")}
          </span>
        </div>
      ),
      sortable: false,
      grow: 0.6
    },
  ];

  return (
    <React.Fragment>
      <Content>
        <ClientListTable columns={dataTableColumns} data={data || []} search={false} show={false} />
      </Content>
    </React.Fragment>
  );
};

export default HistoryTab;
