import User from "../../../images/avatar/b-sm.jpg";
import User2 from "../../../images/avatar/c-sm.jpg";
import User3 from "../../../images/avatar/a-sm.jpg";
import User4 from "../../../images/avatar/d-sm.jpg";
import { UserAvatar, Icon } from "../../../components/Component";
import { findUpper, findFirst, dateConverter, capitalizeWords, shortenString } from "../../../utils/Utils";
import { Badge, DropdownItem, DropdownMenu, DropdownToggle, Progress, UncontrolledDropdown, Util } from "reactstrap";
import { Link } from "react-router-dom";
import { FaIdCard, FaLocationDot, FaPassport, FaMicrophone } from "react-icons/fa6";
import { BsPersonBadge } from "react-icons/bs";
import moment from "moment/moment";

export const basicData = {
  header: ["#", "First", "Last"],
  data: [
    {
      id: "1",
      first: "Mark",
      last: "Otto",
    },
    {
      id: "2",
      first: "Jacob",
      last: "Thornton",
    },
    {
      id: "3",
      first: "Larry",
      last: "the bird",
    },
  ],
};

export const kycListColumns = [
  {
    // name: [
    //   <div key={new Date()} className="">
    //     Client / Reason
    //     {/* Client /<br /> Reason */}
    //   </div>,
    // ],
    // name: "Client / Reason"
    name: "Matter ID (Reason)",
    selector: (row) => row.name,
    compact: true,
    sortable: true,
    cell: (row) => (
      <Link to={`/customer/solutions/${row.type.split('-')[1]}/${row.uuid}`}>
        <div className="user-card position-relative">
          <div className="user-info">
            <span className="tb-lead">{capitalizeWords(row.matter_id)} </span>
            {/* <span className="tb-lead">{capitalizeWords(row.name)} </span> */}
            <span>({shortenString(row.matter_reason, 15)})</span>
          </div>
        </div>
      </Link>
    ),
  },
  {
    // name: [
    //   <div key={new Date()} className="">
    //     Client / Reason
    //     {/* Client /<br /> Reason */}
    //   </div>,
    // ],
    // name: "Client / Reason"
    name: "Client (Requestor)",
    selector: (row) => row.client,
    compact: true,
    sortable: true,
    // access: ['Owner'],
    cell: (row) => (
      <Link to={`/customer/solutions/${row.type.split('-')[1]}/${row.uuid}`}>
        <div className="user-card position-relative">
          <div className="user-info">
            <span className="tb-lead">{capitalizeWords(row.client_name)} </span>
            {/* <span className="tb-lead">{capitalizeWords(row.name)} </span> */}
            <span>({row.customer_name})</span>
          </div>
        </div>
      </Link>
    ),
  },
  {
    // name: "Matter ID / Due date",
    // name: ["Matter ID /", <br key={new Date()} />, "Due date"],
    // name: ["Matter ID / Due date"],
    name: "Due date (Start Date)",
    selector: (row) => row.type,
    sortable: true,
    compact: true,
    hide: "sm",
    grow: 3,
    cell: (row) => (
      <Link to={`/customer/solutions/${row.type.split('-')[1]}/${row.uuid}`}>
        <div className="user-card " data-toggle="tooltip">
          <div className="user-info">
            <span className="tb-lead">
              {dateConverter(row.expires_at, true)[0]}, {dateConverter(row.expires_at, true)[1]}
            </span>
            {/* <span className="tb-lead">
            {dateConverter(row.expires_at, true)[0]}, {dateConverter(row.expires_at, true)[1]}
          </span> */}
            <span className="tb-lead">
              ({row.start_date
                ? `${dateConverter(row.start_date, true)[0]}, ${dateConverter(row.start_date, true)[1]}`
                : "Not started"})
            </span>
            {/* <span className="tb-lead">{row.matter_id}</span>
          <span>
            {dateConverter(row.expires_at, true)[0]}, {dateConverter(row.expires_at, true)[1]}
          </span> */}
            {/* <br /> */}
          </div>
        </div>
      </Link>
    ),
  },

  {
    name: "Status",
    selector: (row) => row.status.description,
    sortable: true,
    grow: 3.2,
    hide: "sm",
    cell: (row) => (
      <Link to={`/customer/solutions/${row.type.split('-')[1]}/${row.uuid}`}>
        <div>
          <div>
            <span
              className={`sub-text ${!row.active ? "text-warning" : row.status.description === "COMPLETE" ? "text-success" : ""
                }`}
            >
              <em></em>{" "}
              <b>
                {row.active
                  ? row.status.description === "ESTATE"
                    ? "THIRD-PARTIES"
                    : row.status.description
                  : "CANCELED"}{" "}
                {/* {row.status.description === "COMPLETE" && `(${row.result})`} */}
              </b>
              {row.status.description === "COMPLETE" && (
                <b className={row.result && row.result === "Passed" ? " text-success" : "text-danger"}>
                  ({row.result && row.result.toUpperCase()})
                </b>
              )}
            </span>
          </div>

          <div className="project-list-progress mt-1">
            <Progress
              className="mw-100 progress-pill progress-md bg-light"
              color={!row.active ? "warning" : row.status.description === "COMPLETE" ? "success" : ""}
              value={(Number(row.status.step.split(" of ")[0]) / Number(row.status.step.split(" of ")[1])) * 100}
            />
          </div>
        </div>
      </Link>
    ),
  },
  // {
  //   name: "Report",
  //   selector: (row) => row.type,
  //   sortable: true,
  //   compact: true,
  //   hide: "sm",
  //   cell: (row) => (
  //     <div>
  //       <div className="user-card ">
  //         <div className="user-info">
  //           <span className="tb-lead">Free</span>
  //         </div>
  //       </div>
  //     </div>
  //   ),
  // },

  {
    name: "Date Requested",
    selector: (row) => row.created_at,
    sortable: true,
    compact: true,
    grow: 2.5,
    hide: "md",
    cell: (row) => (
      <Link to={`/customer/solutions/${row.type.split('-')[1]}/${row.uuid}`}>
        <div className="user-card">
          <div className="user-info">
            <span className="tb-lead">
              {dateConverter(row.created_at, true)[0]} <span className={"dot"}></span>
            </span>
            <span>{dateConverter(row.created_at, true)[1]}</span>
          </div>
        </div>
      </Link>
    ),
  },
  // {
  //   name: "Date Completed",
  //   selector: (row) => row.salary,
  //   sortable: true,
  //   compact: true,
  //   hide: "md",
  //   style: { marginLeft: "5px" },
  //   grow: 2.5,
  //  cell: (row) => (
  //     <div className="user-card mt-2 mb-2">
  //     <div className="user-info">
  //         <span className="tb-lead">
  //          {row.date_completed === '-' ? 'Incomplete' : dateConverter(row.date_completed,true)[0]} <span className={"dot"}></span>
  //         </span>
  //         <span>{row.date_completed === '-' ? '' :dateConverter(row.date_completed,true)[1]}</span>
  //       </div>
  //       </div>
  //   ),
  // },
  // {
  //   name: "Requested By",
  //   selector: (row) => row.name,
  //   sortable: true,
  //   hide: "md",
  //   compact: true,
  //   cell: (row) => (
  //     <div className="user-card mt-2 mb-2">
  //       <UserAvatar
  //         theme={row.avatarBg}
  //         text={findFirst(row.name)}
  //         image={row.image}
  //       ></UserAvatar>
  //     </div>
  //   ),
  // },
  // {
  //   cell: (row) => (
  //     <div className=" ps-4 col-6 col-sm-3">
  //       <UncontrolledDropdown>
  //         <DropdownToggle tag="a" className="btn btn-icon btn-trigger">
  //           <Icon name="more-v"></Icon>
  //         </DropdownToggle>
  //         <DropdownMenu>
  //           <ul className="link-list-opt no-bdr ">
  //             <li>
  //               <a
  //                 href="#sendEmail"
  //                 onClick={(ev) => {
  //                   ev.preventDefault();
  //                 }}
  //               >
  //                 <Icon name="check"></Icon>
  //                 <span>Approve</span>
  //               </a>
  //             </li>
  //             <li>
  //               <a
  //                 href="#edit"
  //                 onClick={(ev) => {
  //                   ev.preventDefault();
  //                 }}
  //               >
  //                 <Icon name="cross"></Icon>
  //                 <span>Reject</span>
  //               </a>
  //             </li>
  //             <li>
  //               <a
  //                 href="#edit"
  //                 onClick={(ev) => {
  //                   ev.preventDefault();
  //                 }}
  //               >
  //                 <Icon name="trash"></Icon>
  //                 <span>Cancel</span>
  //               </a>
  //             </li>
  //           </ul>
  //         </DropdownMenu>
  //       </UncontrolledDropdown>
  //     </div>
  //   ),
  // },
];

export const shareListColumns = [
  {
    // name: [
    //   <div key={new Date()} className="">
    //     Client / Reason
    //     {/* Client /<br /> Reason */}
    //   </div>,
    // ],
    // name: "Client / Reason"
    name: "Matter ID / Reason",
    selector: (row) => row.name,
    compact: true,
    sortable: true,
    cell: (row) => (
      <Link to={`/customer/solutions/share/${row.uuid}`}>
        <div className="user-card position-relative">
          <div className="user-info">
            <span className="tb-lead">{capitalizeWords(row.matter_id)} </span>
            {/* <span className="tb-lead">{capitalizeWords(row.name)} </span> */}
            <span>{row.matter_reason}</span>
          </div>
        </div>
      </Link>
    ),
  },
  {
    // name: [
    //   <div key={new Date()} className="">
    //     Client / Reason
    //     {/* Client /<br /> Reason */}
    //   </div>,
    // ],
    // name: "Client / Reason"
    name: "Client / Requestor",
    selector: (row) => row.client,
    compact: true,
    sortable: true,
    // access: ['Owner'],
    cell: (row) => (
      <Link to={`/customer/solutions/verify/${row.uuid}`}>
        <div className="user-card position-relative">
          <div className="user-info">
            <span className="tb-lead">{capitalizeWords(row.client_name)} </span>
            {/* <span className="tb-lead">{capitalizeWords(row.name)} </span> */}
            <span>{row.customer_name}</span>
          </div>
        </div>
      </Link>
    ),
  },
  // {
  //   // name: "Matter ID / Due date",
  //   // name: ["Matter ID /", <br key={new Date()} />, "Due date"],
  //   // name: ["Matter ID / Due date"],
  //   name: ["Due date / Start Date"],
  //   selector: (row) => row.type,
  //   sortable: true,
  //   compact: true,
  //   hide: "sm",
  //   grow: 3,
  //   cell: (row) => (
  //     <div className="user-card " data-toggle="tooltip">
  //       <div className="user-info">
  //         <span className="tb-lead">
  //           {dateConverter(row.expires_at, true)[0]}, {dateConverter(row.expires_at, true)[1]}
  //         </span>
  //         {/* <span className="tb-lead">
  //           {dateConverter(row.expires_at, true)[0]}, {dateConverter(row.expires_at, true)[1]}
  //         </span> */}
  //         <span className="tb-lead">
  //           {row.start_date
  //             ? `${dateConverter(row.start_date, true)[0]}, ${dateConverter(row.start_date, true)[1]}`
  //             : "Not started"}
  //         </span>
  //         {/* <span className="tb-lead">{row.matter_id}</span>
  //         <span>
  //           {dateConverter(row.expires_at, true)[0]}, {dateConverter(row.expires_at, true)[1]}
  //         </span> */}
  //         {/* <br /> */}
  //       </div>
  //     </div>
  //   ),
  // },

  {
    name: "Status",
    selector: (row) => row.status.description,
    sortable: true,
    grow: 3.2,
    hide: "sm",
    cell: (row) => (
      <div>
        <div>
          <span
            className={`sub-text ${!row.active ? "text-warning" : row.status.description === "COMPLETE" ? "text-success" : ""
              }`}
          >
            <em></em>{" "}
            {row.status.description && (
              <b>
                {row.status.description.toUpperCase()}
              </b>
            )}
          </span>
        </div>

        <div className="project-list-progress mt-1">
          <Progress
            className="mw-100 progress-pill progress-md bg-light"
            color={row.status.description === "complete" ? "success" : ""}
            value={(Number(row.status.step.split(" of ")[0]) / Number(row.status.step.split(" of ")[1])) * 100}
          />
        </div>
      </div>
    ),
  },
  // {
  //   name: "Report",
  //   selector: (row) => row.type,
  //   sortable: true,
  //   compact: true,
  //   hide: "sm",
  //   cell: (row) => (
  //     <div>
  //       <div className="user-card ">
  //         <div className="user-info">
  //           <span className="tb-lead">Free</span>
  //         </div>
  //       </div>
  //     </div>
  //   ),
  // },

  {
    name: "Date Requested",
    selector: (row) => row.created_at,
    sortable: true,
    compact: true,
    grow: 2.5,
    hide: "md",
    cell: (row) => (
      <div className="user-card">
        <div className="user-info">
          <span className="tb-lead">
            {dateConverter(row.created_at, true)[0]} <span className={"dot"}></span>
          </span>
          <span>{dateConverter(row.created_at, true)[1]}</span>
        </div>
      </div>
    ),
  },
  // {
  //   name: "Date Completed",
  //   selector: (row) => row.salary,
  //   sortable: true,
  //   compact: true,
  //   hide: "md",
  //   style: { marginLeft: "5px" },
  //   grow: 2.5,
  //  cell: (row) => (
  //     <div className="user-card mt-2 mb-2">
  //     <div className="user-info">
  //         <span className="tb-lead">
  //          {row.date_completed === '-' ? 'Incomplete' : dateConverter(row.date_completed,true)[0]} <span className={"dot"}></span>
  //         </span>
  //         <span>{row.date_completed === '-' ? '' :dateConverter(row.date_completed,true)[1]}</span>
  //       </div>
  //       </div>
  //   ),
  // },
  // {
  //   name: "Requested By",
  //   selector: (row) => row.name,
  //   sortable: true,
  //   hide: "md",
  //   compact: true,
  //   cell: (row) => (
  //     <div className="user-card mt-2 mb-2">
  //       <UserAvatar
  //         theme={row.avatarBg}
  //         text={findFirst(row.name)}
  //         image={row.image}
  //       ></UserAvatar>
  //     </div>
  //   ),
  // },
  // {
  //   cell: (row) => (
  //     <div className=" ps-4 col-6 col-sm-3">
  //       <UncontrolledDropdown>
  //         <DropdownToggle tag="a" className="btn btn-icon btn-trigger">
  //           <Icon name="more-v"></Icon>
  //         </DropdownToggle>
  //         <DropdownMenu>
  //           <ul className="link-list-opt no-bdr ">
  //             <li>
  //               <a
  //                 href="#sendEmail"
  //                 onClick={(ev) => {
  //                   ev.preventDefault();
  //                 }}
  //               >
  //                 <Icon name="check"></Icon>
  //                 <span>Approve</span>
  //               </a>
  //             </li>
  //             <li>
  //               <a
  //                 href="#edit"
  //                 onClick={(ev) => {
  //                   ev.preventDefault();
  //                 }}
  //               >
  //                 <Icon name="cross"></Icon>
  //                 <span>Reject</span>
  //               </a>
  //             </li>
  //             <li>
  //               <a
  //                 href="#edit"
  //                 onClick={(ev) => {
  //                   ev.preventDefault();
  //                 }}
  //               >
  //                 <Icon name="trash"></Icon>
  //                 <span>Cancel</span>
  //               </a>
  //             </li>
  //           </ul>
  //         </DropdownMenu>
  //       </UncontrolledDropdown>
  //     </div>
  //   ),
  // },
];

export const verifyListColumns = [
  {
    // name: [
    //   <div key={new Date()} className="">
    //     Client / Reason
    //     {/* Client /<br /> Reason */}
    //   </div>,
    // ],
    // name: "Client / Reason"
    name: "Matter ID (Reason)",
    selector: (row) => row.name,
    compact: true,
    sortable: true,
    cell: (row) => (
      <Link to={`/customer/solutions/${row.type.split('-')[1]}/${row.uuid}`}>
        <div className="user-card position-relative">
          <div className="user-info">
            <span className="tb-lead">{capitalizeWords(row.matter_id)} </span>
            {/* <span className="tb-lead">{capitalizeWords(row.name)} </span> */}
            (<span data-toggle="tooltip" data-placement="bottom" title={row.matter_reason}>{shortenString(row.matter_reason, 30)}</span>)
          </div>
        </div>
      </Link>
    ),
  },
  {
    // name: [
    //   <div key={new Date()} className="">
    //     Client / Reason
    //     {/* Client /<br /> Reason */}
    //   </div>,
    // ],
    // name: "Client / Reason"
    name: "Client (Requestor)",
    selector: (row) => row,
    compact: true,
    sortable: true,
    // access: ['Owner'],
    cell: (row) => (
      <Link to={`/customer/solutions/${row.type.split('-')[1]}/${row.uuid}`}>
        <div className="user-card position-relative">
          <div className="user-info">
            <span className="tb-lead">{capitalizeWords(row.client_name)} </span>
            {/* <span className="tb-lead">{capitalizeWords(row.name)} </span> */}
            <span>({row.customer_name})</span>
          </div>
        </div>
      </Link>
    ),
  },
  {
    // name: "Matter ID / Due date",
    // name: ["Matter ID /", <br key={new Date()} />, "Due date"],
    // name: ["Matter ID / Due date"],
    name: "Due date (Start Date)",
    selector: (row) => row.type,
    sortable: true,
    compact: true,
    hide: "sm",
    grow: 3,
    cell: (row) => (
      <Link to={`/customer/solutions/${row.type.split('-')[1]}/${row.uuid}`}>
        <div className="user-card " data-toggle="tooltip">
          <div className="user-info">
            <span className="tb-lead">
              {dateConverter(row.expires_at, true)[0]}, {dateConverter(row.expires_at, true)[1]}
            </span>
            {/* <span className="tb-lead">
            {dateConverter(row.expires_at, true)[0]}, {dateConverter(row.expires_at, true)[1]}
          </span> */}
            <span >
              ({row.start_date
                ? `${dateConverter(row.start_date, true)[0]}, ${dateConverter(row.start_date, true)[1]}`
                : "Not started"})
            </span>
            {/* <span className="tb-lead">{row.matter_id}</span>
          <span>
            {dateConverter(row.expires_at, true)[0]}, {dateConverter(row.expires_at, true)[1]}
          </span> */}
            {/* <br /> */}
          </div>
        </div>
      </Link>
    ),
  },

  {
    name: "Status",
    selector: (row) => row.status.description,
    sortable: true,
    grow: 3.2,
    hide: "sm",
    cell: (row) => (
      <Link to={`/customer/solutions/${row.type.split('-')[1]}/${row.uuid}`}>
        <div>
          <div>
            <span
              className={`sub-text ${!row.active ? "text-warning" : row.status.description === "COMPLETE" ? "text-success" : row.status.description === "Requires Review" ? 'text-danger' : ""
                }`}
            >
              <em></em>{" "}
              <b>
                {row.active
                  ? row.status.description === "ESTATE"
                    ? "THIRD-PARTIES"
                    : row.status.description
                  : "CANCELED"}{" "}
                {/* {row.status.description === "COMPLETE" && `(${row.result})`} */}
              </b>
              {row.status.description === "COMPLETE" && (
                <b className={row.result && row.result === "Passed" ? " text-success" : "text-danger"}>
                  ({row.result && row.result.toUpperCase()})
                </b>
              )}
            </span>
          </div>

          <div className="project-list-progress mt-1">
            <Progress
              className="mw-100 progress-pill progress-md bg-light"
              color={!row.active ? "warning" : row.status.description === "COMPLETE" ? "success" : row.status.description === "Requires Review" ? 'danger' : ""}
              value={(Number(row.status.step.split(" of ")[0]) / Number(row.status.step.split(" of ")[1])) * 100}
            />
          </div>
        </div>
      </Link>
    ),
  },
  // {
  //   name: "Report",
  //   selector: (row) => row.type,
  //   sortable: true,
  //   compact: true,
  //   hide: "sm",
  //   cell: (row) => (
  //     <div>
  //       <div className="user-card ">
  //         <div className="user-info">
  //           <span className="tb-lead">Free</span>
  //         </div>
  //       </div>
  //     </div>
  //   ),
  // },

  {
    name: "Date Requested",
    selector: (row) => row.created_at,
    sortable: true,
    compact: true,
    grow: 2.5,
    hide: "md",
    cell: (row) => (
      <Link to={`/customer/solutions/${row.type.split('-')[1]}/${row.uuid}`}>
        <div className="user-card">
          <div className="user-info">
            <span className="tb-lead">
              {dateConverter(row.created_at, true)[0]} <span className={"dot"}></span>
            </span>
            <span>{dateConverter(row.created_at, true)[1]}</span>
          </div>
        </div>
      </Link>
    ),
  },
  // {
  //   name: "Date Completed",
  //   selector: (row) => row.salary,
  //   sortable: true,
  //   compact: true,
  //   hide: "md",
  //   style: { marginLeft: "5px" },
  //   grow: 2.5,
  //  cell: (row) => (
  //     <div className="user-card mt-2 mb-2">
  //     <div className="user-info">
  //         <span className="tb-lead">
  //          {row.date_completed === '-' ? 'Incomplete' : dateConverter(row.date_completed,true)[0]} <span className={"dot"}></span>
  //         </span>
  //         <span>{row.date_completed === '-' ? '' :dateConverter(row.date_completed,true)[1]}</span>
  //       </div>
  //       </div>
  //   ),
  // },
  // {
  //   name: "Requested By",
  //   selector: (row) => row.name,
  //   sortable: true,
  //   hide: "md",
  //   compact: true,
  //   cell: (row) => (
  //     <div className="user-card mt-2 mb-2">
  //       <UserAvatar
  //         theme={row.avatarBg}
  //         text={findFirst(row.name)}
  //         image={row.image}
  //       ></UserAvatar>
  //     </div>
  //   ),
  // },
  // {
  //   cell: (row) => (
  //     <div className=" ps-4 col-6 col-sm-3">
  //       <UncontrolledDropdown>
  //         <DropdownToggle tag="a" className="btn btn-icon btn-trigger">
  //           <Icon name="more-v"></Icon>
  //         </DropdownToggle>
  //         <DropdownMenu>
  //           <ul className="link-list-opt no-bdr ">
  //             <li>
  //               <a
  //                 href="#sendEmail"
  //                 onClick={(ev) => {
  //                   ev.preventDefault();
  //                 }}
  //               >
  //                 <Icon name="check"></Icon>
  //                 <span>Approve</span>
  //               </a>
  //             </li>
  //             <li>
  //               <a
  //                 href="#edit"
  //                 onClick={(ev) => {
  //                   ev.preventDefault();
  //                 }}
  //               >
  //                 <Icon name="cross"></Icon>
  //                 <span>Reject</span>
  //               </a>
  //             </li>
  //             <li>
  //               <a
  //                 href="#edit"
  //                 onClick={(ev) => {
  //                   ev.preventDefault();
  //                 }}
  //               >
  //                 <Icon name="trash"></Icon>
  //                 <span>Cancel</span>
  //               </a>
  //             </li>
  //           </ul>
  //         </DropdownMenu>
  //       </UncontrolledDropdown>
  //     </div>
  //   ),
  // },
];

export const clientVerificationListColumns = [
  {

    name: "Type",
    selector: (row) => row.type,
    compact: true,
    sortable: true,
    cell: (row) => (
      <Link to={`/customer/solutions/${row.type.split('-')[1]}/${row.uuid}`}>
        <div className="user-card position-relative">
          <div className="user-info">
            <span className="tb-lead text-center"><Badge style={{ minWidth: '50px', display: "inline-flex !important", justifyContent: 'center', alignItems: 'center' }} color='light' pill >{row.type === 'ccr-verify' ? 'IDV' : row.type.split('-')[1].toUpperCase()}</Badge></span>

          </div>
        </div>
      </Link>
    ),
  },
  {
    // name: [
    //   <div key={new Date()} className="">
    //     Client / Reason
    //     {/* Client /<br /> Reason */}
    //   </div>,
    // ],
    // name: "Client / Reason"
    name: "Matter ID (Reason)",
    selector: (row) => row.name,
    compact: true,
    sortable: true,
    cell: (row) => (
      <Link to={`/customer/solutions/${row.type.split('-')[1]}/${row.uuid}`}>
        <div className="user-card position-relative">
          <div className="user-info">
            <span className="tb-lead">{capitalizeWords(row.matter_id)}</span>
            {/* <span className="tb-lead">{capitalizeWords(row.name)} </span> */}
            (<span>{shortenString(row.matter_reason, 30)}</span>)
          </div>
        </div>
      </Link>
    ),
  },

  {
    // name: [
    //   <div key={new Date()} className="">
    //     Client / Reason
    //     {/* Client /<br /> Reason */}
    //   </div>,
    // ],
    // name: "Client / Reason"
    name: "Client (Requestor)",
    selector: (row) => row,
    compact: true,
    sortable: true,
    // access: ['Owner'],
    cell: (row) => (
      <Link to={`/customer/solutions/verify/${row.uuid}`}>
        <div className="user-card position-relative">
          <div className="user-info">
            <span className="tb-lead">{capitalizeWords(row.client_name)} </span>
            {/* <span className="tb-lead">{capitalizeWords(row.name)} </span> */}
            <span>({row.customer_name})</span>
          </div>
        </div>
      </Link>
    ),
  },
  {
    // name: "Matter ID / Due date",
    // name: ["Matter ID /", <br key={new Date()} />, "Due date"],
    // name: ["Matter ID / Due date"],
    name: "Due date (Start Date)",
    selector: (row) => row.type,
    sortable: true,
    compact: true,
    hide: "sm",
    grow: 3,
    cell: (row) => (
      <Link to={`/customer/solutions/${row.type.split('-')[1]}/${row.uuid}`}>
        <div className="user-card " data-toggle="tooltip">
          <div className="user-info">
            <span className="tb-lead">
              {dateConverter(row.expires_at, true)[0]}, {dateConverter(row.expires_at, true)[1]}
            </span>
            {/* <span className="tb-lead">
            {dateConverter(row.expires_at, true)[0]}, {dateConverter(row.expires_at, true)[1]}
          </span> */}
            <span className="tb-lead">
              ({row.start_date
                ? `${dateConverter(row.start_date, true)[0]}, ${dateConverter(row.start_date, true)[1]}`
                : "Not started"})
            </span>
            {/* <span className="tb-lead">{row.matter_id}</span>
          <span>
            {dateConverter(row.expires_at, true)[0]}, {dateConverter(row.expires_at, true)[1]}
          </span> */}
            {/* <br /> */}
          </div>
        </div>
      </Link>
    ),
  },

  {
    name: "Status",
    selector: (row) => row.status.description,
    sortable: true,
    grow: 3.2,
    hide: "sm",
    cell: (row) => (
      <Link to={`/customer/solutions/${row.type.split('-')[1]}/${row.uuid}`}>
        <div>
          <div>
            <span
              className={`sub-text ${!row.active ? "text-warning" : row.status.description === "COMPLETE" ? "text-success" : row.status.description === "Requires Review" ? 'text-danger' : ""
                }`}
            >
              <em></em>{" "}
              <b>
                {row.active
                  ? row.status.description === "ESTATE"
                    ? "THIRD-PARTIES"
                    : row.status.description
                  : "CANCELED"}{" "}
                {/* {row.status.description === "COMPLETE" && `(${row.result})`} */}
              </b>
              {row.status.description === "COMPLETE" && (
                <b className={row.result && row.result === "Passed" ? " text-success" : "text-danger"}>
                  ({row.result && row.result.toUpperCase()})
                </b>
              )}
            </span>
          </div>

          <div className="project-list-progress mt-1">
            <Progress
              className="mw-100 progress-pill progress-md bg-light"
              color={!row.active ? "warning" : row.status.description === "COMPLETE" ? "success" : row.status.description === "Requires Review" ? 'danger' : ""}
              value={(Number(row.status.step.split(" of ")[0]) / Number(row.status.step.split(" of ")[1])) * 100}
            />
          </div>
        </div>
      </Link>
    ),
  },
  // {
  //   name: "Report",
  //   selector: (row) => row.type,
  //   sortable: true,
  //   compact: true,
  //   hide: "sm",
  //   cell: (row) => (
  //     <div>
  //       <div className="user-card ">
  //         <div className="user-info">
  //           <span className="tb-lead">Free</span>
  //         </div>
  //       </div>
  //     </div>
  //   ),
  // },

  {
    name: "Date Requested",
    selector: (row) => row.created_at,
    sortable: true,
    compact: true,
    grow: 2.5,
    hide: "md",
    cell: (row) => (
      <Link to={`/customer/solutions/${row.type.split('-')[1]}/${row.uuid}`}>
        <div className="user-card">
          <div className="user-info">
            <span className="tb-lead">
              {dateConverter(row.created_at, true)[0]} <span className={"dot"}></span>
            </span>
            <span>{dateConverter(row.created_at, true)[1]}</span>
          </div>
        </div>
      </Link>
    ),
  },
  // {
  //   name: "Date Completed",
  //   selector: (row) => row.salary,
  //   sortable: true,
  //   compact: true,
  //   hide: "md",
  //   style: { marginLeft: "5px" },
  //   grow: 2.5,
  //  cell: (row) => (
  //     <div className="user-card mt-2 mb-2">
  //     <div className="user-info">
  //         <span className="tb-lead">
  //          {row.date_completed === '-' ? 'Incomplete' : dateConverter(row.date_completed,true)[0]} <span className={"dot"}></span>
  //         </span>
  //         <span>{row.date_completed === '-' ? '' :dateConverter(row.date_completed,true)[1]}</span>
  //       </div>
  //       </div>
  //   ),
  // },
  // {
  //   name: "Requested By",
  //   selector: (row) => row.name,
  //   sortable: true,
  //   hide: "md",
  //   compact: true,
  //   cell: (row) => (
  //     <div className="user-card mt-2 mb-2">
  //       <UserAvatar
  //         theme={row.avatarBg}
  //         text={findFirst(row.name)}
  //         image={row.image}
  //       ></UserAvatar>
  //     </div>
  //   ),
  // },
  // {
  //   cell: (row) => (
  //     <div className=" ps-4 col-6 col-sm-3">
  //       <UncontrolledDropdown>
  //         <DropdownToggle tag="a" className="btn btn-icon btn-trigger">
  //           <Icon name="more-v"></Icon>
  //         </DropdownToggle>
  //         <DropdownMenu>
  //           <ul className="link-list-opt no-bdr ">
  //             <li>
  //               <a
  //                 href="#sendEmail"
  //                 onClick={(ev) => {
  //                   ev.preventDefault();
  //                 }}
  //               >
  //                 <Icon name="check"></Icon>
  //                 <span>Approve</span>
  //               </a>
  //             </li>
  //             <li>
  //               <a
  //                 href="#edit"
  //                 onClick={(ev) => {
  //                   ev.preventDefault();
  //                 }}
  //               >
  //                 <Icon name="cross"></Icon>
  //                 <span>Reject</span>
  //               </a>
  //             </li>
  //             <li>
  //               <a
  //                 href="#edit"
  //                 onClick={(ev) => {
  //                   ev.preventDefault();
  //                 }}
  //               >
  //                 <Icon name="trash"></Icon>
  //                 <span>Cancel</span>
  //               </a>
  //             </li>
  //           </ul>
  //         </DropdownMenu>
  //       </UncontrolledDropdown>
  //     </div>
  //   ),
  // },
];


export const verifyListData = [
  {
    id: "f87d3259-5c86-4f72-a928-58f28c685a8f",
    avatar: "https://static-00.iconduck.com/assets.00/link-square-icon-512x512-tgr53p19.png",
    name: "Roozbeh Karimi",
    email: "roozbeh.karimi@gmail.com",
    company: "Abgoon",
    req_id: "f87d3259-5c86-4f72-a928-58f28c685a8f",
    uid: "100101241",
    status: { status: "Email sent", step: "" },
    type: "estate",
    date_requested: "Aug 10 2023 3:13:35 p.m.",
    date_completed: "-",
    check_avatar: "https://iso.500px.com/wp-content/uploads/2016/03/stock-photo-142984111.jpg",
    check_name: "from_name",
    source: [
      "https://iso.500px.com/wp-content/uploads/2016/03/stock-photo-142984111.jpg",
      "https://iso.500px.com/wp-content/uploads/2016/03/stock-photo-142984111.jpg",
    ],
    matter_id: "23-06-2201-001",
  },
  {
    id: "200bb518-7ff3-4b72-90f5-6297496ee286",
    avatar: "https://static-00.iconduck.com/assets.00/link-square-icon-512x512-tgr53p19.png",
    name: "Roozbeh Karimi",
    email: "roozbeh.karimi@gmail.com",
    company: "Abgoon",
    req_id: "200bb518-7ff3-4b72-90f5-6297496ee286",
    uid: "100101241",
    status: { status: "In Progress", step: "Documents" },
    type: "estate",
    date_requested: "Aug 02 2023 3:56:51 p.m.",
    date_completed: "-",
    check_avatar: "https://iso.500px.com/wp-content/uploads/2016/03/stock-photo-142984111.jpg",
    check_name: "from_name",
    source: [
      "https://iso.500px.com/wp-content/uploads/2016/03/stock-photo-142984111.jpg",
      "https://iso.500px.com/wp-content/uploads/2016/03/stock-photo-142984111.jpg",
    ],
    matter_id: "23-06-2201-002",
  },
  {
    id: "2dac2af5-3753-4143-8660-af29ad690399",
    avatar: "https://static-00.iconduck.com/assets.00/link-square-icon-512x512-tgr53p19.png",
    name: "Jon Geller",
    email: "jgeller@sipstack.com",
    company: "Abgoon",
    req_id: "2dac2af5-3753-4143-8660-af29ad690399",
    uid: "100101241",
    status: { status: "In Progress", step: "person" },
    type: "estate",
    date_requested: "Aug 02 2023 2:40:30 p.m.",
    date_completed: "-",
    check_avatar: "https://iso.500px.com/wp-content/uploads/2016/03/stock-photo-142984111.jpg",
    check_name: "from_name",
    source: [
      "https://iso.500px.com/wp-content/uploads/2016/03/stock-photo-142984111.jpg",
      "https://iso.500px.com/wp-content/uploads/2016/03/stock-photo-142984111.jpg",
    ],
    matter_id: "23-06-2201-003",
  },
  {
    id: "3e9db1b8-24f1-42d9-a9a0-fa5997a143c8",
    avatar: "https://static-00.iconduck.com/assets.00/link-square-icon-512x512-tgr53p19.png",
    name: "test ertert",
    email: "roozbeh.karimi@gmail.com",
    company: "Abgoon",
    req_id: "3e9db1b8-24f1-42d9-a9a0-fa5997a143c8",
    uid: "100101241",
    status: { status: "In Progress", step: "contact" },
    type: "business",
    date_requested: "Aug 02 2023 10:46:55 a.m.",
    date_completed: "-",
    check_avatar: "https://iso.500px.com/wp-content/uploads/2016/03/stock-photo-142984111.jpg",
    check_name: "from_name",
    source: [
      "https://iso.500px.com/wp-content/uploads/2016/03/stock-photo-142984111.jpg",
      "https://iso.500px.com/wp-content/uploads/2016/03/stock-photo-142984111.jpg",
    ],
    matter_id: "23-06-2201-004",
  },
  {
    id: "5e393aa8-cb29-4afe-b91c-134fbda5fc03",
    avatar: "https://static-00.iconduck.com/assets.00/link-square-icon-512x512-tgr53p19.png",
    name: "Roozbeh Karimi",
    email: "roozbeh.karimi@gmail.com",
    company: "Abgoon",
    req_id: "5e393aa8-cb29-4afe-b91c-134fbda5fc03",
    uid: "100101241",
    status: { status: "In Progress", step: "biometric_face" },
    type: "personal",
    date_requested: "Aug 02 2023 10:44:19 a.m.",
    date_completed: "-",
    check_avatar: "https://iso.500px.com/wp-content/uploads/2016/03/stock-photo-142984111.jpg",
    check_name: "from_name",
    source: [
      "https://iso.500px.com/wp-content/uploads/2016/03/stock-photo-142984111.jpg",
      "https://iso.500px.com/wp-content/uploads/2016/03/stock-photo-142984111.jpg",
    ],
    matter_id: "23-06-2201-005",
  },
  {
    id: "f629214d-1a9b-425a-8be3-1146ca586ce0",
    avatar: "https://static-00.iconduck.com/assets.00/link-square-icon-512x512-tgr53p19.png",
    name: "Roozbeh Karimi",
    email: "roozbeh.karimi@gmail.com",
    company: "sipstack",
    req_id: "f629214d-1a9b-425a-8be3-1146ca586ce0",
    uid: "100101241",
    status: { status: "Received", step: "business" },
    type: "estate",
    date_requested: "Aug 01 2023 5:19:30 p.m.",
    date_completed: "-",
    check_avatar: "https://iso.500px.com/wp-content/uploads/2016/03/stock-photo-142984111.jpg",
    check_name: "from_name",
    source: [
      "https://iso.500px.com/wp-content/uploads/2016/03/stock-photo-142984111.jpg",
      "https://iso.500px.com/wp-content/uploads/2016/03/stock-photo-142984111.jpg",
    ],
    matter_id: "23-06-2201-006",
  },
  {
    id: "e95e678d-b0e4-4a13-b04a-1f94109d1cf0",
    avatar: "https://static-00.iconduck.com/assets.00/link-square-icon-512x512-tgr53p19.png",
    name: "reza moradi",
    email: "roozbeh.karimi@gmail.com",
    company: "sipstack",
    req_id: "e95e678d-b0e4-4a13-b04a-1f94109d1cf0",
    uid: "100101241",
    status: { status: "Approved", step: "" },
    type: "estate",
    date_requested: "Aug 01 2023 4:57:04 p.m.",
    date_completed: "-",
    check_avatar: "https://iso.500px.com/wp-content/uploads/2016/03/stock-photo-142984111.jpg",
    check_name: "from_name",
    source: [
      "https://iso.500px.com/wp-content/uploads/2016/03/stock-photo-142984111.jpg",
      "https://iso.500px.com/wp-content/uploads/2016/03/stock-photo-142984111.jpg",
    ],
    matter_id: "23-06-2201-007",
  },
  {
    id: "244b4f6a-78a7-455f-94c0-16c36ac762c4",
    avatar: "https://static-00.iconduck.com/assets.00/link-square-icon-512x512-tgr53p19.png",
    name: "reza moradi",
    email: "roozbeh.karimi+23@gmail.com",
    company: "sipstack",
    req_id: "244b4f6a-78a7-455f-94c0-16c36ac762c4",
    uid: "100101241",
    status: { status: "Rejected", step: "" },
    type: "estate",
    date_requested: "Aug 01 2023 4:54:46 p.m.",
    date_completed: "-",
    check_avatar: "https://iso.500px.com/wp-content/uploads/2016/03/stock-photo-142984111.jpg",
    check_name: "from_name",
    source: [
      "https://iso.500px.com/wp-content/uploads/2016/03/stock-photo-142984111.jpg",
      "https://iso.500px.com/wp-content/uploads/2016/03/stock-photo-142984111.jpg",
    ],
    matter_id: "23-06-2201-008",
  },
];

export const clientListColumns = [
  {
    name: "Client",
    selector: (row) => row.name,
    compact: true,
    grow: 2.2,

    cell: (row) => (
      <Link to={`/customer/menu/client-list/${row.uuid}`}>
        <div className="user-card mt-2 mb-2">
          <UserAvatar theme="" text={findFirst(row.name)} image=""></UserAvatar>
          <div className="user-info">
            <span className="tb-lead">
              {row.name}{" "}
              <span
                className={`dot dot-${row.status === "Active" ? "success" : row.status === "Pending" ? "warning" : "danger"
                  } d-md-none ms-1`}
              ></span>
            </span>
          </div>
        </div>
      </Link>
    ),
    sortable: true,
  },
  {
    name: "Contact",
    selector: (row) => row.name,
    grow: 2.3,
    cell: (row) => (
      <div className="user-card  text-left" style={{ fontSize: "12px" }}>
        <div className="user-info">
          <span className="tb-lead">
            <Icon name="call"> </Icon>
            {row.phone}{" "}
          </span>
          <Icon name="mail" />
          <span>{row.email}</span>
        </div>
      </div>
    ),
    sortable: false,
  },
  {
    name: "Loaction",
    selector: (row) => row.location,
    sortable: false,
    cell: (row) => (
      <div className="nk-tb-col tb-col-md text-center">
        <span style={{ fontSize: "20px" }} className="toggler ml-3 text-primary sub-text">
          <i className="toggler ni ni-map-pin"></i>
        </span>
      </div>
    ),
    hide: "lg",
  },
  {
    name: "Verifications",
    selector: (row) => row.requests,
    sortable: false,
    grow: 1.2,
    compact: true,
    style: { paddingRight: "60px" },
    hide: "md",
    cell: (row) => (
      <div className="icon-text ">
        <div className="d-flex flex-column">
          <div
            style={{ margin: "3px", display: "inline-block" }}
            data-toggle="tooltip"
            title="ID: <%-id_status%>"
            className=" badge badge-dim text-success "
          >
            <div className="sub-text bg-outline-success">
              <span className="align-middle">ID</span>
            </div>
          </div>
          <div
            style={{ margin: "3px", display: "inline-block", width: "63px" }}
            data-toggle="tooltip"
            title="Face: <%-face_status%>"
            className=" badge badge-dim bg-outline-warning"
          >
            <div className="sub-text text-warning">
              <span className="align-middle">Face</span>
            </div>
          </div>
        </div>
        <div className="d-flex flex-column">
          <div
            style={{ margin: "3px", display: "inline-block", width: "63px" }}
            data-toggle="tooltip"
            title="Voice: <%-voice_status%>"
            className="badge badge-dim  bg-outline-warning"
          >
            <div className="sub-text text-warning">
              <span className="align-middle">Voice</span>
            </div>
          </div>
          <div
            style={{ margin: "3px", display: "inline-block", width: "63px" }}
            className="badge badge-dim bg-outline-danger"
          >
            <div data-toggle="tooltip" title="Address: <%-address_status%>" className="sub-text  text-danger">
              <span className="align-middle">Address</span>
            </div>
          </div>
        </div>
      </div>
    ),
  },

  {
    name: "Last Verified",
    selector: (row) => row.lastLogin,
    style: { padding: "10px" },
    grow: 1.2,
    sortable: true,
    cell: (row) => <span>{row.lastLogin}</span>,
    hide: "lg",
  },
  {
    name: "Documents",
    selector: (row) => row.verified,
    sortable: false,
    grow: 1.2,
    compact: true,
    style: { padding: "5px 20px 5px 10px" },
    hide: "md",
    cell: (row) => (
      <>
        <div className="icon-text">
          <div className="d-flex flex-column">
            <div style={{ display: "inline-block", marginBottom: "2px" }} className="badge badge-dim bg-outline-danger">
              <div data-toggle="tooltip" title="Address: <%-address_status%>" className="sub-text  text-danger">
                <span className="align-middle">Passport</span>
              </div>
            </div>
            <div style={{ display: "inline-block", marginBottom: "2px" }} className="badge badge-dim bg-outline-danger">
              <div data-toggle="tooltip" title="Address: <%-address_status%>" className="sub-text  text-danger">
                <span className="align-middle">Drivers Licence</span>
              </div>
            </div>
            <div style={{ display: "inline-block" }} className="badge badge-dim bg-outline-danger">
              <div data-toggle="tooltip" title="Address:" className="sub-text  text-danger">
                <span className="align-middle">Photo ID</span>
              </div>
            </div>
          </div>
        </div>
      </>
    ),
  },
  {
    name: "Owner",
    selector: (row) => row.lastLogin,
    sortable: false,
    cell: (row) => (
      <div className="row">
        <div style={{ paddingRight: "30px" }} className="col-6 col-sm-3">
          <UserAvatar theme={row.avatarBg} text={findFirst(row.name)} image={row.image}></UserAvatar>
        </div>
        <div style={{ paddingRight: "35px" }} className="col-6 col-sm-3">
          <UncontrolledDropdown className="mr-2">
            <DropdownToggle tag="a" className="btn btn-icon btn-trigger">
              <Icon name="more-v"></Icon>
            </DropdownToggle>
            <DropdownMenu>
              <ul className="link-list-opt no-bdr ">
                <li onClick={() => (window.location = `mailto:${row.email}`)}>
                  <a
                    href="#sendEmail"
                    onClick={(ev) => {
                      ev.preventDefault();
                    }}
                  >
                    <Icon name="mail"></Icon>
                    <span>Send Email</span>
                  </a>
                </li>
                <li onClick={() => (window.location = `tel:${row.to_phone}`)}>
                  <a
                    href="#edit"
                    onClick={(ev) => {
                      ev.preventDefault();
                    }}
                  >
                    <Icon name="call"></Icon>
                    <span>Call</span>
                  </a>
                </li>
                {/* <li>
              <a
                href="#markasdone"
                onClick={(ev) => {
                  ev.preventDefault();
                }}
              >
                <Icon name="check"></Icon>
                <span>Verify</span>
              </a>
            </li> */}
                <li>
                  <a
                    href="#markasdone"
                    onClick={(ev) => {
                      ev.preventDefault();
                    }}
                  >
                    <Icon name="done"></Icon>
                    <span>Re-Verify</span>
                  </a>
                </li>
              </ul>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      </div>
    ),
    hide: "lg",
  },
];

export const clientListColumns2 = [
  {
    name: "Client",
    selector: (row) => row.name,
    compact: true,
    grow: 3,
    style: { paddingRight: "20px" },
    cell: (row) => (
      <div className="user-card mt-2 mb-2">
        <UserAvatar theme={row.avatarBg} text={findUpper(row.name)} image={row.image}></UserAvatar>
        <div className="user-info">
          <span className="tb-lead">
            {row.name}{" "}
            <span
              className={`dot dot-${row.status === "Active" ? "success" : row.status === "Pending" ? "warning" : "danger"
                } d-md-none ms-1`}
            ></span>
          </span>
          <span>
            <div></div>
            <div></div>
            <Icon name="mail"></Icon>
            {` ${row.email}`} <br /> <Icon name="call"></Icon>
            {` ${row.phone}`}{" "}
          </span>
          <br />
        </div>
      </div>
    ),
    sortable: true,
  },

  {
    name: "Verifications",
    selector: (row) => row.verified,
    grow: 3,
    hide: "lg",
    cell: (row) => (
      <div>
        <ul className="list-status d-flex mb-1">
          <li>
            <div
              style={{
                fontSize: "13px",
                margin: "0px",
                display: "inline-block",
                width: "80px",
              }}
              data-toggle="tooltip"
              title={
                row.ids[0].status === "not provided"
                  ? "Document 1: Not Collected"
                  : `${row.ids[0].type}: ${row.ids[0].status}`
              }
              className={`badge bg-${row.ids[0].status === "approved"
                ? "success"
                : row.ids[0].status === "progress"
                  ? "warning"
                  : row.ids[0].status === "rejected" || row.ids[0].status === "expired"
                    ? "danger"
                    : "light"
                }`}
            >
              {row.ids.length < 1 ? <div>Document 1</div> : <div>{row.ids[0].type}</div>}
            </div>
          </li>

          <li>
            <div
              style={{
                fontSize: "13px",
                margin: "0px",
                display: "inline-block",
                width: "75px",
              }}
              data-toggle="tooltip"
              title={row.voice === "not provided" ? "Voice: Not Collected" : `Voice: ${row.voice}`}
              className={`badge bg-${row.voice === "approved"
                ? "success"
                : row.voice === "progress"
                  ? "warning"
                  : row.voice === "rejected" || row.voice === "expired"
                    ? "danger"
                    : "light"
                }`}
            >
              Voice
            </div>
          </li>
          <li>
            <div
              style={{
                fontSize: "13px",
                margin: "0px",
                display: "inline-block",
                width: "75px",
              }}
              data-toggle="tooltip"
              title={row.address === "not provided" ? "Voice: Not Collected" : `Voice: ${row.address}`}
              className={`badge bg-${row.address === "approved"
                ? "success"
                : row.address === "progress"
                  ? "warning"
                  : row.address === "rejected" || row.address === "expired"
                    ? "danger"
                    : "light"
                }`}
            >
              Address
            </div>
          </li>
        </ul>
        <ul className="list-status d-flex">
          <li>
            <div
              style={{
                fontSize: "13px",
                margin: "0px",
                display: "inline-block",
                width: "80px",
              }}
              data-toggle="tooltip"
              title={
                row.ids[1].status === "not provided"
                  ? "Document 1: Not Collected"
                  : `${row.ids[1].type}: ${row.ids[1].status}`
              }
              className={`badge bg-${row.ids[1].status === "approved"
                ? "success"
                : row.ids[1].status === "progress"
                  ? "warning"
                  : row.ids[1].status === "rejected" || row.ids[1].status === "expired"
                    ? "danger"
                    : "light"
                }`}
            >
              {row.ids.length < 2 ? <div>Document 2</div> : <div>{row.ids[1].type}</div>}
            </div>
          </li>
          <li>
            <div
              style={{
                fontSize: "13px",
                margin: "0px",
                display: "inline-block",
                width: "75px",
              }}
              data-toggle="tooltip"
              title={row.face === "not provided" ? "Voice: Not Collected" : `Voice: ${row.face}`}
              className={`badge bg-${row.face === "approved"
                ? "success"
                : row.face === "progress"
                  ? "warning"
                  : row.face === "rejected" || row.face === "expired"
                    ? "danger"
                    : "light"
                }`}
            >
              Face
            </div>
          </li>
        </ul>
      </div>
    ),
  },
  {
    name: "Last Verified",
    selector: (row) => row.lastLogin,
    grow: 2.5,
    cell: (row) => (
      <div>
        <div className="mb-2 ">
          <span>{row.date_completed}</span>
        </div>
        <div
          className={`lead text-${row.validation_status === "Valid"
            ? "success"
            : row.validation_status === "expire soon"
              ? "warning"
              : row.validation_status === "verification"
                ? "secondary"
                : "danger"
            }`}
        >
          <Icon
            name={
              row.validation_status === "Valid"
                ? "check-circle-cut"
                : row.validation_status === "expire soon"
                  ? "alert"
                  : row.validation_status === "verification"
                    ? "clock"
                    : "cross-circle"
            }
          ></Icon>
          <span>{row.validation_status}</span>
        </div>
      </div>
    ),
  },
  {
    name: "",
    grow: 0.2,
    selector: (row) => row.name,
    compact: true,
    cell: (row) => (
      <div className="row">
        {/* <div className="user-card mt-2 mb-2 col-6">
          <UserAvatar
            theme={row.avatarBg}
            text={findUpper(row.name)}
            image={row.image}
          ></UserAvatar>
        </div> */}
        <div className="col-6 ">
          <UncontrolledDropdown className="mr-2">
            <DropdownToggle tag="a" className="btn btn-icon btn-trigger">
              <Icon name="more-v"></Icon>
            </DropdownToggle>
            <DropdownMenu>
              <ul className="link-list-opt no-bdr ">
                <li onClick={() => (window.location = `mailto:${row.email}`)}>
                  <a
                    href="#sendEmail"
                    onClick={(ev) => {
                      ev.preventDefault();
                    }}
                  >
                    <Icon name="mail"></Icon>
                    <span>Send Email</span>
                  </a>
                </li>
                <li onClick={() => (window.location = `tel:${row.to_phone}`)}>
                  <a
                    href="#edit"
                    onClick={(ev) => {
                      ev.preventDefault();
                    }}
                  >
                    <Icon name="call"></Icon>
                    <span>Call</span>
                  </a>
                </li>
                <li>
                  <a
                    href="#markasdone"
                    onClick={(ev) => {
                      ev.preventDefault();
                    }}
                  >
                    <Icon name="done"></Icon>
                    <span>Re-Verify</span>
                  </a>
                </li>
              </ul>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      </div>
    ),
    sortable: true,
  },
];
export const clientListColumns3 = [
  {
    name: "Client",
    selector: (row) => row.name,
    compact: true,
    grow: 3,
    style: { paddingRight: "20px" },
    cell: (row) => (
      <div className="user-card mt-2 mb-2">
        <UserAvatar theme={row.avatarBg} text={findUpper(row.name)} image={row.image}></UserAvatar>
        <div className="user-info">
          <span className="tb-lead">
            {row.name}{" "}
            <span
              className={`dot dot-${row.status === "Active" ? "success" : row.status === "Pending" ? "warning" : "danger"
                } d-md-none ms-1`}
            ></span>
          </span>
          <span>
            <div></div>
            <div></div>
            <Icon name="mail"></Icon>
            {` ${row.email}`} <br /> <Icon name="call"></Icon>
            {` ${row.to_phone}`}{" "}
          </span>
          <br />
        </div>
      </div>
    ),
    sortable: true,
  },

  {
    name: "Verifications",
    selector: (row) => row.verified,
    grow: 3.2,
    hide: "lg",
    cell: (row) => (
      <div>
        <ul className="list-status d-flex mb-1">
          <li>
            <div
              style={{
                fontSize: "15px",
                margin: "0px",
                display: "inline-block",
                width: "100px",
              }}
              data-toggle="tooltip"
              title={
                row.ids[0].status === "not provided"
                  ? "Document 1: Not Collected"
                  : `${row.ids[0].type}: ${row.ids[0].status}`
              }
              className={`text-${row.ids[0].status === "approved"
                ? "success"
                : row.ids[0].status === "progress"
                  ? "warning"
                  : row.ids[0].status === "rejected" || row.ids[0].status === "expired"
                    ? "danger"
                    : "light"
                }`}
            >
              <div className="d-flex mb-1">
                <Icon
                  name={
                    row.ids[0].status === "approved"
                      ? "check-circle"
                      : row.ids[0].status === "progress"
                        ? "clock"
                        : row.ids[0].status === "rejected" || row.ids[0].status === "expired"
                          ? "cross-circle"
                          : "light"
                  }
                />
                {row.ids.length < 1 ? <div>Document 1</div> : <div>{row.ids[0].type}</div>}
              </div>
            </div>
          </li>

          <li>
            <div
              style={{
                fontSize: "15px",
                margin: "0px",
                display: "inline-block",
                width: "60px",
              }}
              data-toggle="tooltip"
              title={row.voice === "not provided" ? "Voice: Not Collected" : `Voice: ${row.voice}`}
              className={`text-${row.voice === "approved"
                ? "success"
                : row.voice === "progress"
                  ? "warning"
                  : row.voice === "rejected" || row.voice === "expired"
                    ? "danger"
                    : "light"
                }`}
            >
              <div className="d-flex mb-1">
                <Icon
                  name={
                    row.voice === "approved"
                      ? "check-circle"
                      : row.ids[0].status === "progress"
                        ? "clock"
                        : row.ids[0].status === "rejected" || row.ids[0].status === "expired"
                          ? "cross-circle"
                          : "light"
                  }
                />
                Voice
              </div>
            </div>
          </li>
          <li>
            <div
              style={{
                fontSize: "15px",
                margin: "0px",
                display: "inline-block",
                width: "82px",
              }}
              data-toggle="tooltip"
              title={row.address === "not provided" ? "Address: Not Collected" : `Voice: ${row.address}`}
              className={`text-${row.address === "approved"
                ? "success"
                : row.address === "progress"
                  ? "warning"
                  : row.address === "rejected" || row.address === "expired"
                    ? "danger"
                    : "light"
                }`}
            >
              <div className="d-flex mb-1">
                <Icon
                  name={
                    row.address === "approved"
                      ? "check-circle"
                      : row.address === "progress"
                        ? "clock"
                        : row.address === "rejected" || row.address === "expired"
                          ? "cross-circle"
                          : "light"
                  }
                />
                Address
              </div>
            </div>
          </li>
        </ul>
        <ul className="list-status d-flex">
          <li>
            <div
              style={{
                fontSize: "15px",
                margin: "0px",
                display: "inline-block",
                width: "100px",
              }}
              data-toggle="tooltip"
              title={
                row.ids[1].status === "not provided"
                  ? "Document 1: Not Collected"
                  : `${row.ids[1].type}: ${row.ids[1].status}`
              }
              className={`text-${row.ids[1].status === "approved"
                ? "success"
                : row.ids[1].status === "progress"
                  ? "warning"
                  : row.ids[1].status === "rejected" || row.ids[1].status === "expired"
                    ? "danger"
                    : "light"
                }`}
            >
              <div className="d-flex mb-1">
                <Icon
                  name={
                    row.ids[1].status === "approved"
                      ? "check-circle"
                      : row.ids[1].status === "progress"
                        ? "clock"
                        : row.ids[1].status === "rejected" || row.ids[1].status === "expired"
                          ? "cross-circle"
                          : "light"
                  }
                />
                {row.ids.length < 2 ? <div>Document 2</div> : <div>{row.ids[1].type}</div>}
              </div>
            </div>
          </li>
          <li>
            <div
              style={{
                fontSize: "15px",
                margin: "0px",
                display: "inline-block",
                width: "80px",
              }}
              data-toggle="tooltip"
              title={row.face === "not provided" ? "Face: Not Collected" : `Voice: ${row.face}`}
              className={`text-${row.face === "approved"
                ? "success"
                : row.face === "progress"
                  ? "warning"
                  : row.face === "rejected" || row.face === "expired"
                    ? "danger"
                    : "light"
                }`}
            >
              <div className="d-flex mb-1">
                <Icon
                  name={
                    row.face === "approved"
                      ? "check-circle"
                      : row.face === "progress"
                        ? "clock"
                        : row.face === "rejected" || row.face === "expired"
                          ? "cross-circle"
                          : "light"
                  }
                />
                Face
              </div>
            </div>
          </li>
        </ul>
      </div>
    ),
  },
  {
    name: "Last Verified",
    selector: (row) => row.lastLogin,
    grow: 2.5,
    cell: (row) => (
      <div>
        <div className="mb-2 ">
          <span>{row.date_completed}</span>
        </div>
        <div
          className={`lead text-${row.validation_status === "Valid"
            ? "success"
            : row.validation_status === "expire soon"
              ? "warning"
              : row.validation_status === "verification"
                ? "secondary"
                : "danger"
            }`}
        >
          <Icon
            name={
              row.validation_status === "Valid"
                ? "check-circle-cut"
                : row.validation_status === "expire soon"
                  ? "alert"
                  : row.validation_status === "verification"
                    ? "clock"
                    : "cross-circle"
            }
          ></Icon>
          <span>{row.validation_status}</span>
        </div>
      </div>
    ),
  },
  {
    name: "",
    grow: 0.2,
    selector: (row) => row.name,
    compact: true,
    cell: (row) => (
      <div className="row">
        {/* <div className="user-card mt-2 mb-2 col-6">
          <UserAvatar
            theme={row.avatarBg}
            text={findUpper(row.name)}
            image={row.image}
          ></UserAvatar>
        </div> */}
        <div className="col-6 ">
          <UncontrolledDropdown className="mr-2">
            <DropdownToggle tag="a" className="btn btn-icon btn-trigger">
              <Icon name="more-v"></Icon>
            </DropdownToggle>
            <DropdownMenu>
              <ul className="link-list-opt no-bdr ">
                <li onClick={() => (window.location = `mailto:${row.email}`)}>
                  <a
                    href="#sendEmail"
                    onClick={(ev) => {
                      ev.preventDefault();
                    }}
                  >
                    <Icon name="mail"></Icon>
                    <span>Send Email</span>
                  </a>
                </li>
                <li onClick={() => (window.location = `tel:${row.to_phone}`)}>
                  <a
                    href="#edit"
                    onClick={(ev) => {
                      ev.preventDefault();
                    }}
                  >
                    <Icon name="call"></Icon>
                    <span>Call</span>
                  </a>
                </li>
                <li>
                  <a
                    href="#markasdone"
                    onClick={(ev) => {
                      ev.preventDefault();
                    }}
                  >
                    <Icon name="done"></Icon>
                    <span>Re-Verify</span>
                  </a>
                </li>
              </ul>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      </div>
    ),
    sortable: true,
  },
];
export const clientListColumns4 = [
  {
    name: "Client",
    selector: (row) => row.name,
    compact: true,
    grow: 3,
    style: { paddingRight: "20px" },
    cell: (row) => (
      <div className="user-card mt-2 mb-2">
        <UserAvatar theme={row.avatarBg} text={findUpper(row.name)} image={row.image}></UserAvatar>
        <div className="user-info">
          <span className="tb-lead">
            {row.name}{" "}
            <span
              className={`dot dot-${row.status === "Active" ? "success" : row.status === "Pending" ? "warning" : "danger"
                } d-md-none ms-1`}
            ></span>
          </span>
          <span>
            <div></div>
            <div></div>
            <Icon name="mail"></Icon>
            {` ${row.email}`} <br /> <Icon name="call"></Icon>
            {` ${row.to_phone}`}{" "}
          </span>
          <br />
        </div>
      </div>
    ),
    sortable: true,
  },

  {
    name: "Verifications",
    selector: (row) => row.verified,
    grow: 3.2,
    hide: "lg",
    cell: (row) => (
      <div>
        <ul className="list-status d-flex mb-1">
          <li>
            <div
              style={{
                fontSize: "15px",
                margin: "0px",
                display: "inline-block",
                width: "100px",
              }}
              data-toggle="tooltip"
              title={
                row.ids[0].status === "not provided"
                  ? "Document 1: Not Collected"
                  : `${row.ids[0].type}: ${row.ids[0].status}`
              }
              className={`text-${row.ids[0].status === "approved"
                ? "success"
                : row.ids[0].status === "progress"
                  ? "warning"
                  : row.ids[0].status === "rejected" || row.ids[0].status === "expired"
                    ? "danger"
                    : "light"
                }`}
            >
              <div className="d-flex mb-1">
                <Icon
                  name={
                    row.ids[0].status === "approved"
                      ? "check-circle"
                      : row.ids[0].status === "progress"
                        ? "clock"
                        : row.ids[0].status === "rejected" || row.ids[0].status === "expired"
                          ? "cross-circle"
                          : "light"
                  }
                />
                {row.ids.length < 1 ? <div>Document 1</div> : <div>{row.ids[0].type}</div>}
              </div>
            </div>
          </li>

          <li>
            <div
              style={{
                fontSize: "15px",
                margin: "0px",
                display: "inline-block",
                width: "60px",
              }}
              data-toggle="tooltip"
              title={row.voice === "not provided" ? "Voice: Not Collected" : `Voice: ${row.voice}`}
              className={`text-${row.voice === "approved"
                ? "success"
                : row.voice === "progress"
                  ? "warning"
                  : row.voice === "rejected" || row.voice === "expired"
                    ? "danger"
                    : "light"
                }`}
            >
              <div className="d-flex mb-1">
                <Icon
                  name={
                    row.voice === "approved"
                      ? "check-circle"
                      : row.ids[0].status === "progress"
                        ? "clock"
                        : row.ids[0].status === "rejected" || row.ids[0].status === "expired"
                          ? "cross-circle"
                          : "light"
                  }
                />
                Voice
              </div>
            </div>
          </li>
          <li>
            <div
              style={{
                fontSize: "15px",
                margin: "0px",
                display: "inline-block",
                width: "82px",
              }}
              data-toggle="tooltip"
              title={row.address === "not provided" ? "Address: Not Collected" : `Voice: ${row.address}`}
              className={`text-${row.address === "approved"
                ? "success"
                : row.address === "progress"
                  ? "warning"
                  : row.address === "rejected" || row.address === "expired"
                    ? "danger"
                    : "light"
                }`}
            >
              <div className="d-flex mb-1">
                <Icon
                  name={
                    row.address === "approved"
                      ? "check-circle"
                      : row.address === "progress"
                        ? "clock"
                        : row.address === "rejected" || row.address === "expired"
                          ? "cross-circle"
                          : "light"
                  }
                />
                Address
              </div>
            </div>
          </li>
        </ul>
        <ul className="list-status d-flex">
          <li>
            <div
              style={{
                fontSize: "15px",
                margin: "0px",
                display: "inline-block",
                width: "100px",
              }}
              data-toggle="tooltip"
              title={
                row.ids[1].status === "not provided"
                  ? "Document 1: Not Collected"
                  : `${row.ids[1].type}: ${row.ids[1].status}`
              }
              className={`text-${row.ids[1].status === "approved"
                ? "success"
                : row.ids[1].status === "progress"
                  ? "warning"
                  : row.ids[1].status === "rejected" || row.ids[1].status === "expired"
                    ? "danger"
                    : "light"
                }`}
            >
              <div className="d-flex mb-1">
                <Icon
                  name={
                    row.ids[1].status === "approved"
                      ? "check-circle"
                      : row.ids[1].status === "progress"
                        ? "clock"
                        : row.ids[1].status === "rejected" || row.ids[1].status === "expired"
                          ? "cross-circle"
                          : "light"
                  }
                />
                {row.ids.length < 2 ? <div>Document 2</div> : <div>{row.ids[1].type}</div>}
              </div>
            </div>
          </li>
          <li>
            <div
              style={{
                fontSize: "15px",
                margin: "0px",
                display: "inline-block",
                width: "80px",
              }}
              data-toggle="tooltip"
              title={row.face === "not provided" ? "Face: Not Collected" : `Voice: ${row.face}`}
              className={`text-${row.face === "approved"
                ? "success"
                : row.face === "progress"
                  ? "warning"
                  : row.face === "rejected" || row.face === "expired"
                    ? "danger"
                    : "light"
                }`}
            >
              <div className="d-flex mb-1">
                <Icon
                  name={
                    row.face === "approved"
                      ? "check-circle"
                      : row.face === "progress"
                        ? "clock"
                        : row.face === "rejected" || row.face === "expired"
                          ? "cross-circle"
                          : "light"
                  }
                />
                Face
              </div>
            </div>
          </li>
        </ul>
      </div>
    ),
  },
  {
    name: "Status",
    selector: (row) => row.lastLogin,
    grow: 1.5,
    cell: (row) => (
      <div className="mt-2 mb-1">
        <div
          className={`lead badge badge-dim text-${row.validation_status === "Valid"
            ? "success"
            : row.validation_status === "expire soon"
              ? "warning"
              : row.validation_status === "verification"
                ? "secondary"
                : "danger"
            }`}
        >
          <Icon
            name={
              row.validation_status === "Valid"
                ? "check-circle-cut"
                : row.validation_status === "expire soon"
                  ? "alert"
                  : row.validation_status === "verification"
                    ? "clock"
                    : "cross-circle"
            }
          ></Icon>
          <span>{row.validation_status}</span>
        </div>
        <div className="mt-2 ">
          <span>
            Last verified: <br />
            {row.date_completed}
          </span>
        </div>
      </div>
    ),
  },

  {
    name: "",
    grow: 0.2,
    selector: (row) => row.name,
    compact: true,
    cell: (row) => (
      <div className="row">
        {/* <div className="user-card mt-2 mb-2 col-6">
          <UserAvatar
            theme={row.avatarBg}
            text={findUpper(row.name)}
            image={row.image}
          ></UserAvatar>
        </div> */}
        <div className="col-6 ">
          <UncontrolledDropdown className="mr-2">
            <DropdownToggle tag="a" className="btn btn-icon btn-trigger">
              <Icon name="more-v"></Icon>
            </DropdownToggle>
            <DropdownMenu>
              <ul className="link-list-opt no-bdr ">
                <li onClick={() => (window.location = `mailto:${row.email}`)}>
                  <a
                    href="#sendEmail"
                    onClick={(ev) => {
                      ev.preventDefault();
                    }}
                  >
                    <Icon name="mail"></Icon>
                    <span>Send Email</span>
                  </a>
                </li>
                <li onClick={() => (window.location = `tel:${row.to_phone}`)}>
                  <a
                    href="#edit"
                    onClick={(ev) => {
                      ev.preventDefault();
                    }}
                  >
                    <Icon name="call"></Icon>
                    <span>Call</span>
                  </a>
                </li>
                <li>
                  <a
                    href="#markasdone"
                    onClick={(ev) => {
                      ev.preventDefault();
                    }}
                  >
                    <Icon name="done"></Icon>
                    <span>Re-Verify</span>
                  </a>
                </li>
              </ul>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      </div>
    ),
    sortable: true,
  },
];

export const clientListColumns5 = [
  {
    name: "Client",
    selector: (row) => row.name,
    compact: true,
    grow: 3,
    style: { paddingRight: "20px" },
    cell: (row) => (
      <div className="user-card mt-2 mb-2 " data-toggle="tooltip" title={row.validation_status}>
        <UserAvatar theme={row.avatarBg} text={findUpper(row.name)} image={row.image}>
          <div
            className={`status dot dot-xl dot-${row.validation_status === "Valid"
              ? "success"
              : row.validation_status === "expire soon"
                ? "warning"
                : row.validation_status === "verification"
                  ? "secondary"
                  : "danger"
              }`}
          ></div>
        </UserAvatar>
        <div className="user-info">
          <span className="tb-lead">
            {row.name}{" "}
            <span
              className={`dot dot-${row.status === "Active" ? "success" : row.status === "Pending" ? "warning" : "danger"
                } d-md-none ms-1`}
            ></span>
          </span>
          <span>
            <div></div>
            <div></div>
            <Icon name="mail"></Icon>
            {` ${row.email}`}
            {/* <br />  */}
            {/* <Icon name="call"></Icon>{` ${row.to_phone}`}{" "} */}
          </span>
          <br />
        </div>
      </div>
    ),
    sortable: true,
  },

  {
    name: "Verified",
    selector: (row) => row.verified,
    grow: 3.2,
    hide: "lg",
    cell: (row) => (
      <div>
        <ul className="list-status d-flex">
          {row.documents.driver_license && (
            <li data-toggle="tooltip" title={`Driver's Licence : ${row.documents.driver_license}`}>
              <FaIdCard
                size={20}
                className={`text-${row.documents.driver_license === "approved"
                  ? "success"
                  : row.documents.driver_license === "expire soon"
                    ? "warning"
                    : row.documents.driver_license === "verification"
                      ? "secondary"
                      : row.documents.driver_license === " rejected" || "expired"
                        ? "danger"
                        : ""
                  }`}
              />
            </li>
          )}
          {row.documents.photo_id && (
            <li data-toggle="tooltip" title={`Photo ID : ${row.documents.photo_id}`}>
              <FaIdCard
                size={20}
                className={`text-${row.documents.photo_id === "approved"
                  ? "success"
                  : row.documents.photo_id === "expire soon"
                    ? "warning"
                    : row.documents.photo_id === "verification"
                      ? "secondary"
                      : row.documents.photo_id === " rejected" || "expired"
                        ? "danger"
                        : ""
                  }`}
              />
            </li>
          )}
          {row.documents.passport && (
            <li data-toggle="tooltip" title={`Passport : ${row.documents.passport}`}>
              <FaPassport
                size={20}
                className={`text-${row.documents.passport === "approved"
                  ? "success"
                  : row.documents.passport === "expire soon"
                    ? "warning"
                    : row.documents.passport === "verification"
                      ? "secondary"
                      : row.documents.passport === " rejected" || "expired"
                        ? "danger"
                        : ""
                  }`}
              />
            </li>
          )}
          {row.documents.address && (
            <li data-toggle="tooltip" title={`Address : ${row.documents.address}`}>
              <FaLocationDot
                size={20}
                className={`text-${row.documents.address === "approved"
                  ? "success"
                  : row.documents.address === "expire soon"
                    ? "warning"
                    : row.documents.address === "verification"
                      ? "secondary"
                      : row.documents.address === " rejected" || "expired"
                        ? "danger"
                        : ""
                  }`}
              />
            </li>
          )}
          {row.documents.voice && (
            <li data-toggle="tooltip" title={`Voice : ${row.documents.voice}`}>
              <FaMicrophone
                className={`text-${row.documents.voice === "approved"
                  ? "success"
                  : row.documents.voice === "expire soon"
                    ? "warning"
                    : row.documents.voice === "verification"
                      ? "secondary"
                      : row.documents.voice === " rejected" || "expired"
                        ? "danger"
                        : ""
                  }`}
              />
            </li>
          )}
          {row.documents.face && (
            <li data-toggle="tooltip" title={`Face : ${row.documents.face}`}>
              <BsPersonBadge
                size={20}
                className={`text-${row.documents.face === "approved"
                  ? "success"
                  : row.documents.face === "expire soon"
                    ? "warning"
                    : row.documents.face === "verification"
                      ? "secondary"
                      : row.documents.face === " rejected" || "expired"
                        ? "danger"
                        : ""
                  }`}
              />
            </li>
          )}

          {/* {row.ids.length > 0 && (<li>
            <div
              style={{
                fontSize: "13px",
                margin: "0px",
                display: "inline-block",
                width: "80px",
              }}
              data-toggle="tooltip"
              title={row.ids[0].status === "not provided" ? "Document 1: Not Collected" : `${row.ids[0].type}: ${row.ids[0].status}`}
              className={`text-${row.ids[0].status === 'approved' ? 'success' : row.ids[0].status === 'progress' ? 'warning' : row.ids[0].status === 'rejected' || row.ids[0].status === 'expired' ? 'danger' : 'light' }`}
            >
              <div className="d-flex">
                <Icon name={row.ids[0].status === 'approved' ? 'check-circle' : row.ids[0].status === 'progress' ? 'clock' : row.ids[0].status === 'rejected' || row.ids[0].status === 'expired' ? 'cross-circle' : 'light' }/>
                {row.ids.length < 1 ? (<div>Document 1</div>):(<div>{row.ids[0].type}</div>)}
              </div>
            </div>
          </li>)} */}

          {/* {row.ids.length > 1 && (<li>
            <div
              style={{
                fontSize: "13px",
                margin: "0px",
                display: "inline-block",
                width: "80px",
              }}
              data-toggle="tooltip"
              title={row.voice === "not provided" ? "Voice: Not Collected" : `Voice: ${row.voice}`}
              className={`text-${ row.voice === 'approved' ? 'success' : row.voice === 'progress' ? 'warning' : row.voice === 'rejected' || row.voice === 'expired' ? 'danger' : 'light' }`}
            >
               <div className="d-flex">
                  <Icon name={row.voice === 'approved' ? 'check-circle' : row.ids[0].status === 'progress' ? 'clock' : row.ids[0].status === 'rejected' || row.ids[0].status === 'expired' ? 'cross-circle' : 'light' }/>
                  Voice
                </div>
            </div>
          </li>)}
          <li>
            <div
              style={{
                fontSize: "13px",
                margin: "0px",
                display: "inline-block",
                width: "80px",
              }}
              data-toggle="tooltip"
              title={row.address === "not provided" ? "Address: Not Collected" : `Voice: ${row.address}`}
              className={`text-${ row.address === 'approved' ? 'success' : row.address === 'progress' ? 'warning' : row.address === 'rejected' || row.address === 'expired' ? 'danger' : 'light' }`}
            >
              <div className="d-flex">
                <Icon name={row.address === 'approved' ? 'check-circle' : row.address === 'progress' ? 'clock' : row.address === 'rejected' || row.address === 'expired' ? 'cross-circle' : 'light' }/>
                Address
              </div>
            </div>
          </li>
        </ul>
        <ul className="list-status d-flex">
          <li>
            <div
              style={{
               fontSize: "13px",
                margin: "0px",
                display: "inline-block",
                width: "80px",
              }}
              data-toggle="tooltip"
              title={row.ids[1].status === "not provided" ? "Document 1: Not Collected" : `${row.ids[1].type}: ${row.ids[1].status}`}
              className={`text-${row.ids[1].status === 'approved' ? 'success' : row.ids[1].status === 'progress' ? 'warning' : row.ids[1].status === 'rejected' || row.ids[1].status === 'expired' ? 'danger' : 'light' }`}
            >
              <div className="d-flex">
                <Icon name={row.ids[1].status === 'approved' ? 'check-circle' : row.ids[1].status === 'progress' ? 'clock' : row.ids[1].status === 'rejected' || row.ids[1].status === 'expired' ? 'cross-circle' : 'light' }/>
                {row.ids.length < 2 ? (<div>Document 2</div>):(<div>{row.ids[1].type}</div>)}
              </div>
            </div>
          </li>
          <li>
            <div
              style={{
                fontSize: "13px",
                margin: "0px",
                display: "inline-block",
                width: "80px",
              }}
              data-toggle="tooltip"
              title={row.face === "not provided" ? "Face: Not Collected" : `Voice: ${row.face}`}
              className={`text-${ row.face === 'approved' ? 'success' : row.face === 'progress' ? 'warning' : row.face === 'rejected' || row.face === 'expired' ? 'danger' : 'light' }`}
            >
              <div className="d-flex">
                <Icon name={row.face === 'approved' ? 'check-circle' : row.face === 'progress' ? 'clock' : row.face === 'rejected' || row.face === 'expired' ? 'cross-circle' : 'light' }/>
                Face
              </div>
            </div>
          </li> */}
        </ul>
      </div>
    ),
  },
  {
    name: "Last Verified",
    selector: (row) => row.lastLogin,
    grow: 2,
    cell: (row) => (
      <div className="">
        <span>{row.date_completed}</span>
      </div>
    ),
  },

  {
    name: "",
    grow: 0.5,
    selector: (row) => row.name,
    compact: true,
    cell: (row) => (
      <div className="row">
        <div className="col-6 ">
          <UncontrolledDropdown className="mr-2">
            <DropdownToggle tag="a" className="btn btn-icon btn-trigger">
              <Icon name="more-v"></Icon>
            </DropdownToggle>
            <DropdownMenu>
              <ul className="link-list-opt no-bdr ">
                <li onClick={() => (window.location = `mailto:${row.email}`)}>
                  <a
                    href="#sendEmail"
                    onClick={(ev) => {
                      ev.preventDefault();
                    }}
                  >
                    <Icon name="mail"></Icon>
                    <span>Send Email</span>
                  </a>
                </li>
                <li onClick={() => (window.location = `tel:${row.to_phone}`)}>
                  <a
                    href="#edit"
                    onClick={(ev) => {
                      ev.preventDefault();
                    }}
                  >
                    <Icon name="call"></Icon>
                    <span>Call</span>
                  </a>
                </li>
                <li>
                  <a
                    href="#markasdone"
                    onClick={(ev) => {
                      ev.preventDefault();
                    }}
                  >
                    <Icon name="done"></Icon>
                    <span>Re-Verify</span>
                  </a>
                </li>
              </ul>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      </div>
    ),
    sortable: true,
  },
];

export const clientListColumns6 = [
  {
    name: "Client",
    selector: (row) => row.name,
    compact: true,
    grow: 5,
    style: { paddingRight: "20px" },
    cell: (row) => (
      <Link to={`/customer/menu/client-list/${row.matter_reason}`}>
        <div className="user-card mt-2 mb-2 ">
          <UserAvatar theme={row.avatarBg} text={findUpper(row.name)} image={row.image}></UserAvatar>
          <div className="user-info">
            <span className="tb-lead">{row.name} </span>
            <span>{` ${row.address}`}</span>
          </div>
        </div>
      </Link>
    ),
    sortable: true,
  },
  {
    name: "Contact",
    selector: (row) => row.lastLogin,
    grow: 4,
    cell: (row) => (
      <div className="user-card mt-2 mb-2 " data-toggle="tooltip" title={row.validation_status}>
        <div className="user-info">
          <span className="tb-lead"></span>
          <span>
            {` ${row.to_phone}`}
            {` ${row.email}`}
          </span>
        </div>
      </div>
    ),
  },
  {
    name: "#Smart Verifies",
    selector: (row) => row.lastLogin,
    grow: 4,
    cell: (row) => (
      <div
        className="center-block user-card"
        style={{ marginLeft: "auto", marginRight: "auto" }}
        data-toggle="tooltip"
        title={row.type.charAt(0).toUpperCase() + row.type.slice(1)}
      >
        <span className="badge rounded-pill bg-primary">{row.smart_verifies}</span>
      </div>
    ),
  },

  {
    name: "Status",
    selector: (row) => row.verified,
    hide: "lg",
    cell: (row) => (
      <div
        className="nk-menu-icon"
        data-toggle="tooltip"
        title={row.validation_status.charAt(0).toUpperCase() + row.validation_status.slice(1)}
      >
        {row.validation_status === "Drafts" && <Badge>{row.validation_status}</Badge>}
        {row.validation_status === "In Progress" && <Badge color="warning">{row.validation_status}</Badge>}
        {row.validation_status === "Received" && <Badge color="orange">{row.validation_status}</Badge>}
        {row.validation_status === "Returned" && <Badge color="danger">{row.validation_status}</Badge>}
        {row.validation_status === "Approved" && <Badge color="success">{row.validation_status}</Badge>}
      </div>
    ),
  },
  {
    name: "Verified Date",
    selector: (row) => row.lastLogin,
    grow: 3,
    cell: (row) => (
      <div className="">
        <span>{row.date_completed}</span>
      </div>
    ),
  },

  {
    name: "",
    grow: 0.5,
    selector: (row) => row.name,
    compact: true,
    cell: (row) => (
      <div className="row">
        <div className="col-6 ">
          <UncontrolledDropdown className="mr-2">
            <DropdownToggle tag="a" className="btn btn-icon btn-trigger">
              <Icon name="more-v"></Icon>
            </DropdownToggle>
            <DropdownMenu>
              <ul className="link-list-opt no-bdr ">
                <li onClick={() => (window.location = `mailto:${row.email}`)}>
                  <a
                    href="#sendEmail"
                    onClick={(ev) => {
                      ev.preventDefault();
                    }}
                  >
                    <Icon name="mail"></Icon>
                    <span>Send Email</span>
                  </a>
                </li>
                <li onClick={() => (window.location = `tel:${row.to_phone}`)}>
                  <a
                    href="#edit"
                    onClick={(ev) => {
                      ev.preventDefault();
                    }}
                  >
                    <Icon name="call"></Icon>
                    <span>Call</span>
                  </a>
                </li>
                <li>
                  <a
                    href="#markasdone"
                    onClick={(ev) => {
                      ev.preventDefault();
                    }}
                  >
                    <Icon name="done"></Icon>
                    <span>Re-Verify</span>
                  </a>
                </li>
              </ul>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      </div>
    ),
    sortable: true,
  },
];

export const clientListColumns7 = [
  {
    name: "Client",
    selector: (row) => row.name,
    compact: true,
    grow: 4,
    style: { paddingRight: "20px" },
    cell: (row) => (
      <Link to={`/customer/menu/client-list/${row.id}`}>
        <div className="user-card mt-2 mb-2 position-relative">
          <UserAvatar theme={row.avatarBg} text={findUpper(row.name)} image={row.image} className="me-1">
            <span className="position-absolute top-100 start-100 translate-middle badge rounded-pill bg-danger">
              {row.smart_verifies}
            </span>
          </UserAvatar>
          <div className="user-info">
            <span className="tb-lead">{row.name} </span>
            <span>{` ${row.to_phone}`}</span>
          </div>
        </div>
      </Link>
    ),
    sortable: true,
  },
  {
    name: "Contact",
    selector: (row) => row.lastLogin,
    grow: 4,
    cell: (row) => (
      <div className="user-card mt-2 mb-2 " data-toggle="tooltip" title={row.validation_status}>
        <div className="user-info">
          <span className="tb-lead"></span>
          <span>
            {` ${row.address}`}
            <br />
            {` ${row.email}`}
          </span>
        </div>
      </div>
    ),
  },
  // {
  //   name: "#Smart Verifies",
  //   selector: (row) => row.lastLogin,
  //   grow: 4,
  //   cell: (row) => (
  //     <div
  //       className="center-block user-card"
  //       style={{ marginLeft: "auto", marginRight: "auto" }}
  //       data-toggle="tooltip"
  //       title={row.type.charAt(0).toUpperCase() + row.type.slice(1)}
  //     >
  //       <span className="badge rounded-pill bg-primary">
  //         {row.smart_verifies}
  //       </span>
  //     </div>
  //   ),
  // },

  {
    name: "Status",
    selector: (row) => row.verified,
    hide: "lg",
    cell: (row) => (
      <div
        className="nk-menu-icon"
        data-toggle="tooltip"
        title={row.validation_status.charAt(0).toUpperCase() + row.validation_status.slice(1)}
      >
        {row.validation_status === "Drafts" && <Badge color="outline-dark">{row.validation_status}</Badge>}
        {row.validation_status === "In Progress" && <Badge color="outline-warning">{row.validation_status}</Badge>}
        {row.validation_status === "Received" && (
          <Badge style={{ color: "orange" }} color="outline">
            {row.validation_status}
          </Badge>
        )}
        {row.validation_status === "Returned" && <Badge color="outline-danger">{row.validation_status}</Badge>}
        {row.validation_status === "Approved" && <Badge color="outline-success">{row.validation_status}</Badge>}
      </div>
    ),
  },
  {
    name: "Verified Date",
    selector: (row) => row.lastLogin,
    grow: 3.5,
    cell: (row) => (
      <div className="">
        <span>{row.date_completed && row.date_completed[0]}</span>
        <br />
        <span>{row.date_completed && row.date_completed[1]}</span>
      </div>
    ),
  },

  {
    name: "",
    grow: 0.5,
    selector: (row) => row.name,
    compact: true,
    cell: (row) => (
      <div className="row">
        <div className="col-6 ">
          <UncontrolledDropdown className="mr-2">
            <DropdownToggle tag="a" className="btn btn-icon btn-trigger">
              <Icon name="more-v"></Icon>
            </DropdownToggle>
            <DropdownMenu>
              <ul className="link-list-opt no-bdr ">
                <li onClick={() => (window.location = `mailto:${row.email}`)}>
                  <a
                    href="#sendEmail"
                    onClick={(ev) => {
                      ev.preventDefault();
                    }}
                  >
                    <Icon name="mail"></Icon>
                    <span>Send Email</span>
                  </a>
                </li>
                <li onClick={() => (window.location = `tel:${row.to_phone}`)}>
                  <a
                    href="#edit"
                    onClick={(ev) => {
                      ev.preventDefault();
                    }}
                  >
                    <Icon name="call"></Icon>
                    <span>Call</span>
                  </a>
                </li>
                <li>
                  <a
                    href="#markasdone"
                    onClick={(ev) => {
                      ev.preventDefault();
                    }}
                  >
                    <Icon name="done"></Icon>
                    <span>Re-Verify</span>
                  </a>
                </li>
              </ul>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      </div>
    ),
    sortable: true,
  },
];

export const clientListColumns8 = [
  {
    name: "Client",
    selector: (row) => row.name,
    compact: true,
    grow: 5,
    style: { paddingRight: "20px" },
    cell: (row) => (
      <Link to={`/customer/menu/client-list/${row.uuid}`}>
        <div className="user-card mt-2 mb-2 position-relative">
          <UserAvatar
            theme={row.avatarBg}
            text={findUpper(row.name.replace(" (R)"))}
            image={row.facial}
            className="me-1"
          >
            <span className="position-absolute top-100 start-100 translate-middle badge rounded-pill bg-danger">
              {row.request_count}
            </span>
          </UserAvatar>
          <div className="user-info">
            <span className="tb-lead">{row.name} </span>
            <span>{` ${row.phone ? row.phone : "Not Collected"}`}</span>
          </div>
        </div>
      </Link>
    ),
    sortable: true,
  },
  {
    name: "Contact",
    selector: (row) => row.location,
    grow: 5,
    cell: (row) => (
      <div className="user-card mt-2 mb-2 " data-toggle="tooltip" title={row.status}>
        <div className="user-info">
          <span className="tb-lead"></span>
          <span>
            {` ${row.location.address1 ? row.location.address1 : "Not Collected"}`}
            <br />
            {` ${row.email}`}
          </span>
        </div>
      </div>
    ),
  },
  // {
  //   name: "#Smart Verifies",
  //   selector: (row) => row.lastLogin,
  //   grow: 4,
  //   cell: (row) => (
  //     <div
  //       className="center-block user-card"
  //       style={{ marginLeft: "auto", marginRight: "auto" }}
  //       data-toggle="tooltip"
  //       title={row.type.charAt(0).toUpperCase() + row.type.slice(1)}
  //     >
  //       <span className="badge rounded-pill bg-primary">
  //         {row.smart_verifies}
  //       </span>
  //     </div>
  //   ),
  // },

  {
    name: "Status",
    selector: (row) => row.status,
    hide: "lg",
    cell: (row) => (
      <div
        className="nk-menu-icon"
        data-toggle="tooltip"
        title={row.status.charAt(0).toUpperCase() + row.status.slice(1)}
      >
        {row.status === "In Progress" && <Badge color="outline-warning">{row.status}</Badge>}
        {row.status === "Received" && (
          <Badge style={{ color: "orange" }} color="outline">
            {row.status}
          </Badge>
        )}
        {row.validation_status === "Drafts" && <Badge color="outline-dark">{row.validation_status}</Badge>}
        {row.validation_status === "Drafts" && <Badge color="outline-dark">{row.validation_status}</Badge>}
        {row.validation_status === "In Progress" && <Badge color="outline-warning">{row.validation_status}</Badge>}
        {row.validation_status === "Received" && (
          <Badge style={{ color: "orange" }} color="outline">
            {row.validation_status}
          </Badge>
        )}
        {row.validation_status === "Returned" && <Badge color="outline-danger">{row.validation_status}</Badge>}
        {row.validation_status === "Approved" && <Badge color="outline-success">{row.validation_status}</Badge>}
      </div>
    ),
  },
  {
    name: "Last Verified Date",
    selector: (row) => row.created_at,
    grow: 4.5,
    cell: (row) => (
      <div className="">
        {row.created_at && (
          <span>
            {" "}
            {new Date(row.created_at).toLocaleDateString("default", { weekday: "long" }) +
              " " +
              new Date(row.created_at).toLocaleDateString("default", {
                month: "long",
                day: "numeric",
                year: "numeric",
              })}
          </span>
        )}
        {row.created_at && (
          <span>
            <br />
            {Math.trunc((new Date().getTime() - new Date(row.created_at)) / (1000 * 3600 * 24)) === 0
              ? "Today"
              : Math.trunc((new Date().getTime() - new Date(row.created_at)) / (1000 * 3600 * 24)) === 1
                ? "Yesterday"
                : Math.trunc((new Date().getTime() - new Date(row.created_at)) / (1000 * 3600 * 24)) + " Days ago"}
          </span>
        )}
        {!row.created_at && <span>Never Verified</span>}
      </div>
    ),
  },

  {
    name: "",
    grow: 0.2,
    selector: (row) => row.name,
    compact: true,
    cell: (row) => (
      <div className="row ps-5">
        <div className="col-6 ">
          <UncontrolledDropdown className="mr-2">
            <DropdownToggle tag="a" className="btn btn-icon btn-trigger">
              <Icon name="more-v"></Icon>
            </DropdownToggle>
            <DropdownMenu>
              <ul className="link-list-opt no-bdr ">
                <li onClick={() => (window.location = `mailto:${row.email}`)}>
                  <a
                    href="#sendEmail"
                    onClick={(ev) => {
                      ev.preventDefault();
                    }}
                  >
                    <Icon name="mail"></Icon>
                    <span>Send Email</span>
                  </a>
                </li>
                <li onClick={() => (window.location = `tel:${row.to_phone}`)}>
                  <a
                    href="#edit"
                    onClick={(ev) => {
                      ev.preventDefault();
                    }}
                  >
                    <Icon name="call"></Icon>
                    <span>Call</span>
                  </a>
                </li>
                <li>
                  <a
                    href="#markasdone"
                    onClick={(ev) => {
                      ev.preventDefault();
                    }}
                  >
                    <Icon name="done"></Icon>
                    <span>Re-Verify</span>
                  </a>
                </li>
              </ul>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      </div>
    ),
    sortable: true,
  },
];

export const DataTableData = [
  {
    id: 0,
    name: "Francine Kirby",
    age: 24,
    gender: "female",
    company: "BUZZWORKS",
    startDate: "2017-02-17",
    salary: "$2,570.39",
    document: [
      { name: "Passport", status: "verified" },
      { name: "Photo Id", status: "processing" },
    ],
  },
  {
    id: 1,
    name: "Reva Best",
    age: 40,
    gender: "female",
    company: "MARQET",
    startDate: "2021-10-14",
    salary: "$1,488.76",
    document: [
      { name: "Passport", status: "processing" },
      { name: "Photo Id", status: "rejected" },
    ],
  },
  {
    id: 2,
    name: "Alexis Flores",
    age: 21,
    gender: "female",
    company: "OBONES",
    startDate: "2020-04-28",
    salary: "$1,336.93",
  },
  {
    id: 3,
    name: "Nixon Sullivan",
    age: 30,
    gender: "male",
    company: "SLUMBERIA",
    startDate: "2016-10-08",
    salary: "$2,156.70",
    document: [
      { name: "Passport", status: "processing" },
      { name: "Photo Id", status: "rejected" },
    ],
  },
  {
    id: 4,
    name: "Foreman Wooten",
    age: 20,
    gender: "male",
    company: "ESCENTA",
    startDate: "2018-07-12",
    salary: "$3,057.42",
    document: [
      { name: "Passport", status: "processing" },
      { name: "Photo Id", status: "rejected" },
    ],
  },
  {
    id: 5,
    name: "Franco Davis",
    age: 28,
    gender: "male",
    company: "ZILLACON",
    startDate: "2015-10-08",
    salary: "$2,730.88",
    document: [
      { name: "Passport", status: "rejected" },
      { name: "Photo Id", status: "rejected" },
    ],
  },
  {
    id: 6,
    name: "Bullock Kline",
    age: 32,
    gender: "male",
    company: "SAVVY",
    startDate: "2017-07-03",
    salary: "$2,986.05",
    document: [
      { name: "Passport", status: "rejected" },
      { name: "Photo Id", status: "rejected" },
    ],
  },
  {
    id: 7,
    name: "Baird Coffey",
    age: 36,
    gender: "male",
    company: "BLEENDOT",
    startDate: "2014-01-27",
    salary: "$2,755.80",
    document: [
      { name: "Passport", status: "rejected" },
      { name: "Photo Id", status: "rejected" },
    ],
  },
  {
    id: 8,
    name: "Eula Walters",
    age: 40,
    gender: "female",
    company: "UXMOX",
    startDate: "2020-09-19",
    salary: "$3,302.75",
    document: [
      { name: "Passport", status: "rejected" },
      { name: "Photo Id", status: "rejected" },
    ],
  },
  {
    id: 9,
    name: "Gaines Moss",
    age: 26,
    gender: "male",
    company: "INCUBUS",
    startDate: "2017-10-13",
    salary: "$3,856.24",
    document: [
      { name: "Passport", status: "verified" },
      { name: "Photo Id", status: "verified" },
    ],
  },
];

export const userData = [
  {
    id: 1,
    avatarBg: "purple",
    name: "Abu Bin Ishtiyak",
    displayName: "Ishtiak",
    dob: "10 Aug, 1980",
    role: "Customer",
    checked: false,
    email: "info@softnio.com",
    balance: "35,040.34",
    phone: "818474958",
    emailStatus: "success",
    kycStatus: "success",
    lastLogin: "10 Feb 2020",
    status: "Active",
    address: "2337 Kildeer Drive",
    state: "Kentucky",
    country: "Canada",
    designation: "UI/UX Designer",
    projects: "213",
    performed: "87.5",
    tasks: "587",
  },
  {
    id: 2,
    avatarBg: "purple",
    image: User3,
    name: "Ashley Lawson",
    dob: "10 Sept, 1990",
    role: "Investor",
    email: "ashley@softnio.com",
    balance: "580.00",
    checked: false,
    phone: "1243941787",
    emailStatus: "success",
    kycStatus: "pending",
    lastLogin: "07 Feb 2020",
    status: "Pending",
    country: "United States",
    designation: "UI/UX Designer",
    projects: "213",
    performed: "87.5",
    tasks: "587",
  },
  {
    id: 3,
    avatarBg: "info",
    name: "Joe Larson",
    dob: "19 Jan, 1985",
    role: "Customer",
    email: "larson@example.com",
    balance: "32,000.34",
    checked: false,
    phone: "1686032320",
    emailStatus: "success",
    kycStatus: "success",
    lastLogin: "04 Feb 2020",
    status: "Active",
    country: "England",
    designation: "UI/UX Designer",
    projects: "213",
    performed: "87.5",
    tasks: "587",
  },
  {
    id: 4,
    avatarBg: "danger",
    name: "Jane Montgomery",
    dob: "24 April, 1985",
    role: "Subscriber",
    email: "jane84@example.com",
    balance: "0.00",
    checked: false,
    phone: "4392715360",
    emailStatus: "alert",
    kycStatus: "alert",
    lastLogin: "01 Feb 2020",
    status: "Suspend",
    country: "United States",
    designation: "UI/UX Designer",
    projects: "213",
    performed: "87.5",
    tasks: "587",
  },
  {
    id: 5,
    avatarBg: "purple",
    name: "Frances Burns",
    dob: "30 May, 2000",
    role: "Manager",
    image: User,
    email: "frances@example.com",
    balance: "42.50",
    checked: false,
    phone: "6391303150",
    emailStatus: "pending",
    kycStatus: "error",
    lastLogin: "31 Jan 2020",
    status: "Active",
    country: "Bangladesh",
    designation: "UI/UX Designer",
    projects: "213",
    performed: "87.5",
    tasks: "587",
  },
  {
    id: 6,
    avatarBg: "primary",
    name: "Alan Butler",
    dob: "10 Feb, 1997",
    role: "Investor",
    image: User2,
    email: "butler@example.com",
    balance: "440.34",
    checked: false,
    phone: "9633091706",
    emailStatus: "pending",
    kycStatus: "warning",
    lastLogin: "18 Jan 2020",
    status: "Inactive",
    country: "India",
    designation: "UI/UX Designer",
    projects: "213",
    performed: "87.5",
    tasks: "587",
  },
  {
    id: 7,
    avatarBg: "warning",
    name: "Victoria Lynch",
    dob: "02 May, 1993",
    role: "Investor",
    email: "victoria@example.com",
    balance: "59,400.68",
    checked: false,
    phone: "8119854846",
    emailStatus: "success",
    kycStatus: "success",
    lastLogin: "15 Jan 2020",
    status: "Active",
    country: "China",
    designation: "UI/UX Designer",
    projects: "213",
    performed: "87.5",
    tasks: "587",
  },
  {
    id: 8,
    avatarBg: "success",
    name: "Patrick Newman",
    dob: "15 Feb, 1997",
    role: "Customer",
    email: "patrick@example.com",
    balance: "30.00",
    checked: false,
    phone: "9422384474",
    emailStatus: "success",
    kycStatus: "pending",
    lastLogin: "08 Jan 2020",
    status: "Active",
    country: "India",
    designation: "UI/UX Designer",
    projects: "213",
    performed: "87.5",
    tasks: "587",
  },
  {
    id: 9,
    avatarBg: "purple",
    name: "Jane Harris",
    dob: "28 Feb, 1985",
    role: "Customer",
    image: User4,
    email: "harris@example.com",
    balance: "5,530.23",
    checked: false,
    phone: "1234472384",
    emailStatus: "pending",
    kycStatus: "pending",
    lastLogin: "02 Jan 2020",
    status: "Pending",
    country: "Vietnam",
    designation: "UI/UX Designer",
    projects: "213",
    performed: "87.5",
    tasks: "587",
  },
  {
    id: 10,
    avatarBg: "purple",
    name: "Emma Walker",
    dob: "30 Dec, 1998",
    role: "Investor",
    email: "walker@example.com",
    balance: "55.00",
    checked: false,
    phone: "4634717173",
    emailStatus: "success",
    kycStatus: "success",
    lastLogin: "25 Dec 2019",
    status: "Active",
    country: "United States",
    designation: "UI/UX Designer",
    projects: "213",
    performed: "87.5",
    tasks: "587",
  },
  {
    id: 11,
    avatarBg: "pink",
    name: "Lilja Peltola",
    dob: "30 Dec, 1998",
    role: "Investor",
    email: "lilja@example.com",
    balance: "105.00",
    checked: false,
    phone: "4634717173",
    emailStatus: "success",
    kycStatus: "pending",
    lastLogin: "25 Dec 2019",
    status: "Active",
    country: "Canada",
    designation: "Web Developer",
    projects: "213",
    performed: "87.5",
    tasks: "587",
  },
  {
    id: 12,
    avatarBg: "secondary",
    name: "Annette Hunter",
    dob: "30 Dec, 1998",
    role: "Investor",
    email: "hunter@example.com",
    balance: "55.00",
    checked: false,
    phone: "4634717173",
    emailStatus: "success",
    kycStatus: "success",
    lastLogin: "25 Dec 2019",
    status: "Pending",
    country: "United States",
    designation: "UI/UX Designer",
    projects: "213",
    performed: "87.5",
    tasks: "587",
  },
  {
    id: 13,
    avatarBg: "pink",
    name: "Sara Koivisto",
    dob: "30 Dec, 1998",
    role: "Customer",
    email: "sara@example.com",
    balance: "165.00",
    checked: false,
    phone: "4634717173",
    emailStatus: "rejected",
    kycStatus: "pending",
    lastLogin: "25 Dec 2019",
    status: "Active",
    country: "Russia",
    designation: "Web Developer",
    projects: "213",
    performed: "87.5",
    tasks: "587",
  },
  {
    id: 14,
    avatarBg: "blue",
    name: "Kianna Pham",
    dob: "30 Dec, 1998",
    role: "Admin",
    email: "kiana@example.com",
    balance: "55.00",
    checked: false,
    phone: "4634717173",
    emailStatus: "success",
    kycStatus: "rejected",
    lastLogin: "25 Dec 2019",
    status: "Suspend",
    country: "South Korea",
    designation: "Accountant",
    projects: "213",
    performed: "87.5",
    tasks: "587",
  },
  {
    id: 15,
    avatarBg: "pink",
    name: "Raymond Atkins",
    dob: "30 Dec, 1998",
    role: "Customer",
    image: User4,
    email: "sara@example.com",
    balance: "165.00",
    checked: false,
    phone: "4634717173",
    emailStatus: "rejected",
    kycStatus: "pending",
    lastLogin: "25 Dec 2019",
    status: "Active",
    country: "Russia",
    designation: "Web Developer",
    projects: "213",
    performed: "87.5",
    tasks: "587",
  },
  {
    id: 16,
    avatarBg: "blue",
    name: "Amira Talley",
    dob: "30 Dec, 1998",
    role: "Admin",
    email: "amira@example.com",
    balance: "55.00",
    checked: false,
    phone: "4634717173",
    emailStatus: "pending",
    kycStatus: "rejected",
    lastLogin: "25 Dec 2019",
    status: "Active",
    country: "Saudi Arabia",
    designation: "Lecturer",
    projects: "213",
    performed: "87.5",
    tasks: "587",
  },
  {
    id: 17,
    avatarBg: "secondary",
    name: "Lana Steiner",
    dob: "30 Dec, 1998",
    role: "Admin",
    email: "steinar@example.com",
    balance: "55.00",
    checked: false,
    phone: "4634717173",
    emailStatus: "pending",
    kycStatus: "rejected",
    lastLogin: "25 Dec 2019",
    status: "Pending",
    country: "Latvia",
    designation: "Accountant",
    projects: "213",
    performed: "87.5",
    tasks: "587",
  },
  {
    id: 18,
    avatarBg: "warning",
    name: "Joshua Mcnair",
    dob: "30 Dec, 1998",
    image: User4,
    role: "Admin",
    email: "joshua@example.com",
    balance: "55.00",
    checked: false,
    phone: "4634717173",
    emailStatus: "pending",
    kycStatus: "rejected",
    lastLogin: "25 Dec 2019",
    status: "Suspend",
    country: "Ireland",
    designation: "Web Developer",
    projects: "213",
    performed: "87.5",
    tasks: "587",
  },
  {
    id: 19,
    avatarBg: "secondary",
    name: "Asiya Wolff",
    dob: "30 Dec, 1998",
    role: "Customer",
    email: "asia@example.com",
    balance: "55.00",
    checked: false,
    phone: "4634717173",
    emailStatus: "success",
    kycStatus: "success",
    lastLogin: "25 Dec 2019",
    status: "Active",
    country: "Latvia",
    designation: "Accountant",
    projects: "213",
    performed: "87.5",
    tasks: "587",
  },
  {
    id: 20,
    avatarBg: "warning",
    name: "Fox Mccloud",
    dob: "30 Dec, 1998",
    role: "Admin",
    email: "fox@example.com",
    balance: "55.00",
    checked: false,
    phone: "4634717173",
    emailStatus: "pending",
    kycStatus: "rejected",
    lastLogin: "25 Dec 2019",
    status: "Suspend",
    country: "Ireland",
    designation: "Web Developer",
    projects: "213",
    performed: "87.5",
    tasks: "587",
  },
];

export const clientListTableData = [
  {
    id: "64e4cad78dd98142f96de2d6",
    avatar: "https://static-00.iconduck.com/assets.00/link-square-icon-512x512-tgr53p19.png",
    name: "Yvonne Thomas",
    email: "yvonnethomas@ludak.com",
    company: "MEDIOT",
    req_id: "f87d3259-5c86-4f72-a928-58f28c685a8f",
    uid: 100101241,
    status: {
      status: "Approved",
      step: "",
    },
    type: "estate",
    to_phone: "(842) 547-2326",
    date_requested: "2021-02-01 07:46",
    date_completed: "Monday August 12, 2023, 10 days ago",
    check_avatar: "https://iso.500px.com/wp-content/uploads/2016/03/stock-photo-142984111.jpg",
    check_name: "Jeff",
    source: [
      "https://iso.500px.com/wp-content/uploads/2016/03/stock-photo-142984111.jpg",
      "https://iso.500px.com/wp-content/uploads/2016/03/stock-photo-142984111.jpg",
    ],
    ids: [
      {
        type: "Driver",
        status: "approved",
      },
      {
        type: "Photo id",
        status: "approved",
      },
    ],
    voice: "approved",
    face: "approved",
    address: "approved",
    validation_status: "Valid",
  },
  {
    id: "64e4cad77a095ad845e839a3",
    avatar: "https://static-00.iconduck.com/assets.00/link-square-icon-512x512-tgr53p19.png",
    name: "Mona Mcmahon",
    email: "monamcmahon@suremax.com",
    company: "ANACHO",
    req_id: "f87d3259-5c86-4f72-a928-58f28c685a8f",
    uid: 100101241,
    status: {
      status: "Returned",
      step: "",
    },
    type: "personal",
    to_phone: "(833) 446-2847",
    date_requested: "2017-08-23 08:26",
    date_completed: "Monday August 12, 2023, 10 days ago",
    check_avatar: "https://iso.500px.com/wp-content/uploads/2016/03/stock-photo-142984111.jpg",
    check_name: "Jeff",
    source: [
      "https://iso.500px.com/wp-content/uploads/2016/03/stock-photo-142984111.jpg",
      "https://iso.500px.com/wp-content/uploads/2016/03/stock-photo-142984111.jpg",
    ],
    ids: [
      {
        type: "Photo id",
        status: "not provided",
      },
      {
        type: "Photo id",
        status: "rejected",
      },
    ],
    voice: "not provided",
    face: "rejected",
    address: "rejected",
    validation_status: "rejected",
  },
  {
    id: "64e4cad7491a328836ace004",
    avatar: "https://static-00.iconduck.com/assets.00/link-square-icon-512x512-tgr53p19.png",
    name: "Wilcox Parker",
    email: "wilcoxparker@mediot.com",
    company: "DANCERITY",
    req_id: "f87d3259-5c86-4f72-a928-58f28c685a8f",
    uid: 100101241,
    status: {
      status: "In Progress",
      step: "",
    },
    type: "estate",
    to_phone: "(923) 425-3439",
    date_requested: "2023-06-23 09:31",
    date_completed: "Monday August 12, 2023, 10 days ago",
    check_avatar: "https://iso.500px.com/wp-content/uploads/2016/03/stock-photo-142984111.jpg",
    check_name: "Jeff",
    source: [
      "https://iso.500px.com/wp-content/uploads/2016/03/stock-photo-142984111.jpg",
      "https://iso.500px.com/wp-content/uploads/2016/03/stock-photo-142984111.jpg",
    ],
    ids: [
      {
        type: "Passport",
        status: "progress",
      },
      {
        type: "Document2",
        status: "not provided",
      },
    ],
    voice: "not provided",
    face: "not provided",
    address: "progress",
    validation_status: "verification",
  },
  {
    id: "64e4cad7022a8c800d6de01c",
    avatar: "https://static-00.iconduck.com/assets.00/link-square-icon-512x512-tgr53p19.png",
    name: "Henson Wood",
    email: "hensonwood@dancerity.com",
    company: "SUREMAX",
    req_id: "f87d3259-5c86-4f72-a928-58f28c685a8f",
    uid: 100101241,
    status: {
      status: "Email sent",
      step: "",
    },
    type: "business",
    to_phone: "(983) 554-2605",
    date_requested: "2022-01-24 12:38",
    date_completed: "Monday August 12, 2023, 10 days ago",
    check_avatar: "https://iso.500px.com/wp-content/uploads/2016/03/stock-photo-142984111.jpg",
    check_name: "Jeff",
    source: [
      "https://iso.500px.com/wp-content/uploads/2016/03/stock-photo-142984111.jpg",
      "https://iso.500px.com/wp-content/uploads/2016/03/stock-photo-142984111.jpg",
    ],
    ids: [
      {
        type: "Passport",
        status: "approved",
      },
      {
        type: "Driver",
        status: "approved",
      },
    ],
    voice: "approved",
    face: "approved",
    address: "approved",
    validation_status: "expire soon",
  },

  {
    id: "64e4cad79233e98842c64e73",
    avatar: "https://static-00.iconduck.com/assets.00/link-square-icon-512x512-tgr53p19.png",
    name: "Fern Powers",
    email: "fernpowers@anacho.com",
    company: "MARQET",
    req_id: "f87d3259-5c86-4f72-a928-58f28c685a8f",
    uid: 100101241,
    status: {
      status: "Email sent",
      step: "",
    },
    type: "personal",
    to_phone: "(991) 514-3682",
    date_requested: "2020-04-15 05:46",
    date_completed: "Monday August 12, 2023, 10 days ago",
    check_avatar: "https://iso.500px.com/wp-content/uploads/2016/03/stock-photo-142984111.jpg",
    check_name: "Jeff",
    source: [
      "https://iso.500px.com/wp-content/uploads/2016/03/stock-photo-142984111.jpg",
      "https://iso.500px.com/wp-content/uploads/2016/03/stock-photo-142984111.jpg",
    ],
    ids: [
      {
        type: "Driver",
        status: "expired",
      },
      {
        type: "Driver",
        status: "expired",
      },
    ],
    voice: "expired",
    face: "expired",
    address: "expired",
    validation_status: "expired",
  },
];
export const clientListTableData2 = [
  {
    id: "64e4cad78dd98142f96de2d6",
    avatar: "https://static-00.iconduck.com/assets.00/link-square-icon-512x512-tgr53p19.png",
    name: "Yvonne Thomas",
    email: "yvonnethomas@ludak.com",
    company: "MEDIOT",
    req_id: "f87d3259-5c86-4f72-a928-58f28c685a8f",
    uid: 100101241,
    address: "1234 Elm Street, Springfield, IL 12345",
    status: {
      status: "Approved",
      step: "",
    },
    type: "estate",
    to_phone: "(842) 547-2326",
    date_requested: "2021-02-01 07:46",
    date_completed: ["Monday August 12, 2023", "10 days ago"],
    check_avatar: "https://iso.500px.com/wp-content/uploads/2016/03/stock-photo-142984111.jpg",
    check_name: "Jeff",
    source: [
      "https://iso.500px.com/wp-content/uploads/2016/03/stock-photo-142984111.jpg",
      "https://iso.500px.com/wp-content/uploads/2016/03/stock-photo-142984111.jpg",
    ],
    documents: {
      driver_license: "approved",
      passport: "approved",
      photo_id: "approved",
      voice: "approved",
      face: "approved",
      address: "approved",
    },
    validation_status: "Drafts",
    smart_verifies: 4,
  },
  {
    id: "64e4cad77a095ad845e839a3",
    avatar: "https://static-00.iconduck.com/assets.00/link-square-icon-512x512-tgr53p19.png",
    name: "Mona Mcmahon",
    email: "monamcmahon@suremax.com",
    company: "ANACHO",
    req_id: "f87d3259-5c86-4f72-a928-58f28c685a8f",
    uid: 100101241,
    address: "5678 Maple Avenue, Pleasantville, NY 67890",
    status: {
      status: "Returned",
      step: "",
    },
    type: "personal",
    to_phone: "(833) 446-2847",
    date_requested: "2017-08-23 08:26",
    date_completed: ["Monday August 12, 2023", "10 days ago"],
    check_avatar: "https://iso.500px.com/wp-content/uploads/2016/03/stock-photo-142984111.jpg",
    check_name: "Jeff",
    source: [
      "https://iso.500px.com/wp-content/uploads/2016/03/stock-photo-142984111.jpg",
      "https://iso.500px.com/wp-content/uploads/2016/03/stock-photo-142984111.jpg",
    ],
    documents: {
      driver_license: "rejected",
      passport: "rejected",
      photo_id: "rejected",
      voice: "approved",
      face: "rejected",
      address: "rejected",
    },
    validation_status: "In Progress",
    smart_verifies: 2,
  },
  {
    id: "64e4cad7491a328836ace004",
    avatar: "https://static-00.iconduck.com/assets.00/link-square-icon-512x512-tgr53p19.png",
    name: "Wilcox Parker",
    email: "wilcoxparker@mediot.com",
    company: "DANCERITY",
    req_id: "f87d3259-5c86-4f72-a928-58f28c685a8f",
    uid: 100101241,
    address: "9012 Oak Drive, San Diego, CA 34567",
    status: {
      status: "In Progress",
      step: "",
    },
    type: "estate",
    to_phone: "(923) 425-3439",
    date_requested: "2023-06-23 09:31",
    date_completed: ["Monday August 12, 2023", "10 days ago"],
    check_avatar: "https://iso.500px.com/wp-content/uploads/2016/03/stock-photo-142984111.jpg",
    check_name: "Jeff",
    source: [
      "https://iso.500px.com/wp-content/uploads/2016/03/stock-photo-142984111.jpg",
      "https://iso.500px.com/wp-content/uploads/2016/03/stock-photo-142984111.jpg",
    ],
    documents: {
      driver_license: "approved",
      passport: "approved",
      address: "approved",
    },
    validation_status: "Received",
    smart_verifies: 7,
  },
  {
    id: "64e4cad7022a8c800d6de01c",
    avatar: "https://static-00.iconduck.com/assets.00/link-square-icon-512x512-tgr53p19.png",
    name: "Henson Wood",
    email: "hensonwood@dancerity.com",
    company: "SUREMAX",
    req_id: "f87d3259-5c86-4f72-a928-58f28c685a8f",
    uid: 100101241,
    address: "3456 Pine Street, Houston, TX 45678",
    status: {
      status: "Email sent",
      step: "",
    },
    type: "business",
    to_phone: "(983) 554-2605",
    date_requested: "2022-01-24 12:38",
    date_completed: ["Monday August 12, 2023", "10 days ago"],
    check_avatar: "https://iso.500px.com/wp-content/uploads/2016/03/stock-photo-142984111.jpg",
    check_name: "Jeff",
    source: [
      "https://iso.500px.com/wp-content/uploads/2016/03/stock-photo-142984111.jpg",
      "https://iso.500px.com/wp-content/uploads/2016/03/stock-photo-142984111.jpg",
    ],
    documents: {
      driver_license: "expire soon",
      passport: "expire soon",
      photo_id: "expire soon",
      voice: "expire soon",
      face: "expire soon",
      address: "expire soon",
    },
    validation_status: "Returned",
    smart_verifies: 1,
  },

  {
    id: "64e4cad79233e98842c64e73",
    avatar: "https://static-00.iconduck.com/assets.00/link-square-icon-512x512-tgr53p19.png",
    name: "Fern Powers",
    email: "fernpowers@anacho.com",
    company: "MARQET",
    req_id: "f87d3259-5c86-4f72-a928-58f28c685a8f",
    uid: 100101241,
    address: "7890 Birch Lane, Denver, CO 56789",
    status: {
      status: "Email sent",
      step: "",
    },
    type: "personal",
    to_phone: "(991) 514-3682",
    date_requested: "2020-04-15 05:46",
    date_completed: ["Monday August 12, 2023", "10 days ago"],
    check_avatar: "https://iso.500px.com/wp-content/uploads/2016/03/stock-photo-142984111.jpg",
    check_name: "Jeff",
    source: [
      "https://iso.500px.com/wp-content/uploads/2016/03/stock-photo-142984111.jpg",
      "https://iso.500px.com/wp-content/uploads/2016/03/stock-photo-142984111.jpg",
    ],
    documents: {
      driver_license: "expired",
      passport: "expired",
      photo_id: "expired",
      voice: "expired",
      face: "expired",
      address: "expired",
    },
    validation_status: "Approved",
    smart_verifies: 12,
  },
];

export const invoiceTableColumns = [
  {
    name: "Invoice #",
    selector: (row) => <Link to={`/customer/admin/billing/invoices/invoice-detail/${row.invnum}`}>{row.invnum}</Link>,
    sortable: true,
  },
  {
    name: "Amount",
    // name: "New Amount",
    selector: (row) => '$' + row.charged,
    sortable: true,
  },
  {
    name: "Balance",
    selector: (row) => '$' + row.balance,
    sortable: true,
  },
  {
    name: "Date",
    selector: (row) => row.date,
    sortable: true,
  },
  // {
  //   cell: (row) => (
  //     <div className="ps-4 col-6 col-sm-3">
  //       <UncontrolledDropdown>
  //         <DropdownToggle tag="a" className="btn btn-icon btn-trigger">
  //           <Icon name="more-v"></Icon>
  //         </DropdownToggle>
  //         <DropdownMenu>
  //           <ul className="link-list-opt no-bdr ">
  //             <li>
  //               <DropdownItem tag={Link} to={`/customer/admin/billing/invoices/invoice-detail/${row.invnum}`}>
  //                 <Icon name="eye"></Icon>
  //                 <span>View</span>
  //               </DropdownItem>
  //             </li>
  //             <li>
  //               <DropdownItem tag={Link} to={`/invoice-print/9`}>
  //                 <Icon name="trash"></Icon>
  //                 <span>Print</span>
  //               </DropdownItem>
  //             </li>
  //           </ul>
  //         </DropdownMenu>
  //       </UncontrolledDropdown>
  //     </div>
  //   ),
  //   grow: 0,
  // },
];
export const PaymentTableColumns = [
  {
    name: "Payment #",
    selector: (row) => row.paynum,
    sortable: true,
  },
  {
    name: "Date",
    selector: (row) => row.date,
    sortable: true,
  },
  {
    name: "Payment type",
    selector: (row) => row.payby,
    sortable: true,
  },
  {
    name: "Amount",
    selector: (row) => row.paid,
    sortable: true,
  },
];

export const transactionsTableColumns = [
  {
    name: "Type",
    selector: (row) => row.type,
    sortable: true,
  },
  {
    name: "Description",
    selector: (row) => row.description,
    sortable: true,
  },
  {
    name: "Date",
    selector: (row) => row.date_pretty,
    sortable: true,
  },
  {
    name: "Payment Amount",
    selector: (row) => row.amount_pretty,
    sortable: true,
  },
  {
    name: "Balance",
    selector: (row) => row.balance_pretty,
    sortable: true,
  },
];
