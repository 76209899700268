import React, { useState } from "react";
import Content from "../../../../../../layout/content/Content";

import {

  Card,

} from "reactstrap";
import { capitalizeFirstLetter, capitalizeWords, getInitials } from "../../../../../../utils/Utils";


const Recipients = ({ data }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [modal, setModal] = useState(false);
  const [tooltipOpen, setOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
    setModal(!modal);
  };

  console.log(data.biometrics)

  const toggleTooltip = () => {
    setOpen(!tooltipOpen);
  };



  return (
    <React.Fragment>
      <Content>
        {(!data.recipients || ((!data.recipients.third_party || !data.recipients.third_party.length) && !data.recipients.primary)) && (
          <div className="nk-block-head">
            <div className="card card-bordered card-stretch">
              <h5 className="title" style={{ textAlign: "center", margin: "10px" }}>
                No Recipients
              </h5>
            </div>
          </div>
        )}
        {(data.recipients && data.recipients.primary) && (
          <>

            <h5 >
              Primary Recipient
            </h5>

            {/* <Card style={{ height: '200px', width: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} className="card-preview mx-auto" > */}
            <Card className="card-preview mx-auto" >
              <div className="team">


                <div className={"table-responsive"}>
                  <table className="table">
                    <thead >
                      <tr>

                        <th>Name</th>
                        <th>Title</th>
                        <th>Email</th>
                        <th>Phone</th>

                      </tr>
                    </thead>
                    <tbody>

                      <tr key={'item.id'}>
                        {/* <th>{item.id}</th> */}
                        <td style={{ width: '25%' }}>{capitalizeWords(data.recipients.primary.name)}</td>
                        <td style={{ width: '25%' }}>{capitalizeFirstLetter(data.recipients.primary.title || 'No Title')}</td>
                        <td style={{ width: '25%' }}>{data.recipients.primary.email || 'No Name'}</td>
                        <td style={{ width: '25%' }}>{data.recipients.primary.phone}</td>
                      </tr>


                    </tbody>
                  </table>
                </div>



              </div >
            </Card>
          </>
        )}

        {(data.recipients && data.recipients.primary) && (
          <>

            <h5 className="mt-5">
              Requestor
            </h5>

            {/* <Card style={{ height: '200px', width: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} className="card-preview mx-auto" > */}
            <Card className="card-preview mx-auto" >
              <div className="team">


                <div className={"table-responsive"}>
                  <table className="table">
                    <thead >
                      <tr>

                        <th>Name</th>
                        <th>Title</th>
                        <th>Email</th>
                        <th>Phone</th>

                      </tr>
                    </thead>
                    <tbody>

                      <tr key={'item.id'}>
                        {/* <th>{item.id}</th> */}
                        <td style={{ width: '25%' }}>{capitalizeWords(data.recipients.requestor.name)}</td>
                        <td style={{ width: '25%' }}>{capitalizeFirstLetter(data.recipients.requestor.title || 'No Title')}</td>
                        <td style={{ width: '25%' }}>{data.recipients.requestor.email || 'No Name'}</td>
                        <td style={{ width: '25%' }}>{data.recipients.requestor.phone}</td>
                      </tr>


                    </tbody>
                  </table>
                </div>



              </div >
            </Card>
          </>
        )}

        {(data.recipients && data.recipients.reviewer) && (
          <>

            <h5 className="mt-5">
              Reviewed By
            </h5>

            {/* <Card style={{ height: '200px', width: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} className="card-preview mx-auto" > */}
            <Card className="card-preview mx-auto" >
              <div className="team">


                <div className={"table-responsive"}>
                  <table className="table">
                    <thead >
                      <tr>

                        <th>Name</th>
                        <th>Title</th>
                        <th>Email</th>
                        <th>Phone</th>

                      </tr>
                    </thead>
                    <tbody>

                      <tr key={'item.id'}>
                        {/* <th>{item.id}</th> */}
                        <td style={{ width: '25%' }}>{capitalizeWords(data.recipients.reviewer.name)}</td>
                        <td style={{ width: '25%' }}>{capitalizeFirstLetter(data.recipients.reviewer.title || 'No Title')}</td>
                        <td style={{ width: '25%' }}>{data.recipients.reviewer.email || 'No Name'}</td>
                        <td style={{ width: '25%' }}>{data.recipients.reviewer.phone}</td>
                      </tr>


                    </tbody>
                  </table>
                </div>



              </div >
            </Card>
          </>
        )}

        {(data.recipients && data.recipients.third_party && data.recipients.third_party.length > 0) && (
          <>

            <h5 className="mt-5" >
              Third-party recipient(s)

            </h5>

            {/* <Card style={{ height: '200px', width: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} className="card-preview mx-auto" > */}
            <Card className="card-preview mx-auto" >
              <div className="team">


                <div className={"table-responsive"}>
                  <table className="table">
                    <thead >
                      <tr>

                        <th>Type</th>
                        <th>Company</th>
                        <th>Title</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Mobile</th>

                      </tr>
                    </thead>
                    <tbody>
                      {data.recipients.third_party.map(item => {
                        return (
                          <tr key={'item.id'}>
                            {/* <th>{item.id}</th> */}
                            <td>{capitalizeFirstLetter(item.type)}</td>
                            <td>{capitalizeFirstLetter(item.company)}</td>
                            <td>{capitalizeFirstLetter(item.title || 'No Title')}</td>
                            <td>{capitalizeWords(item.name || 'No Name')}</td>
                            <td>{item.email || 'No Email'}</td>
                            <td>{item.phone || 'No Mobile'}</td>
                          </tr>
                        )
                      })}



                    </tbody>
                  </table>
                </div>



              </div >
            </Card>
          </>
        )}
      </Content>
    </React.Fragment >
  );
};

export default Recipients;
