import { Steps, Step } from "react-step-builder";
import { Col, Container, Row } from "reactstrap";
import { useForm } from "react-hook-form";
import SolutionWizardStart from "./solutionWizardStart/SolutionWizardStart";
import SolutionWizardUser from "./solutionWizardUser/SolutionWizardUser";
import SolutionWizardFinal from "./solutionWizardFinal/SolutionWizardFinal";
import { apiRequest } from "../../../../../utils/Api";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import SolutionWizardThirdParty from "./solutionWizardThirdParty/SolutionWizardThirdParty";
import { useEffect, useState } from "react";
import SolutionWizardSelect from "./solutionWizardSelect/SolutionWizardSelect";


const Header = (props) => {
    return (
        <div className="steps clearfix">
            <ul>
                <li className={props.current >= 1 ? "done" : ""}>
                    <a href="#wizard-01-h-0" onClick={(ev) => ev.preventDefault()}>
                        <span className="number">01</span>
                    </a>
                </li>
                <li className={props.current >= 2 ? "done" : ""}>
                    <a href="#wizard-01-h-1" onClick={(ev) => ev.preventDefault()}>
                        <span className="number">02</span>
                    </a>
                </li>
                {/* <li className={props.current >= 3 ? "done" : ""}>
                    <a href="#wizard-01-h-2" onClick={(ev) => ev.preventDefault()}>
                        <span className="current-info audible">current step: </span>
                        <span className="number">03</span>
                    </a>
                </li> */}
                {/* <li className={props.current >= 4 ? "done" : ""}>
                    <a href="#wizard-01-h-2" onClick={(ev) => ev.preventDefault()}>
                        <span className="current-info audible">current step: </span>
                        <span className="number">04</span>
                    </a>
                </li> */}

            </ul>
        </div>
    );
};

const SolutionAddWizard = () => {
    const { type } = useParams()
    const navigate = useNavigate()
    const [agentList, setAgentList] = useState();
    const [thirdPartieList, setThirdPartieList] = useState();
    const [clientList, setClientList] = useState()
    const [matterList, setMatterList] = useState();
    const [data, setData] = useState({
        client: null,
        thirdparty: null,
        agent: null,
        matter: null
    })
    const config = {
        before: Header,
    };

    useEffect(() => {
        console.log('===========================')
        console.log(data)
    }, [data])

    const {
        register,
        handleSubmit,
        formState: { errors },
        setError,
        clearErrors,
        getValues,
        watch,
        setValue,
        unregister,
        reset
    } = useForm();

    const submit = async (event) => {
        console.log('submit')
        console.log(getValues())
    }

    const formSubmit = async () => {
        console.log(getValues())
        console.log('test')

        let thirdparty = []

        for (let third_party of getValues('third-party')) {
            thirdparty.push(
                third_party.value
            )
        }

        const data = {
            client: {
                id: getValues('client').value,
            },
            agent: {
                id: getValues('agent').value,
            },
            matter: getValues('matter'),
            thirdparty
        }


        await apiRequest(`/customer/a/solution/smart-${type}`, "POST", data).then((resp) => {
            // setLoading(true)
            console.log('test')
            if (resp.code === 200) {
                navigate(`/customer/solutions/${type}`, { state: "clientAdded" });
                console.log('done')
            } else {
                toast.error("Something went wrong", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            }
        });
    }

    return (
        <Container>
            <Row>
                <Col>
                    <div className="nk-wizard nk-wizard-simple is-alter wizard clearfix">
                        <Steps config={config}>
                            {/* <Step component={SolutionWizardStart} errors={errors} clearErrors={clearErrors} setError={setError} unregister={unregister} submit={submit} handleSubmit={handleSubmit} watch={watch} register={register} setValue={setValue} getValues={getValues} reset={reset} /> */}
                            {/* <Step component={SolutionWizardUser} errors={errors} clearErrors={clearErrors} setError={setError} unregister={unregister} submit={submit} handleSubmit={handleSubmit} watch={watch} register={register} setValue={setValue} getValues={getValues} reset={reset} /> */}
                            {/* <Step component={SolutionWizardThirdParty} clientList={clientList} setClientList={setClientList} matterList={matterList} setMatterList={setMatterList} thirdPartieList={thirdPartieList} setThirdPartieList={setThirdPartieList} agentList={agentList} setAgentList={setAgentList} errors={errors} clearErrors={clearErrors} setError={setError} unregister={unregister} submit={submit} handleSubmit={handleSubmit} watch={watch} register={register} setValue={setValue} getValues={getValues} reset={reset} /> */}
                            <Step component={SolutionWizardSelect} data={data} setData={setData} errors={errors} clearErrors={clearErrors} setError={setError} unregister={unregister} submit={submit} handleSubmit={handleSubmit} watch={watch} register={register} setValue={setValue} getValues={getValues} reset={reset} />
                            <Step component={SolutionWizardFinal} errors={errors} clearErrors={clearErrors} setError={setError} unregister={unregister} submit={submit} handleSubmit={handleSubmit} watch={watch} register={register} setValue={setValue} getValues={getValues} formSubmit={formSubmit} reset={reset} />
                        </Steps>
                    </div>
                </Col>


            </Row>
        </Container>
    )
}

export default SolutionAddWizard