import React, { useState, useEffect } from "react";
import Content from "../../../../layout/content/Content";
import Head from "../../../../layout/head/Head";
import {
  Icon,
  ClientListTable,
  PreviewCard,
  BlockAthenty,
  Block,
  UserAvatar,
  Loading,
  PreviewAltCard,
  Input,
  Button,
  // DropdownMenu,
} from "../../../../components/Component";
import { Badge, Form, Card, DropdownMenu, DropdownToggle, Modal, ModalBody, ModalHeader, Spinner, UncontrolledDropdown } from "reactstrap";

import { Link } from "react-router-dom";
import { capitalizeFirstLetter, capitalizeWords, findUpper } from "../../../../utils/Utils";
import MainTable from "../../../components/athenty/table/Main.Table";
import { apiRequest } from "../../../../utils/Api";
import { toast, ToastContainer } from "react-toastify";
import { parsePhoneNumber } from "libphonenumber-js";
import { useForm } from "react-hook-form";

const ThirdPartyList = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [modal, setModal] = useState(false)
  const [userToDelete, setUserToDelet] = useState()
  const dataTableColumns = [
    {
      name: "Type",
      selector: (row) => row.type,

      cell: (row) => <span>{row.type ? capitalizeWords(row.type) : 'No Provided'}</span>,
      sortable: false,
    },
    {
      name: "Company",
      selector: (row) => row.company,
      cell: (row) => (
        <div>
          {/* <UserAvatar theme={row.avatarBg} text={findUpper(row.name)} image={row.image}></UserAvatar> */}
          <span>
            {capitalizeWords(row.company)}
          </span>
        </div>
      ),
      sortable: false,
      grow: 1.5,
    },
    {
      name: "Contact",
      selector: (row) => row.email,
      cell: (row) => (<div className="user-card " data-toggle="tooltip">
        <div className="user-info">

          <span>
            <Icon name="call-fill" className={"pe-1"} />
            {row.mobile ? <a href={`tel:${row.mobile}`}>{parsePhoneNumber("+1" + row.mobile).formatNational()}</a> : 'Not provided'}
            <br />
            <Icon name="mail" className={"pe-1"} />
            {/* <a href={row.email ? `mailto://${row.email}` : ''}>{row.email}</a> */}
            {row.email ? <a href={`mailto://${row.email}`}>{row.email}</a> : 'Not provided'}
          </span>
        </div>
      </div>),
      sortable: false,
      grow: 2.5,
    },
    // {
    //   name: "Phone Number",
    //   selector: (row) => row.phoneNumber,
    //   compact: true,
    //   width: "110px",
    //   style: { paddingRight: "10px" },
    //   cell: (row) => <span>{row.phoneNumber}</span>,
    //   sortable: false,
    // },
    {
      name: "Title",
      selector: (row) => row.businessTitle,
      cell: (row) => <span>{row.title ? row.title : 'Not Provided'}</span>,
      sortable: false,
    },
    // {
    //   name: "Role or Profession",
    //   selector: (row) => row.businessRole,
    //   compact: true,
    //   width: "80px",
    //   style: { paddingRight: "10px" },
    //   cell: (row) => <span>{row.businessRole}</span>,
    //   sortable: false,
    // },
    {
      name: "First name (Last name)",
      selector: (row) => row.name,
      cell: (row) => <><span>{row.name_first || 'No Provided'}</span> <span>({row.name_last || 'No Provided'})</span></>,
      sortable: false,
    },

    // {
    //   name: "Status",
    //   selector: (row) => row.active,

    //   cell: (row) => (
    //     <Badge color={row.active ? "success" : "danger"} pill>
    //       {row.active ? "Active" : "Disabled"}
    //     </Badge>
    //   ),
    //   sortable: false,
    // },
    // {
    //   name: "",
    //   compact: true,
    //   cell: (row) => (
    //     <div className="row">
    //       <div className="col-6">
    //         {row.role !== "Owner" ? (
    //           <UncontrolledDropdown className="ps-5">
    //             <DropdownToggle tag="a" className="btn btn-icon btn-trigger">
    //               <Icon name="more-v"></Icon>
    //             </DropdownToggle>
    //             <DropdownMenu>
    //               <ul className="link-list-opt no-bdr">
    //                 {row.state === 2 && (
    //                   <li>
    //                     <Link onClick={() => enableAgent(row)}>
    //                       <Icon name="check"></Icon>
    //                       <span>Enable Access</span>
    //                     </Link>
    //                   </li>
    //                 )}
    //                 {row.state === 1 && (
    //                   <li>
    //                     <Link to="" onClick={() => disableAgent(row)}>
    //                       <Icon name="na"></Icon>
    //                       <span>Disable Access</span>
    //                     </Link>
    //                   </li>

    //                 )}
    //                 <li>
    //                   <Link onClick={() => toggleModal(row)}>
    //                     <Icon name="trash"></Icon>
    //                     <span>Delete</span>
    //                   </Link>
    //                 </li>
    //               </ul>
    //             </DropdownMenu>
    //           </UncontrolledDropdown>
    //         ) : (
    //           <div style={{ minHeight: "34px" }}></div>
    //         )}
    //       </div>
    //     </div>
    //   ),
    // },
  ];

  const {
    handleSubmit,
  } = useForm();

  useEffect(() => {
    // getThirdPartylist();
  }, []);

  const toggleModal = (data) => {
    setUserToDelet(data)
    console.log(data)
    setModal(!modal)
    // console.log(modal)
  }




  const disableAgent = async (row) => {
    setLoading(true);
    try {
      await apiRequest("/customer/a/agents/disable", "POST", row).then((resp) => {
        if (resp.code === 200) {
          // console.log(resp);
          setData(resp.data);
          setLoading(false);
        } else {
          toast.error(resp.error, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setLoading(false);
        }
      });
    } catch (error) {
      setLoading(false);
      toast.error(error, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  const enableAgent = async (row) => {
    setLoading(true);
    try {
      await apiRequest("/customer/a/agents/enable", "POST", row).then((resp) => {
        if (resp.code === 200) {
          // console.log(resp);
          setData(resp.data);
          setLoading(false);
        } else {
          toast.error(resp.error, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setLoading(false);
        }
      });
    } catch (error) {
      setLoading(false);
      toast.error(error, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  const deleteThirdParty = async (data) => {
    console.log('test')
    console.log(userToDelete)
    if (!userToDelete) {
      return
    }
    setLoading(true)
    try {
      await apiRequest("/customer/a/third-party/delete", "DELETE", userToDelete).then((resp) => {
        if (resp.code === 200) {
          console.log(resp);
          setLoading(false);
          toggleModal();
          toast.success(resp.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        } else {
          toast.error(resp.error, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setLoading(false);
        }
      });
    } catch (err) {
      console.log(err);
      toast.error(err, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }

  }

  return (
    <React.Fragment>
      {loading && <Loading />}
      {!loading && (
        <Content>
          <BlockAthenty
            head={"Third Party List"}
            nav="Menu"
            title={"Third Party List"}
            description={`View and update your Third Party's.`}
            buttons={[
              {
                text: "Add",
                icon: "plus",
                tooltip: "Add User",
                id: "add-client",
                form: "my-form",
                linkTo: "/customer/menu/third-party-list/add",
                className: "btn-icon",
                color: "primary",
              },
            ]}
          />
          <Block className="">
            <Content page="component">
              {/* <PreviewCard className="card-bordered">
                <Head title="Customer Profile" />
                <Block size="lg">
                  <Card>
                    {data && (
                      <ClientListTable
                        data={data}
                        columns={dataTableColumns}
                        pagination
                        className="nk-tb-list"
                      // selectableRows={true}
                      />
                    )}
                  </Card>
                </Block>
              </PreviewCard> */}
              <MainTable route="/customer/a/third-party/list" columns={dataTableColumns} />
            </Content>
          </Block>
        </Content>

      )}
      {modal && (
        <Modal isOpen={modal} toggle={() => { toggleModal(); setUserToDelet() }}>
          <ModalHeader
            toggle={toggleModal}
            close={
              <button className="close" onClick={() => { toggleModal(); setUserToDelet() }}>
                <Icon name="cross" />
              </button>
            }
          >
            CONTACT US
          </ModalHeader>
          {loading && <Loading />}

          {!loading && (
            <ModalBody>
              <Form className="is-alter" onSubmit={handleSubmit(deleteThirdParty)}>
                <div className="form-group">


                </div>
                <div className="form-group">
                  <div className="form-label-group">
                    Are you sure you want to delete this user? This action cannot be undone.
                  </div>

                </div>
                <div className="center mt-2">
                  <button
                    onClick={() => { toggleModal(); setUserToDelet() }}
                    className={`${loading ? "disabled" : ""} center btn border me-2 m-1 closer`}
                    style={{ width: "90px" }}
                  >
                    Cancel
                  </button>
                  <Button color="danger" size="" className="d-flex justify-content-center" outline="" style={{ width: "90px", alignItems: 'center' }}>
                    {!loading ? "Delete" : <Spinner />}
                  </Button>
                </div>
              </Form>
            </ModalBody>
          )}
        </Modal>
      )}
    </React.Fragment>
  );
};

export default ThirdPartyList;
