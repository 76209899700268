import React, { useState } from "react";
import Content from "../../../../../../layout/content/Content";
import { ReactDataTable } from "../../../../../../components/Component";
const ActivitiesColumns = [
  {
    name: "Source",
    selector: (row) => row.name,
    grow: 0.75,
    cell: (row) => (
      <div className="user-card">
        <div className="user-info">{row.source} </div>
      </div>
    ),
  },
  {
    name: "Destination",
    grow: 0.5,
    selector: (row) => row.id,
    hide: 370,
    cell: (row) => (
      <div className="user-card">
        <div className="user-info">
          <span className="tb-lead">{row.destination} </span>
        </div>
      </div>
    ),
  },
  {
    name: "Request source IP",
    grow: 0.5,
    selector: (row) => row.type,
    hide: "sm",
    cell: (row) => (
      <div className="user-card">
        <div className="user-info">
          <span className="tb-lead">{row.ip} </span>
        </div>
      </div>
    ),
  },
  {
    name: "Request source IP",
    grow: 2,
    selector: (row) => row.type,
    sortable: true,
    compact: true,
    hide: "sm",
    cell: (row) => (
      <div className="user-card mt-2 mb-2">
        <div className="user-info">
          <span className="tb-lead">
            {row.date}{" "}
            <span
              className={`dot dot-${row.status === "Active" ? "success" : row.status === "Pending" ? "warning" : "danger"
                } d-md-none ms-1`}
            ></span>
          </span>
        </div>
      </div>
    ),
  },
];

const ActivitiesTab = (props) => {
  const [activitiesData, setActivitiesData] = useState(props.data);
  return (
    <React.Fragment>
      <Content>
        {activitiesData && <ReactDataTable data={activitiesData} columns={ActivitiesColumns} pagination />}
      </Content>
    </React.Fragment>
  );
};

export default ActivitiesTab;
