import React from "react";
import Content from "../../../../../../layout/content/Content";
import GalleryImage from "../../../../../../components/partials/gallery/GalleryImage";
const role_options_business = [];

const entity_options = [
  { value: "1", label: "Corporation" },
  { value: "2", label: "Unlimited Liability Company (ULC)" },
  { value: "3", label: "Limited Liability Company (LLC)" },
  { value: "4", label: "General Partnership (GP)" },
  { value: "5", label: "Limited Partnership (LP)" },
  { value: "6", label: "Limited Liability Partnership (LLP)" },
  { value: "7", label: "Joint Venture or Syndicate" },
  { value: "8", label: "Trust" },
  { value: "9", label: "Co-operative Corporation" },
];

const getBusinessRoleFromId = (id) => { };

const getEntityFromId = (id) => {
  id = id.toString();
  for (let i = 0; i < entity_options.length; i++) {
    if (entity_options[i].value === id) {
      return entity_options[i].label;
    }
  }
};

const officerRoleOptions = {
  o: "Officer",
  d: "Director",
};

const officerTitleOptions = {
  pres: "President",
  vp: "Vice-President",
  sec: "Secretary",
  tres: "Treasurer",
  other: "Other",
};

const officerSignTogetherOptions = {
  0: "Alone",
  1: "Together",
  2: "Some other way",
};

const BusinessTab = ({ data }) => {
  return (
    <React.Fragment>
      {data && (
        <Content>
          <div className="nk-block-head">
            <h5 className="title">Business</h5>
          </div>
          <div className="card card-bordered card-stretch">
            <div className="card-inner-group">
              <div className="card-inner p-0">
                <div className="nk-tb-list nk-tb-ulist">
                  <div className="profile-ud wider">
                    <span
                      style={{
                        fontSize: "14px",
                        margin: "0px 0px 0px 20px",
                        width: "50%",
                      }}
                      className="profile-ud-label"
                    >
                      Client's Title
                    </span>

                    <span
                      style={{
                        textAlign: "left",
                        fontSize: "14px",
                        margin: "0px 20px 0px 0px",
                        width: "50%",
                      }}
                      id="title"
                      className="profile-ud-value"
                    >
                      {data.business && data.business.bus_role ? data.business.bus_role.join(", ") : "Not collected"}
                    </span>
                  </div>
                  <hr style={{ margin: "0" }} />
                  <div className="profile-ud wider">
                    <span
                      style={{
                        fontSize: "14px",
                        margin: "0px 0px 0px 20px",
                        width: "50%",
                      }}
                      className="profile-ud-label"
                    >
                      Name
                    </span>

                    <span
                      style={{
                        textAlign: "left",
                        fontSize: "14px",
                        margin: "0px 20px 0px 0px",
                        width: "50%",
                      }}
                      id="name"
                      className="profile-ud-value"
                    >
                      {data.business && data.business.name ? data.business.name : "Not collected"}
                    </span>
                  </div>
                  <hr style={{ margin: "0" }} />
                  <div className="profile-ud wider">
                    <span
                      style={{
                        fontSize: "14px",
                        margin: "0px 0px 0px 20px",
                        width: "50%",
                      }}
                      className="profile-ud-label"
                    >
                      Formation Documents
                    </span>

                    {data.business && data.business.document_s3 ? (
                      <span
                        style={{
                          textAlign: "center",
                          fontSize: "14px",
                          margin: "0px 0px 0px 20px",
                        }}
                      >
                        <GalleryImage img={data.business.document_s3} height="250px" />
                      </span>
                    ) : (
                      <span
                        style={{
                          textAlign: "left",
                          fontSize: "14px",
                          margin: "0px 20px 0px 0px",
                          width: "50%",
                        }}
                        id="name"
                        className="profile-ud-value"
                      >
                        Not collected
                      </span>
                    )}
                  </div>
                  <hr style={{ margin: "0" }} />
                  <div className="profile-ud wider">
                    <span
                      style={{
                        fontSize: "14px",
                        margin: "0px 0px 0px 20px",
                        width: "50%",
                      }}
                      className="profile-ud-label"
                    >
                      Entity
                    </span>

                    <span
                      style={{
                        textAlign: "left",
                        fontSize: "14px",
                        margin: "0px 20px 0px 0px",
                        width: "50%",
                      }}
                      id="entity"
                      className="profile-ud-value"
                    >
                      {data.business && data.business.entity_id
                        ? getEntityFromId(data.business.entity_id)
                        : "Not collected"}
                    </span>
                  </div>
                  <hr style={{ margin: "0" }} />
                  <div className="profile-ud wider">
                    <span
                      style={{
                        fontSize: "14px",
                        margin: "0px 0px 0px 20px",
                        width: "50%",
                      }}
                      className="profile-ud-label"
                    >
                      Activity
                    </span>

                    <span
                      style={{
                        textAlign: "left",
                        fontSize: "14px",
                        margin: "0px 20px 0px 0px",
                        width: "50%",
                      }}
                      id="activity"
                      className="profile-ud-value"
                    >
                      {data.business && data.business.activity ? data.business.activity : "Not collected"}
                    </span>
                  </div>
                  <hr style={{ margin: "0" }} />
                  <div className="profile-ud wider">
                    <span
                      style={{
                        fontSize: "14px",
                        margin: "0px 0px 0px 20px",
                        width: "50%",
                      }}
                      className="profile-ud-label"
                    >
                      Phone
                    </span>

                    <span
                      style={{
                        textAlign: "left",
                        fontSize: "14px",
                        margin: "0px 20px 0px 0px",
                        width: "50%",
                      }}
                      id="phone"
                      className="profile-ud-value"
                    >
                      {data.business && data.business.phone ? data.business.phone : "Not collected"}
                    </span>
                  </div>
                  <hr style={{ margin: "0" }} />

                  {/* <hr style={{ margin: "0" }} /> */}
                  <div className="profile-ud wider">
                    <span
                      style={{
                        fontSize: "14px",
                        margin: "0px 0px 0px 20px",
                        width: "50%",
                      }}
                      className="profile-ud-label"
                    >
                      Address
                    </span>

                    <span
                      style={{
                        textAlign: "left",
                        fontSize: "14px",
                        margin: "0px 20px 0px 0px",
                        width: "50%",
                      }}
                      id="address"
                      className="profile-ud-value"
                    >
                      {data.business && data.business.address1
                        ? `${data.business.address1}${data.business.address2 ? ", " + data.business.address2 : "Not collected"
                        }, ${data.business.city}, ${data.business.region}, ${data.business.postal}, ${data.business.country
                        }`
                        : "Not collected"}
                    </span>
                  </div>
                  <hr style={{ margin: "0" }} />
                  <div className="profile-ud wider">
                    <span
                      style={{
                        fontSize: "14px",
                        margin: "0px 0px 0px 20px",
                        width: "50%",
                      }}
                      className="profile-ud-label"
                    >
                      Website
                    </span>

                    <span
                      style={{
                        textAlign: "left",
                        fontSize: "14px",
                        margin: "0px 20px 0px 0px",
                        width: "50%",
                      }}
                      id="domain"
                      className="profile-ud-value"
                    >
                      {data.business && data.business.domain ? data.business.domain : "Not collected"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {data.business_additional && (
            <>
              <div className="nk-block-head">
                <h5 className="title">Other Officers & Directors</h5>
              </div>
              <div className="card card-bordered card-stretch">
                <div className="card-inner-group">
                  <div className="card-inner p-0">
                    <div className="nk-tb-list nk-tb-ulist">
                      <div className="profile-ud wider">
                        <span
                          style={{
                            fontSize: "14px",
                            margin: "0px 0px 0px 20px",
                            width: "2%",
                          }}
                        >
                          #
                        </span>
                        <span
                          style={{
                            fontSize: "14px",
                            margin: "0px 0px 0px 20px",
                            width: "18%",
                          }}
                        >
                          Name
                        </span>
                        <span
                          style={{
                            fontSize: "14px",
                            width: "9%",
                          }}
                        >
                          Role
                        </span>
                        <span
                          style={{
                            fontSize: "14px",
                            width: "22%",
                          }}
                        >
                          Email
                        </span>
                        <span
                          style={{
                            fontSize: "14px",
                            width: "18%",
                          }}
                        >
                          Signing & Binding Authority
                        </span>
                        <span
                          style={{
                            fontSize: "14px",
                            width: "33%",
                          }}
                        >
                          Together with/Other
                        </span>
                      </div>
                      <hr style={{ margin: "0" }} />
                      {data.business_additional &&
                        data.business_additional.map((officer, index) => {
                          return (
                            <React.Fragment key={index}>
                              {" "}
                              <div className="profile-ud wider">
                                <span
                                  style={{
                                    textAlign: "left",
                                    fontSize: "14px",
                                    margin: "0px 0px 0px 20px",
                                    width: "2%",
                                    color: "#526484",
                                    fontWeight: "500",
                                  }}
                                  id="number"
                                >
                                  {index + 1}
                                </span>
                                <span
                                  style={{
                                    textAlign: "left",
                                    fontSize: "14px",
                                    margin: "0px 0px 0px 20px",
                                    width: "18%",
                                    color: "#526484",
                                    fontWeight: "500",
                                  }}
                                  id="name"
                                >
                                  {officer.name_first} {officer.name_last}
                                </span>
                                <span
                                  style={{
                                    textAlign: "left",
                                    fontSize: "14px",
                                    width: "9%",
                                    color: "#526484",
                                    fontWeight: "500",
                                  }}
                                  id="title"
                                >
                                  {officerRoleOptions[officer.role]}
                                </span>
                                <span
                                  style={{
                                    textAlign: "left",
                                    fontSize: "14px",
                                    width: "22%",
                                    color: "#526484",
                                    fontWeight: "500",
                                  }}
                                  id="email"
                                >
                                  {officer.email}
                                </span>
                                <span
                                  style={{
                                    textAlign: "left",
                                    fontSize: "14px",
                                    width: "18%",
                                    color: "#526484",
                                    fontWeight: "500",
                                  }}
                                  id="signing-binding-authority"
                                >
                                  {officerSignTogetherOptions[officer.cosign]}
                                </span>
                                <span
                                  style={{
                                    textAlign: "left",
                                    fontSize: "14px",
                                    width: "33%",
                                    color: "#526484",
                                    fontWeight: "500",
                                  }}
                                  id="together-with"
                                >
                                  {officer.together
                                    ? officer.together.map((role) => officerTitleOptions[role]).join(", ")
                                    : officer.other
                                      ? officer.other
                                      : "---"}
                                </span>
                              </div>
                              <hr style={{ margin: "0" }} />
                            </React.Fragment>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </Content>
      )}
    </React.Fragment>
  );
};

export default BusinessTab;
